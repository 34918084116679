import i18next, { InterpolationOptions, Resource, TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'

import {
  formatCurrency,
  formatDate,
  formatRelativeDate,
  formatTime,
  SupportedLanguages,
} from '../helpers/formatters'
import defaultLanguage from './en-CA.json'
import translationEnUs from './en-US.json'
import translationEsMx from './es-MX.json'
import translationFrCa from './fr-CA.json'

interface Translation extends Resource {
  translation: typeof defaultLanguage
}

const resources: {
  [Lang in SupportedLanguages]: Translation
} = {
  'en-CA': { translation: defaultLanguage },
  'en-US': { translation: translationEnUs },
  'fr-CA': { translation: translationFrCa },
  'es-MX': { translation: translationEsMx },
}

const interpolation: InterpolationOptions = {
  escapeValue: false,
  format: (value, format, lng) => {
    switch (format) {
      case 'formatTime':
        return formatTime(value, lng)
      case 'formatDate':
        return formatDate(value, lng)
      case 'formatRelativeDate':
        return formatRelativeDate(value, lng)
      case 'formatCurrency':
      default:
        return formatCurrency(Number(value), lng)
    }
  },
}

const initTranslation = (lng: SupportedLanguages): Promise<TFunction> => {
  return i18next.use(initReactI18next).init({
    returnNull: false,
    fallbackLng: SupportedLanguages.enCA,
    keySeparator: '.',
    interpolation,
    resources,
    lng,
  })
}

export default initTranslation

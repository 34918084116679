import { FunctionComponent, SetStateAction, useEffect, Dispatch, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useDebounce } from '@/libs/helpers/hooks/useDebounce'
import { OrderMethod } from '@/libs/helpers/utils'
import { useGoogleMaps, AutocompleteOption } from '@/providers/googleMaps'
import { useSearch } from '@/providers/state/search'
import { InputAutocomplete, Suggestion } from '@/tbui'

const StyledPrimaryText = styled.p`
  font-size: 14px;
  margin: 0;
`

const StyledSecondaryText = styled.p`
  font-size: 12px;
  margin: 0;
`

const InputAutocompleteWrapper = styled.div`
  min-width: 100%;
`

export enum SearchInputByAddressVariant {
  ADDRESS_HEADER = 'ADDRESS_HEADER',
  ADDRESS_HOME = 'ADDRESS_HOME',
  ADDRESS_MENU = 'ADDRESS_MENU',
}

const AddressAutocompleteComponent: FunctionComponent<{
  setIsLoading?: Dispatch<SetStateAction<boolean>>
  variant: SearchInputByAddressVariant
  orderMethod: OrderMethod
}> = ({ setIsLoading, variant, orderMethod }) => {
  // HOOKS
  const [
    { placesAutocompleteOptions, orderingAddress },
    { getPlacesAutocompleteOptions, selectOrderingAddress, clearUserLocation },
  ] = useGoogleMaps()
  const [, { setOrderMethod }] = useSearch()
  const { t } = useTranslation()

  // CONSTS
  const formattedAddress = orderingAddress?.formattedAddress || ''
  const shouldSubmitOnSelect = Boolean(variant === SearchInputByAddressVariant.ADDRESS_HEADER)

  // STATES
  const [controlledValue, setControlledValue] = useState<string>(formattedAddress)

  // HANDLERS
  const handleSelection = (selectedSuggestion: Suggestion): void => {
    const placeID =
      placesAutocompleteOptions?.find(({ label }) => label === selectedSuggestion.value)?.id || ''
    if (placeID) {
      selectOrderingAddress({ placeID })
      if (shouldSubmitOnSelect) {
        setOrderMethod(orderMethod)
      }
    }
  }

  // debounced to 250ms to avoid calling google APIs on every key stroke
  const asyncSetPlacesAutocompleteOptions = useDebounce(
    async (inputValue: string): Promise<void> => {
      setControlledValue(inputValue)
      // !shouldSubmitOnSelect should cover empty orderingAddress being submitted in search header
      if (!inputValue && !shouldSubmitOnSelect) {
        clearUserLocation()
      } else {
        setIsLoading?.(true)
        await getPlacesAutocompleteOptions(inputValue)
        setIsLoading?.(false)
      }
    },
    250
  )

  const mapAddressOptions = (options: AutocompleteOption[]): Suggestion[] => {
    return options?.map((option) => ({
      value: option.label,
      key: option.id,
      label: (
        <>
          <StyledPrimaryText>{option.primaryText}</StyledPrimaryText>
          <StyledSecondaryText>{option.secondaryText}</StyledSecondaryText>
        </>
      ),
    }))
  }

  // EFFECTS
  useEffect(() => {
    if (formattedAddress !== '') {
      setControlledValue(formattedAddress)
    }
  }, [formattedAddress])

  // HANDLERS
  return (
    <InputAutocompleteWrapper data-test="input-autocomplete-wrapper">
      <InputAutocomplete
        suggestions={mapAddressOptions(placesAutocompleteOptions ?? [])}
        onChange={asyncSetPlacesAutocompleteOptions}
        label={t('tbdine.search_input.label')}
        onSelect={handleSelection}
        data-test="input-address"
        value={controlledValue}
        variant="INSET"
      />
    </InputAutocompleteWrapper>
  )
}

export default AddressAutocompleteComponent

import {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react'

import { SupportedLanguages } from '@/libs/helpers/formatters'
import { useCookies } from '@/libs/helpers/hooks/useCookies'
import initTranslation from '@/libs/i18n'
import { LoadScreen } from '@/tbui'

const initialState: SupportedLanguages | null = null

const LanguageContext = createContext<[SupportedLanguages, Dispatch<SupportedLanguages>]>(
  {} as [SupportedLanguages, Dispatch<SupportedLanguages>]
)

const useUserLanguage = (): {
  setUserLanguage: Dispatch<SupportedLanguages>
  userLanguage: SupportedLanguages
} => {
  const [userLanguage, setUserLanguage] = useContext(LanguageContext)

  return { userLanguage, setUserLanguage }
}

const UserLanguageProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { getUserLocaleCookie, setUserLocaleCookie } = useCookies()
  const [userLanguage, setUserLanguage] = useState<SupportedLanguages | null>(initialState)

  const ctxValue = useMemo(() => [userLanguage, setUserLanguage], [userLanguage, setUserLanguage])

  const languageIsSupported = (language: string): boolean => {
    return Object.values(SupportedLanguages).includes(language as SupportedLanguages)
  }

  // takes care of initializing the translations using the user's locale
  useEffect(() => {
    const setLanguageBySource = (): void => {
      const languageFromCookies = getUserLocaleCookie()
      // cookies need to be handled first
      if (languageIsSupported(languageFromCookies)) {
        initTranslation(languageFromCookies)
        return setUserLanguage(languageFromCookies)
      }
      // eslint-disable-next-line no-restricted-properties
      const languageFromBrowser = window.navigator.language as SupportedLanguages
      // second, we check the browser's locale
      if (languageIsSupported(languageFromBrowser)) {
        initTranslation(languageFromBrowser)
        return setUserLanguage(languageFromBrowser)
      }

      initTranslation(SupportedLanguages.enCA)
      return setUserLanguage(SupportedLanguages.enCA)
    }

    setLanguageBySource()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userLanguage) {
      initTranslation(userLanguage)
      setUserLocaleCookie(userLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage])

  if (!userLanguage) {
    return <LoadScreen />
  }

  return (
    <LanguageContext.Provider
      value={ctxValue as [SupportedLanguages, Dispatch<SetStateAction<SupportedLanguages | null>>]}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export type UseUserLanguageType = ReturnType<typeof useUserLanguage>
export default UserLanguageProvider
export { useUserLanguage }

import { FormatFunction } from 'i18next'
import { DateTime } from 'luxon'

import currencyByLocale from '../i18n/currency.json'

export enum SupportedLanguages {
  enCA = 'en-CA',
  frCA = 'fr-CA',
  enUS = 'en-US',
  esMX = 'es-MX',
}

// works for north american numbers only
// strips optional +1 country code at the start of the number
// strips all non-digit characters from string (i.e + or -)
// returns number formatted as (xxx) xxx-xxxx
export function formatPhone(phone: string | null): string {
  if (phone == null) {
    return ''
  }

  const onlyDigit = phone.replace(/\W/g, '')
  const matches = onlyDigit.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (matches) {
    return `(${matches[2]}) ${matches[3]}-${matches[4]}`
  }
  return phone
}

// uses JS Intl module and user locale to format
// a numerical value with a currency
// for a value of `3.99` and these locales:
// - fr-CA returns 3,99 $
// - en-US returns $3.99
export const formatCurrency: FormatFunction = (value: number, lng = 'en-US'): string => {
  const enumLng = lng as SupportedLanguages
  const { format } = new Intl.NumberFormat(lng, {
    currency: currencyByLocale[enumLng],
    style: 'currency',
  })

  return format(value)
}

// uses a locale to format a timestamp with AM/PM for north american locales
// for non en-* locales, 24hr clock is used
// strips . in A.M. and P.M. since that's what luxon returns and design didn't like it
export const formatTimeWithTimezone = (
  value: string,
  { lng, setZone = true }: { lng?: string; setZone?: boolean }
): string => {
  const date = DateTime.fromISO(value, { locale: lng, setZone })
  // find out whether locale uses 12h format
  const hour12bool = /^en-/.test(lng ?? '')
  return date
    .toFormat(
      `${hour12bool ? 'h' : 'H'}${date.minute > 0 ? ':mm' : ''}${
        // need a space between time and AM/PM (before 'a' character) for better UI
        hour12bool ? ' a' : ''
      }`
    )
    .replace(/[.]/g, '')
    .toUpperCase()
}

// formatters used with i18next library via interpolation
// read more: https://www.i18next.com/translation-function/formatting
export const formatTime: FormatFunction = (value: string, lng?: string): string => {
  // NOTE: set zone uses browser timezone to format the given timestamp
  return formatTimeWithTimezone(value, { lng, setZone: true })
}

export const formatRelativeDate: FormatFunction = (value: string, lng?: string): string => {
  return (
    DateTime.fromISO(value, {
      locale: lng,
      // NOTE: set zone uses browser timezone to format the given timestamp
      setZone: true,
    }).toRelativeCalendar() ?? ''
  )
}

export const formatDate: FormatFunction = (value: string, lng?: string): string => {
  return DateTime.fromISO(value, {
    locale: lng,
    // NOTE: set zone uses browser timezone to format the given timestamp
    setZone: true,
  }).toLocaleString(DateTime.DATE_HUGE)
}

interface Address {
  address1?: string
  address2?: string
  country?: string
  state?: string
  province?: string
  city?: string
}

export function formatAddress(address: Address): string {
  // squashes a given address into a single string
  const lines = [
    address.address1,
    address.address2,
    address.city,
    address.state ?? address.province,
    address.country,
  ]

  // filters out empty string fields, and joins the address components with a comma
  const isLineEmpty = (line: string | undefined): boolean => {
    return typeof line === 'string' && line !== ''
  }

  // strips `.` for some unknown reason, likely a bug
  return lines.filter(isLineEmpty).join(', ').replace('.', '')
}

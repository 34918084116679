import type { FunctionComponent } from 'react'
import { css, cx } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'

const baseStyles = (theme: Theme): string => css`
  background-color: ${theme.palette.GRAY_1};
  height: 2px;
  width: 100%;
`

// We need to export components that way so the the props documentation is generated automatically
const Divider: FunctionComponent<{ classNames?: string }> = ({ classNames }) => {
  const theme = useTheme()
  return <div className={cx(baseStyles(theme), classNames)} />
}
export default Divider

import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Header'
import { buildAssetsPath } from '@/libs/helpers/assets'
import { FullPageErrorCode } from '@/libs/helpers/errorMapper'
import type { ParsedError } from '@/libs/helpers/error_handler'
import { HeaderVariant, OrderMethod } from '@/libs/helpers/utils'
import { useError } from '@/providers/error'
import { Grid } from '@/tbui'

const StyledGrid = styled(Grid)`
  overflow: hidden;
  height: calc(100vh - 100px);
`

const StyledPicture = styled.picture`
  max-width: 800px;
  justify-self: center;
  & > img {
    width: 100%;
  }
`

const StyledTitle = styled.h2`
  margin: 0;
  text-align: center;
  font-family: ${(props) => props.theme.font.FAMILY_BOLD};
  letter-spacing: 0.7px;
  font-size: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    letter-spacing: 0.5px;
    font-size: 24px;
  }
`

const StyledMessage = styled.p`
  margin: 0;
  text-align: center;
  font-family: ${(props) => props.theme.font.FAMILY};
  letter-spacing: 0.5px;
  font-size: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    letter-spacing: 0.35px;
    font-size: 14px;
  }
`

interface ErrorPageProps {
  serverError: ParsedError
}

const ErrorPage: NextPage<ErrorPageProps> = ({
  // on page load error from ssr
  serverError,
}) => {
  // STATES
  const [messageTranslationContext, setMessageTranslationContext] = useState<string>('')

  // HOOKS
  const { t } = useTranslation()
  const router = useRouter()
  const { orderMethod } = router.query
  const { error, clearError } = useError()
  const errorCode = serverError.code ?? error.errorCode

  // EFFECTS
  useEffect(() => {
    switch (errorCode) {
      case FullPageErrorCode.FeatureFlagNotAuthorized:
      case FullPageErrorCode.ModelNotFound:
        setMessageTranslationContext(`${errorCode}_${orderMethod}`)
        break
      case FullPageErrorCode.SettingsNotFound:
        setMessageTranslationContext(errorCode)
        break
      default:
        setMessageTranslationContext('default')
        break
    }

    return () => {
      clearError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, orderMethod])

  // CONSTS
  const title = t('error.title', {
    context: messageTranslationContext,
  })

  return (
    <>
      <Head>
        <title>TouchBistro - Online Ordering - Error: {title}</title>
      </Head>
      <Header
        variant={HeaderVariant.HOME}
        cart={{ isCartLoading: false, orderMethod: OrderMethod[orderMethod ?? 'pickup'] }}
        venueXRefID={undefined}
      />
      <StyledGrid align="center" p="24px" data-test="full-page-error-grid">
        <StyledPicture>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={buildAssetsPath('/images/cloud-errors.png')} alt="default-error" />
        </StyledPicture>
        <Grid justify="center" p="8px">
          <StyledTitle>{title}</StyledTitle>
        </Grid>
        <Grid justify="center" p="8px">
          {t('error.message', {
            context: messageTranslationContext,
          })
            .split('\n')
            .map((paragraph) => (
              <StyledMessage key={encodeURI(paragraph)}>{paragraph}</StyledMessage>
            ))}
        </Grid>
        <Grid justify="center" p="8px" color="ERROR">
          {process.env.NEXT_PUBLIC_NODE_ENV === 'development' && (
            <>
              Original Error (Development Only)
              <br />
              Message: {error?.message ?? serverError.message}
              <br />
              Error Code: {error?.errorCode}
              <br />
              Status Code: {error?.status ?? serverError.statusCode}
            </>
          )}
        </Grid>
      </StyledGrid>
    </>
  )
}

export default ErrorPage

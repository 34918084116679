/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent, ChangeEvent, useEffect, Dispatch, useState, useRef } from 'react'

import styled from '@emotion/styled'
import TextField, { TextFieldProps } from '../TextField'
import { useClickOutsideEvent } from '../hooks/useClickOutsideEvent'

enum InputAutocompleteOrientation {
  BOTTOM = 'BOTTOM',
  TOP = 'TOP',
}

interface StyledPopoverProps {
  orientation?: keyof typeof InputAutocompleteOrientation
}

const StyledPopover = styled.div<StyledPopoverProps>`
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background: ${(props) => props.theme.palette.WHITE};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  transition: 0.2s ease;
  position: absolute;
  overflow: hidden;
  z-index: 9999;
  padding: 12px 0;
  right: 0;
  left: 0;
  ${(props) =>
    props.orientation === InputAutocompleteOrientation.TOP
      ? 'bottom: calc(-100% - 0.5rem)'
      : 'top: calc(100% + 0.5rem)'};
`

export interface Suggestion {
  label: string | JSX.Element
  value: string
  key: string
}

const StyledSuggestion = styled.div`
  font-family: ${(props) => props.theme.font.FAMILY};
  padding: 10px 24px;
`

const StyledSuggestionHeader = styled.p`
  font-family: ${(props) => props.theme.font.FAMILY};
  font-size: 12px;
  line-height: 24px;
  padding: 0 24px;
  margin: 12px 0;
`

const StyledSuggestionContainer = styled.div`
  display: flex;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.palette.SECONDARY};
    font-family: ${(props) => props.theme.font.FAMILY_BOLD};
    color: ${(props) => props.theme.palette.TEXT_SECONDARY};
  }
`

export interface InputAutoCompleteProps
  extends Omit<TextFieldProps, 'onChange' | 'onSelect' | 'onSubmit'> {
  /** an handler to be called on the correspondent event */
  onChange: Dispatch<string>
  /** same as the value input attr */
  value?: string
  /** the direction of the popover opening */
  orientation?: keyof typeof InputAutocompleteOrientation
  /** an handler to be called on the event of suggestion selection */
  onSelect?: Dispatch<Suggestion>
  /** an array of auto-complete suggestions */
  suggestions?: Suggestion[]
  staticSuggestions?: Suggestion[]
  staticSuggestionsHeader?: string
  /** automatically select the first suggestion on blur events */
  selectOnBlur?: boolean
  /** the max number of suggestions to show */
  limit?: number
  /** the label/placeholder of the input */
  label?: string
  /** Used for testing purposes */
  suggestionGroupId?: string
  staticSuggestionGroupId?: string
}

export interface SuggestionPopoverProps {
  /** the direction of the popover opening */
  orientation?: keyof typeof InputAutocompleteOrientation
  /** an handler to be called on the correspondent event */
  onSelect: (index: number) => void
  /** a callback funtion that is called when user clicks outside of the popup */
  onBlur?: () => void
  /** show/hide the popup */
  suggestions: Suggestion[]
  /** the max number of suggestions to show */
  limit: number
  /** Used for testing purposes */
  suggestionGroupId?: string
  header?: string
}

const SuggestionPopover: FunctionComponent<SuggestionPopoverProps> = ({
  orientation,
  onSelect,
  onBlur,
  suggestions,
  limit,
  suggestionGroupId,
  header,
}) => {
  const currentRef = useRef<HTMLDivElement>(null)
  useClickOutsideEvent(currentRef, onBlur)

  return (
    <StyledPopover orientation={orientation} ref={currentRef}>
      {header != null && <StyledSuggestionHeader>{header}</StyledSuggestionHeader>}
      {suggestions.slice(0, limit).map((suggestion, index) => (
        <StyledSuggestionContainer
          key={suggestion.key}
          onClick={() => {
            onSelect(index)
          }}
          data-test={suggestionGroupId ? `${suggestionGroupId}-${suggestion.key}` : suggestion.key}
        >
          <StyledSuggestion>{suggestion.label}</StyledSuggestion>
        </StyledSuggestionContainer>
      ))}
    </StyledPopover>
  )
}

// TODO > update component to not use props with the same name as html attr
const InputAutoComplete: FunctionComponent<InputAutoCompleteProps> = ({
  value: controlledValue = '',
  suggestions = [],
  staticSuggestions = [],
  staticSuggestionsHeader,
  selectOnBlur,
  orientation,
  limit = 5,
  onChange,
  variant,
  onSelect,
  suggestionGroupId,
  staticSuggestionGroupId,
  ...textFieldProps
}) => {
  const [value, setValue] = useState<string>(controlledValue)
  const [open, setOpen] = useState<boolean>(false)

  // EFFECTS
  useEffect(() => {
    onChange(value)
  }, [value])

  useEffect(() => {
    // updates the internal value and allows it to be controlled from outside
    if (controlledValue != null) {
      setValue(controlledValue)
    }
  }, [controlledValue])

  // HANDLERS

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value)
    setOpen(true)
  }

  const handleBlur = (): void => {
    if (value === '') {
      setValue('')
      if (staticSuggestions.length > 0) {
        setOpen(false)
      }
      return
    }
    const firstSuggestion = suggestions[0]?.value
    if (firstSuggestion != null && selectOnBlur) {
      setValue(firstSuggestion)
    }
    setOpen(false)
  }

  const handleSelect = (index: number): void => {
    let selectedSuggestion = suggestions[index]
    if (value === '') {
      selectedSuggestion = staticSuggestions[index]
    }

    if (selectedSuggestion?.value != null) {
      setValue(selectedSuggestion.value)
    }
    if (onSelect != null) {
      onSelect(selectedSuggestion)
    }
    setOpen(false)
  }

  // RENDERER
  return (
    <>
      <TextField
        value={value}
        onChange={handleChange}
        onFocus={() => {
          if (staticSuggestions.length > 0 && value === '') {
            setOpen(true)
          }
        }}
        {...textFieldProps}
        variant={variant}
      />
      {
        // display only when value is empty and static suggestions provided
        staticSuggestions.length > 0 && value === '' && open && (
          <SuggestionPopover
            header={staticSuggestionsHeader}
            orientation={orientation}
            suggestions={staticSuggestions}
            onSelect={handleSelect}
            onBlur={handleBlur}
            limit={staticSuggestions.length}
            data-test={staticSuggestionGroupId}
          />
        )
      }
      {
        // Show <SuggestionPopover/> if the condition below satisfies
        suggestions.length && value && open ? (
          <SuggestionPopover
            orientation={orientation}
            suggestions={suggestions}
            onSelect={handleSelect}
            onBlur={handleBlur}
            limit={limit}
            data-test={suggestionGroupId}
          />
        ) : null
      }
    </>
  )
}

export default InputAutoComplete

import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import { composeCartError } from '@/libs/helpers/composeCartError'
import {
  FullPageErrorCode,
  LoyaltyRewardErrorCode,
  StaleCartErrorCode,
} from '@/libs/helpers/errorMapper'
import {
  ClientErrorStatusCode,
  isPageBlockingError,
  ParsedError,
} from '@/libs/helpers/error_handler'
import ErrorPage from '@/pages/_error'
import { useError, ErrorActionTypes } from '../error'
import { ModalComponent } from '../modal'
import SearchProvider from './search'

const StateProvider: FunctionComponent<{
  error: ParsedError | null
  children: ReactNode
}> = ({ error, children }) => {
  const { setModalError } = useError()
  const router = useRouter()

  // de facto error handling boundary effect
  useEffect(() => {
    const refreshServerData = async (): Promise<void> => {
      await router.replace(router.asPath, undefined, {
        scroll: false,
      })
    }

    if (error != null && !isPageBlockingError(error.statusCode)) {
      if (
        error.statusCode === ClientErrorStatusCode.NOT_FOUND &&
        error.code === FullPageErrorCode.ModelNotFound
      ) {
        setModalError(error)
        return
      }

      composeCartError(error, {
        onStaleError: async () => {
          setModalError(StaleCartErrorCode.MODIFIER_GENERIC)
          await refreshServerData()
        },
        onLoyaltyError: async () => {
          if (error.code !== LoyaltyRewardErrorCode.RedeemRewardFailed) {
            setModalError(error)
            await refreshServerData()
          }
        },
        onDefaultFallback: () => {
          setModalError(error)
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <SearchProvider>
      {error?.type === ErrorActionTypes.FULL_PAGE ? <ErrorPage serverError={error} /> : children}
      <ModalComponent />
    </SearchProvider>
  )
}

export default StateProvider

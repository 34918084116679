import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import CustomSelect from '@/components/SearchInputByAddress/DropDownSelector/CustomSelect'
import type { VenueOrderingMethod } from '@/libs/helpers/adapters'
import { buildHeaderOrderMethods, OptionsType, OrderMethod } from '@/libs/helpers/utils'
import { useUserLanguage } from '@/providers/userLanguage'

const CustomSelectStyledWrapper = styled.div<{
  height: number
  needsPadding: boolean
  isClickable: boolean
}>`
  border-color: ${(props) => props.theme.palette.GRAY_2};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  height: ${(props) => `${props.height}px`};
  grid-template-columns: auto 1fr;
  place-items: center;
  position: relative;
  display: grid;
  padding: ${(props) => (props.needsPadding ? '15px' : '5px')};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  gap: 5px;
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.MD}) {
    display: grid;
  }
`

interface OrderMethodDropdownProps {
  isClickable: boolean
  openOrderMethodSelector: () => void
  venueOrderingMethods: VenueOrderingMethod[]
  orderMethod: OrderMethod
  onCustomSelectChange: (method: string) => void
}

const OrderMethodDropdown: FunctionComponent<OrderMethodDropdownProps> = ({
  isClickable,
  openOrderMethodSelector,
  venueOrderingMethods,
  orderMethod,
  onCustomSelectChange,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const { userLanguage } = useUserLanguage()
  const { t } = useTranslation()

  const [availableOrderMethods, setAvailableOrderMethods] = useState<OptionsType[]>([])

  useEffect(() => {
    // subscribe to user language changes
    // to re-translate order method dropdown
    setAvailableOrderMethods(buildHeaderOrderMethods(venueOrderingMethods, orderMethod, t))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueOrderingMethods, userLanguage])

  return (
    <CustomSelectStyledWrapper
      needsPadding={availableOrderMethods.length === 1}
      ref={wrapperRef}
      height={60}
      isClickable={isClickable}
      onClick={isClickable ? openOrderMethodSelector : undefined}
      data-test="order-method-dropdown"
    >
      <CustomSelect
        wrapperRef={wrapperRef}
        onChange={onCustomSelectChange}
        groupId={`order-method-option-${orderMethod.toLowerCase()}`}
        options={availableOrderMethods}
        minWidth={120}
        value={orderMethod.toLowerCase()}
        styleOnly={isClickable}
      />
    </CustomSelectStyledWrapper>
  )
}

export default OrderMethodDropdown

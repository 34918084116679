import { FunctionComponent, MouseEvent } from 'react'
import styled from '@emotion/styled'

const StyledLabel = styled.label`
  font-family: ${(props) => props.theme.font.FAMILY};
  color: ${(props) => props.theme.palette.TEXT};
  cursor: pointer;
  font-size: 16px;
  padding: 16px;
  &:hover {
    color: ${(props) => props.theme.palette.TEXT_SECONDARY};
    background: ${(props) => props.theme.palette.SECONDARY};
  }
`

interface ICustomOption {
  onChange: (selectedOption: string) => void
  label: string | null
  value: string
}

const CustomOption: FunctionComponent<ICustomOption> = ({ onChange, label, value }) => {
  const handleClick = (e: MouseEvent<HTMLLabelElement>): void => {
    e.preventDefault()
    onChange(value)
  }

  return <StyledLabel onClick={handleClick}>{label}</StyledLabel>
}

export default CustomOption

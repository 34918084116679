import { FunctionComponent, useRef } from 'react'
import styled from '@emotion/styled'
import type { OptionsType } from '@/libs/helpers/utils'
import { SvgIcon } from '@/tbui'
import CustomSelect from './CustomSelect'

const StyledWrapper = styled.div`
  border-right: ${(props) => props.theme.shape.BORDER};
  border-color: ${(props) => props.theme.palette.GRAY_2};
  grid-template-columns: auto 1fr;
  place-items: center;
  position: relative;
  display: grid;
  padding: 5px;
  gap: 5px;
  @media (max-width: 430px) {
    border-right: 0px;
    border-bottom: 1px solid transparent;
    border-color: ${(props) => props.theme.palette.GRAY_2};
    padding-bottom: 10px;
  }
`

const StyledSvgIcon = styled(SvgIcon)`
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    display: none;
  }
`

interface IDropDownSelector {
  onChange: (selectedOption: string) => void
  options: OptionsType[]
  groupId: string
  value?: string
  placeholder?: string
}

const DropDownSelector: FunctionComponent<IDropDownSelector> = ({
  groupId,
  onChange,
  options,
  value = options[0].value,
  placeholder,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  return (
    <StyledWrapper ref={wrapperRef}>
      <StyledSvgIcon name="restaurant" color="PRIMARY" fontSize="20px" />
      <CustomSelect
        wrapperRef={wrapperRef}
        onChange={onChange}
        groupId={groupId}
        options={options}
        minWidth={120}
        value={value}
        placeholder={placeholder}
      />
    </StyledWrapper>
  )
}

export default DropDownSelector

import { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'

export interface LabelProps {
  hasError?: boolean
  children: ReactNode
}

const StyledLabel = styled.label<LabelProps>`
  color: ${({ hasError, theme }) => (hasError ? theme.palette.ERROR : theme.palette.TEXT_2)};
  pointer-events: none;
  font-size: 14px;
`

// We need to export components that way so the the props documentation is generated automatically
const Label: FunctionComponent<LabelProps> = StyledLabel
export default Label

import { FunctionComponent, ReactNode } from 'react'
import { css } from '@emotion/css'
import { Global, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

interface OverlayProps {
  /** Sets a method to be called on onClick events */
  onClick?: (...params: unknown[]) => void
  /** Defines if the component should be visible or not on all screens, use it to control the component */
  open?: boolean
  className?: string
  children?: ReactNode
}

const StyledOverlay = styled.aside<OverlayProps>`
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  background: ${(props) => (props.open ? 'rgba(0, 0, 0, 0.3)' : 'none')};
  position: fixed;
  display: grid;
  z-index: 9999;
  bottom: 0;
  right: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
`

const overlayBodyStyles = (theme: Theme): string => css`
  background: ${theme.palette.WHITE};
  position: absolute;
  max-width: 100vw;
  padding: 0;
`

// We need to export components that way so the the props documentation is generated automatically
const Overlay: FunctionComponent<OverlayProps> = ({ children, open, onClick, className }) => {
  const theme = useTheme()
  return (
    <StyledOverlay className={className} open={open} onClick={onClick}>
      <div className={overlayBodyStyles(theme)}>{children}</div>
      {open && (
        <Global
          styles={{
            body: {
              overflow: 'hidden',
            },
          }}
        />
      )}
    </StyledOverlay>
  )
}
export default Overlay

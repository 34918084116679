/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent, ReactNode, useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import Grid, { GridProps } from '../Grid'
import Overlay from '../Overlay'
import SvgIcon from '../SvgIcon'

enum ModalVariant {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
  BIG = 'BIG',
}

enum ScrollBehavior {
  OVERFLOW = 'OVERFLOW',
  SCROLL = 'SCROLL',
}

export interface ModalProps extends GridProps {
  /** Callback method to be executed when the modal is closed, you cant dismiss modals without an onClose */
  onClose?: (...params: unknown[]) => void
  /** Sets the modal state to open or closed */
  open?: boolean
  /** Sets the possibility for the modal to not be dismissible unless child submit is called */
  forceSubmit?: boolean
  /** Sets the modal variant appearance*/
  variant?: keyof typeof ModalVariant
  /** Sets the scroll behavior */
  scrollBehavior?: keyof typeof ScrollBehavior
  /** A JSX element to be shown at the top of the modal */
  header?: JSX.Element
  /** A JSX element to be shown at the bottom of the modal */
  footer?: ReactNode
  noBottomPadding?: boolean
  fullScreenInSmallDevice?: boolean
}

const StyledWrapper = styled.section<ModalProps>`
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  z-index: ${(props) => (props.open ? 9999 : -1)};
  align-content: center;
  transition: 0.2s ease;
  position: fixed;
  display: grid;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
`
type ParentRect = DOMRectList[0]

const getMaxWidth = (variant: ModalVariant | undefined): number => {
  switch (variant) {
    case ModalVariant.SMALL:
      return 350
    case ModalVariant.BIG:
      return 680
    case ModalVariant.DEFAULT:
    default:
      return 500
  }
}

const StyledModal = styled(Grid)<
  ModalProps & { parent: ParentRect; displayHeader: boolean; fullScreenInSmallDevice: boolean }
>`
  max-width: ${(props) => getMaxWidth(props.variant as ModalVariant)}px;
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background: ${(props) => props.theme.palette.WHITE};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: 0.2s ease;
  justify-self: center;
  z-index: 9999;
  width: 100%;
  margin: 0;
  grid-template-rows: 1fr;
  overflow: auto;
  padding: 0 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.XS}) {
    max-width: ${(props) => (props.variant === ModalVariant.SMALL ? '350px' : '100%')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    max-width: ${(props) =>
      props.fullScreenInSmallDevice ? '100%' : `${getMaxWidth(props.variant as ModalVariant)}px`};
    height: ${(props) => (props.fullScreenInSmallDevice ? '100vh' : 'auto')};
    border-radius: ${(props) => (props.fullScreenInSmallDevice ? 'unset' : '11px')};
    grid-template-rows: ${(props) => (props.fullScreenInSmallDevice ? 'auto' : '1fr')};
  }
`

const ModalBody = styled(Grid)<ModalProps & { displayHeader: boolean }>`
  padding-top: ${(props) => (props.displayHeader ? '0' : '1.5rem')};
  padding-bottom: ${(props) => (props.footer != null || props.noBottomPadding ? '0' : '1.5rem')};
  ${(props) =>
    props.theme.mixin.fluidRange(
      {
        prop: 'padding-left',
        fromSize: '16px',
        toSize: '36px',
      },
      {
        prop: 'padding-right',
        fromSize: '16px',
        toSize: '36px',
      }
    )};
`

const ModalHeader = styled.header`
  background: ${(props) => props.theme.palette.WHITE};
  grid-template-columns: auto 1fr;
  padding: 2rem 1rem 1rem;
  position: sticky;
  display: grid;
  z-index: 9;
  gap: 1rem;
  top: 0;
  border-radius: ${(props) => props.theme.shape.RADIUS};
`

const ModalFooter = styled.footer`
  background: ${(props) => props.theme.palette.WHITE};
  padding: 1.5rem 0;
  position: sticky;
  display: grid;
  z-index: 9;
  bottom: 0;
  gap: 1rem;

  ${(props) =>
    props.theme.mixin.fluidRange(
      {
        prop: 'padding-left',
        fromSize: '16px',
        toSize: '36px',
      },
      {
        prop: 'padding-right',
        fromSize: '16px',
        toSize: '36px',
      }
    )};
`

const Modal: FunctionComponent<ModalProps> = ({
  scrollBehavior,
  variant = ModalVariant.DEFAULT,
  forceSubmit,
  children,
  onClose,
  header,
  footer,
  open,
  noBottomPadding,
  fullScreenInSmallDevice = false,
  ...gridProps
}) => {
  const displayHeader = (onClose != null && !forceSubmit) || header != null
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const getClientRects = (): DOMRect => {
    return wrapperRef?.current?.getClientRects()[0] as DOMRect
  }

  useEffect(() => {
    const escDownHandler = ({ key }: KeyboardEvent): void => {
      if (key === 'Escape' && onClose != null && !forceSubmit) {
        onClose()
      }
    }

    if (open && !forceSubmit) {
      // eslint-disable-next-line no-restricted-properties
      window.addEventListener('keydown', escDownHandler)
    }
    // Remove event listeners on cleanup
    return () => {
      // eslint-disable-next-line no-restricted-properties
      window.removeEventListener('keydown', escDownHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, onClose, forceSubmit])

  function renderIcon(): JSX.Element | null {
    if (forceSubmit) {
      return null
    }
    return <SvgIcon onClick={onClose} color="PRIMARY" fontSize="24px" name="close" />
  }

  return (
    <StyledWrapper open={open} ref={wrapperRef}>
      <Overlay open={open} onClick={!forceSubmit && onClose != null ? onClose : undefined} />
      <StyledModal
        parent={getClientRects()}
        scrollBehavior={scrollBehavior}
        variant={variant}
        onClose={onClose}
        header={header}
        footer={footer}
        open={open}
        displayHeader={displayHeader}
        fullScreenInSmallDevice={fullScreenInSmallDevice}
        {...gridProps}
      >
        {displayHeader && (
          <ModalHeader>
            {renderIcon()}
            {header}
          </ModalHeader>
        )}
        <ModalBody footer={footer} displayHeader={displayHeader} noBottomPadding={noBottomPadding}>
          {children}
        </ModalBody>
        {footer != null && <ModalFooter>{footer}</ModalFooter>}
      </StyledModal>
    </StyledWrapper>
  )
}

export default Modal

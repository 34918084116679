import { HTMLAttributes, FunctionComponent } from 'react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { darken } from 'polished'
import iconList from '@/tbui/SvgIcon/icons'
import CircularLoader from '../CircularLoader'
import SvgIcon from '../SvgIcon'

export enum ButtonVariants {
  SECONDARY = 'SECONDARY',
  OUTLINE = 'OUTLINE',
  LINK = 'LINK',
  PRIMARY = 'PRIMARY',
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /** Makes the button have 100% width */
  fullWidth?: boolean
  /** Same as the disabled attribute for button elements */
  big?: boolean
  /** Changes the component appearance */
  variant?: keyof typeof ButtonVariants
  /** Same as the disabled html attr */
  disabled?: boolean
  /** html button type https://www.w3schools.com/tags/att_button_type.asp */
  type?: 'button' | 'submit' | 'reset'
  /** Displays a loader on the right side of the text */
  isLoading?: boolean
  /** Overrides the color of the text */
  color?: string
  /** Centers the children */
  center?: boolean
  /** Icon */
  icon?: keyof typeof iconList
}

const createPadding = ({ big, variant }: ButtonProps): string => {
  if (variant === ButtonVariants.LINK) {
    return '0'
  }
  if (big) {
    return '16px 36px'
  }
  return '12px 36px'
}

const createBackground = ({ variant }: ButtonProps, theme: Theme): string => {
  switch (variant) {
    case ButtonVariants.LINK:
      return 'transparent'
    case ButtonVariants.OUTLINE:
      return theme.palette.WHITE
    case ButtonVariants.SECONDARY:
      return theme.palette.SECONDARY
    case ButtonVariants.PRIMARY:
    default:
      return theme.palette.PRIMARY
  }
}

const createBackgroundHover = ({ variant }: ButtonProps, theme: Theme): string => {
  switch (variant) {
    case ButtonVariants.LINK:
      return 'transparent'
    case ButtonVariants.OUTLINE:
      return darken(0.05, theme.palette.WHITE)
    case ButtonVariants.SECONDARY:
      return darken(0.05, theme.palette.SECONDARY)
    default:
      return darken(0.05, theme.palette.PRIMARY)
  }
}

const createBackgroundDisabled = ({ variant }: ButtonProps, theme: Theme): string => {
  switch (variant) {
    case ButtonVariants.LINK:
      return 'transparent'
    default:
      return theme.palette.GRAY_1
  }
}

const createColor = ({ variant }: ButtonProps, theme: Theme): string => {
  switch (variant) {
    case ButtonVariants.LINK:
    case ButtonVariants.OUTLINE:
      return theme.palette.PRIMARY
    case ButtonVariants.SECONDARY:
      return theme.palette.TEXT_SECONDARY
    default:
      return theme.palette.TEXT_PRIMARY
  }
}

const createBorderColor = ({ variant }: ButtonProps, theme: Theme): string => {
  if (variant === ButtonVariants.OUTLINE) {
    return theme.palette.PRIMARY
  }
  return 'transparent'
}

const createBorderColorDisabled = ({ variant }: ButtonProps, theme: Theme): string => {
  switch (variant) {
    case ButtonVariants.LINK:
      return 'transparent'
    default:
      return theme.palette.GRAY_1
  }
}

const StyledButton = styled.button<ButtonProps>`
  border: ${(props) => props.theme.shape.BORDER}; // keep it first
  border-color: ${({ theme, ...props }) => createBorderColor(props, theme)};
  ${({ theme, fullWidth }) =>
    theme.mixin.dynamicProperty('width', fullWidth ? '100%' : undefined, 'auto')};
  font-family: ${(props) => props.theme.font.FAMILY_BOLD};
  background: ${({ theme, ...props }) => createBackground(props, theme)};
  color: ${({ theme, ...props }) => createColor(props, theme)};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  padding: ${(props) => createPadding(props)};
  letter-spacing: 0.2px;
  transition: ease 0.3s;
  white-space: nowrap;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  &:hover {
    background: ${({ theme, ...props }) => createBackgroundHover(props, theme)};
  }
  &:disabled {
    background: ${({ theme, ...props }) => createBackgroundDisabled(props, theme)};
    border-color: ${({ theme, ...props }) => createBorderColorDisabled(props, theme)};
    color: ${(props) => props.theme.palette.GRAY_2};
    pointer-events: none;
  }
  ${({ isLoading }) =>
    isLoading &&
    `display: flex;
    justify-content: center;
    align-items: center;`}
  ${({ center }) =>
    center &&
    `display: flex;
    justify-content: center;
    align-items: center;`}
`

const Button: FunctionComponent<ButtonProps> = ({ children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton {...props}>
      {children}
      {props.icon && <SvgIcon name={props.icon} color="TEXT_PRIMARY" fontSize="20px" m="0 1rem" />}
      {props.isLoading && <CircularLoader color="TEXT_PRIMARY" fontSize="20px" m="0 1rem" />}
    </StyledButton>
  )
}

export default Button

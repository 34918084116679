/* eslint-disable complexity */
import axios, { AxiosError } from 'axios'
import { NextRouter } from 'next/router'
import { ModalProps } from '@/tbui'
import type { ParsedError, ServerErrorResponse } from './error_handler'

export enum PaymentErrorCode {
  UNSUPPORTED_CARD_TYPE = 'err_unsupported_card_type',
  ADDRESS_MISMATCH = 'err_address_mismatch',
  GENERAL_DECLINE = 'err_general_decline',
  INSUFFICIENT_FUNDS = 'err_insufficient_funds',
  LOST_OR_STOLEN = 'err_lost_or_stole',
  EXPIRED = 'err_expired',
  CVV_MISMATCH = 'err_cvv_mismatch',
  REJECTED_BY_ISSUING_BANK = 'err_rejected_by_issuing_bank',
  CARD_CANNOT_TRANSACT = 'err_card_cannot_transact',
  CVV_REQUIRED = 'err_cvv_required',
  DATE_MUST_BE_IN_FUTURE = 'err_date_must_be_in_future',
  INVALID_POSTAL_CODE_FOR_COUNTRY_CA = 'err_invalid_postal_code_for_country_ca',
  INVALID_POSTAL_CODE_FOR_COUNTRY_US = 'err_invalid_postal_code_for_country_us',
  INVALID_CREDIT_CARD_NUMBER = 'err_invalid_credit_card_number',
  PARAM_VALUE_IS_TOO_SMALL_INCLUSIVE = 'err_param_value_is_too_small_inclusive',
  PARAM_VALUE_IS_TOO_LONG = 'err_param_value_is_too_long',
  HOSTED_PAYMENT_ERROR = 'err_hosted_payment_failed',
}

export enum DoordashErrorCodes {
  DD_FIELD_ERROR = 'err_doordash_field_errors',
  CUSTOMER_PHONE_INVALID = 'err_doordash_customer_phone_invalid',
  CUSTOMER_PHONE_REQUIRED = 'err_doordash_customer_phone_required',
  CUSTOMER_FIRST_NAME = 'err_doordash_customer_first_name',
  CUSTOMER_LAST_NAME = 'err_doordash_customer_last_name',
  DROPOFF_ADDRESS_CITY = 'err_doordash_dropoff_address_city',
  DROPOFF_ADDRESS_STREET = 'err_doordash_dropoff_address_street',
  DROPOFF_ADDRESS_STATE = 'err_doordash_dropoff_address_state',
  DROPOFF_ADDRESS_ZIP_CODE = 'err_doordash_dropoff_address_zip_code',
  DROPOFF_ADDRESS_UNIT_LENGTH = 'err_doordash_dropoff_address_unit_length',
  DROPOFF_ADDRESS_INVALID = 'err_doordash_dropoff_address_invalid',
  DROPOFF_ADDRESS_PICKUP_DISTANCE = 'err_doordash_dropoff_address_pickup_distance',
  DROPOFF_ADDRESS_TOO_FAR = 'err_doordash_dropoff_address_too_far',
  DROPOFF_ADDRESS_STREET_INVALID = 'err_doordash_dropoff_address_street_invalid',
  PICKUP_TIME_TOO_LATE = 'err_doordash_pickup_time_too_late',
  PICKUP_TIME = 'err_doordash_pickup_time',
  PICKUP_TIME_FORMAT = 'err_doordash_pickup_time_format',
  STORE = 'err_doordash_store',
  TIP_TOO_BIG = 'err_doordash_tip_too_big',
  TIP_INVALID = 'err_doordash_tip_invalid',
  TIP_DELIVERIES = 'err_doordash_tip_deliveries',
}

export enum OrderSubmitErrorCode {
  ORDER_VALIDATION_ERROR = 'err_order_validation_error',
  ORDER_THROTTLING_LIMIT_ERROR = 'err_order_throttling_limit_reached',
}

export enum StaleCartErrorCode {
  MISSING_MENU = 'err_stale_cart_missing_menu',
  MISSING_ITEM = 'err_stale_cart_missing_item',
  MISSING_ITEM_PRICE = 'err_stale_cart_missing_price',
  MISSING_ITEM_MODIFIER = 'err_stale_cart_missing_item_mod',
  MISSING_MODIFIER = 'err_stale_cart_missing_modifier',
  MISSING_OPTION = 'err_stale_cart_missing_option',
  MIN_MOD = 'err_order_min_modifier_error',
  MAX_MOD = 'err_order_max_modifier_error',
  MODIFIER_GENERIC = 'err_order_modifiers',
}

export enum ScheduledOrderErrorCode {
  VALIDATE_VENUE_CLOSED = 'err_validate_closed',
  VALIDATE_OUTSIDE_TIMESLOTS = 'err_validate_outside_timeslots',
  VALIDATE_OVER_MAX_DAYS = 'err_validate_requested_over_max_day',
  VALIDATE_PAST_REQUESTED = 'err_validate_requested_in_past',
  NO_TIMESLOTS = 'err_schedule_no_time_slots',
}

export enum LoyaltyRewardErrorCode {
  UnavailableReward = 'err_unavailable_reward',
  RewardRemoved = 'err_reward_removed',
  CreateTransactionFailed = 'err_create_transaction_failed',
  UpdateTransactionFailed = 'err_update_transaction_failed',
  GetTransactionFailed = 'err_get_transaction_failed',
  RedeemRewardFailed = 'err_redeem_reward_failed',
  RemoveRewardFailed = 'err_remove_reward_failed',
  CommitTransactionFailed = 'err_commit_transaction_failed',
  RevokeTransactionFailed = 'err_revoke_transaction_failed',
  LookupFailed = 'err_loyalty_useraccount_lookup_error',
  GiftCardInvalidOrSpent = 'err_giftcard_invalid_or_spent',
}

export enum ModalPageErrorCode {
  DeliveryAddressOutOfBounds = 'err_out_of_delivery_boundaries_error',
  NoVenueDeliveryBounds = 'err_no_delivery_boundaries_error',
  InvalidAddress = 'err_invalid_address',
}

export enum FullPageErrorCode {
  FeatureFlagNotAuthorized = 'err_feature_flag_unauthorized',
  ModelNotFound = 'err_model_not_found',
  SettingsNotFound = 'err_settings_not_found',
}

interface ErrorModalContext {
  translationContext: string
  callToActionHandler?: () => void
  modalOptions?: ModalProps
}

// uses error code to translate modal `title` and `message`
// in some cases, returns a `callToActionHandler`
// that fires on click of modal submit button
// it also allows for overrides to the modal provider
export function mapErrorToErrorModalContent(
  error:
    | ParsedError
    | AxiosError
    | ModalPageErrorCode
    | PaymentErrorCode
    | ScheduledOrderErrorCode
    | LoyaltyRewardErrorCode
    | unknown,
  router: NextRouter
): ErrorModalContext {
  let code = ''

  if (axios.isAxiosError(error)) {
    // errors coming from the client
    // check if error is of type AxiosErrorResponse, as it could be an unexpected error in our JS code
    code = (error as AxiosError<ServerErrorResponse>).response?.data?.error?.code ?? ''
  } else if ((error as ParsedError).code) {
    // errors coming from ssr
    code = (error as ParsedError).code ?? ''
  } else if (typeof error === 'string') {
    // Allows us to pass in custom error code like those found in ModalPageErrorCode
    // (so we can scope an error to a specific page/api call/functionality)
    code = error
  }

  switch (code) {
    // Doordash error codes
    case DoordashErrorCodes.CUSTOMER_FIRST_NAME:
    case DoordashErrorCodes.CUSTOMER_LAST_NAME:
    case DoordashErrorCodes.CUSTOMER_PHONE_REQUIRED:
    case DoordashErrorCodes.CUSTOMER_PHONE_INVALID:
    case DoordashErrorCodes.PICKUP_TIME:
    case DoordashErrorCodes.PICKUP_TIME_FORMAT:
    case DoordashErrorCodes.PICKUP_TIME_TOO_LATE:
    case DoordashErrorCodes.DROPOFF_ADDRESS_CITY:
    case DoordashErrorCodes.DROPOFF_ADDRESS_INVALID:
    case DoordashErrorCodes.DROPOFF_ADDRESS_PICKUP_DISTANCE:
    case DoordashErrorCodes.DROPOFF_ADDRESS_STATE:
    case DoordashErrorCodes.DROPOFF_ADDRESS_STREET:
    case DoordashErrorCodes.DROPOFF_ADDRESS_TOO_FAR:
    case DoordashErrorCodes.DROPOFF_ADDRESS_UNIT_LENGTH:
    case DoordashErrorCodes.DROPOFF_ADDRESS_STREET_INVALID:
    case DoordashErrorCodes.DROPOFF_ADDRESS_ZIP_CODE:
    case DoordashErrorCodes.TIP_DELIVERIES:
    case DoordashErrorCodes.TIP_INVALID:
    case DoordashErrorCodes.TIP_TOO_BIG:
    case DoordashErrorCodes.STORE:
    case DoordashErrorCodes.DD_FIELD_ERROR:
    case PaymentErrorCode.ADDRESS_MISMATCH:
    case PaymentErrorCode.CARD_CANNOT_TRANSACT:
    case PaymentErrorCode.CVV_MISMATCH:
    case PaymentErrorCode.CVV_REQUIRED:
    case PaymentErrorCode.DATE_MUST_BE_IN_FUTURE:
    case PaymentErrorCode.EXPIRED:
    case PaymentErrorCode.GENERAL_DECLINE:
    case PaymentErrorCode.INSUFFICIENT_FUNDS:
    case PaymentErrorCode.INVALID_CREDIT_CARD_NUMBER:
    case PaymentErrorCode.INVALID_POSTAL_CODE_FOR_COUNTRY_CA:
    case PaymentErrorCode.INVALID_POSTAL_CODE_FOR_COUNTRY_US:
    case PaymentErrorCode.LOST_OR_STOLEN:
    case PaymentErrorCode.PARAM_VALUE_IS_TOO_SMALL_INCLUSIVE:
    case PaymentErrorCode.REJECTED_BY_ISSUING_BANK:
    case PaymentErrorCode.UNSUPPORTED_CARD_TYPE:
    case PaymentErrorCode.PARAM_VALUE_IS_TOO_LONG:
    case ModalPageErrorCode.InvalidAddress:
    case OrderSubmitErrorCode.ORDER_VALIDATION_ERROR:
    case ScheduledOrderErrorCode.NO_TIMESLOTS:
    case StaleCartErrorCode.MISSING_ITEM:
    case StaleCartErrorCode.MISSING_ITEM_MODIFIER:
    case StaleCartErrorCode.MISSING_ITEM_PRICE:
    case StaleCartErrorCode.MISSING_OPTION:
    case StaleCartErrorCode.MISSING_MENU:
      return {
        translationContext: code,
        callToActionHandler: () => {
          router.reload()
        },
      }
    case ScheduledOrderErrorCode.VALIDATE_VENUE_CLOSED:
    case ScheduledOrderErrorCode.VALIDATE_PAST_REQUESTED:
    case ScheduledOrderErrorCode.VALIDATE_OVER_MAX_DAYS:
    case ScheduledOrderErrorCode.VALIDATE_OUTSIDE_TIMESLOTS:
    case StaleCartErrorCode.MODIFIER_GENERIC:
      return {
        translationContext: code,
        callToActionHandler: () => {
          router.push(
            '/[orderMethod]/[venueXRefID]/menu',
            `/${router.query.orderMethod}/${router.query.venueXRefID}/menu`
          )
        },
      }

    case ModalPageErrorCode.DeliveryAddressOutOfBounds:
    case ModalPageErrorCode.NoVenueDeliveryBounds:
      return {
        translationContext: code,
        modalOptions: {
          forceSubmit: true,
        },
      }

    case LoyaltyRewardErrorCode.LookupFailed:
      return {
        translationContext: code,
        modalOptions: {
          forceSubmit: false,
        },
      }
    case LoyaltyRewardErrorCode.RedeemRewardFailed:
    case LoyaltyRewardErrorCode.RemoveRewardFailed:
      return {
        translationContext: LoyaltyRewardErrorCode.UnavailableReward,

        modalOptions: {
          forceSubmit: true,
        },
      }
    case LoyaltyRewardErrorCode.UnavailableReward:
      return {
        translationContext: LoyaltyRewardErrorCode.RewardRemoved,
        modalOptions: {
          forceSubmit: true,
        },
      }
    case LoyaltyRewardErrorCode.CreateTransactionFailed:
    case LoyaltyRewardErrorCode.UpdateTransactionFailed:
    case LoyaltyRewardErrorCode.CommitTransactionFailed:
    case LoyaltyRewardErrorCode.RevokeTransactionFailed:
    case LoyaltyRewardErrorCode.GetTransactionFailed: {
      return {
        translationContext: LoyaltyRewardErrorCode.CreateTransactionFailed,
      }
    }
    case PaymentErrorCode.HOSTED_PAYMENT_ERROR:
      return {
        translationContext: code,
        modalOptions: {
          forceSubmit: true,
        },
      }
    default:
      return {
        translationContext: 'modal_title',
      }
  }
}

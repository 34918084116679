import { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'

import { OrderMethod } from '@/libs/helpers/utils'

const ValidateOrderMethod: FunctionComponent<{
  children: JSX.Element
  orderMethod: OrderMethod | undefined
  venueXRefID: string | undefined
}> = ({ children, orderMethod, venueXRefID }) => {
  // HOOKS
  const router = useRouter()

  // EFFECTS
  useEffect(() => {
    const getWhiteListedRoutes = (): string[] => {
      const whiteListedRoutes = [...Object.keys(OrderMethod), 'profile']

      if (venueXRefID) {
        return whiteListedRoutes
      }

      // If there is no second route param, whitelist /redirect too
      return [...whiteListedRoutes, 'signin', 'signout', 'signin-error', 'password-reset']
    }

    // only validate if we have orderMethod (bypass validations at Homepage)
    const validatePathOrderMethod = (): void => {
      const whiteListedRoutes = getWhiteListedRoutes()

      if (orderMethod != null) {
        const defaultOrderMethod = (): void => {
          const newRoute = router.asPath.replace(orderMethod, 'pickup')
          router.replace(router.route, newRoute)
        }

        const isOrderMethodInvalid = !whiteListedRoutes.includes(orderMethod.toLowerCase())

        // the orderMethod should be valid, otherwise, redirect
        if (isOrderMethodInvalid) {
          defaultOrderMethod()
          return
        }
      }
    }

    validatePathOrderMethod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueXRefID, orderMethod, router])

  return children
}

export default ValidateOrderMethod

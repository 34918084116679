import { Theme } from '@emotion/react'
import { fluidRange as baseFluidRange, rgba } from 'polished'

type Styles =
  | string
  | number
  | {
      [ruleOrSelector: string]: Styles
    }

interface Colors {
  DUPE: string
  NAVY: string
  LAKE: string
  CLOUD: string
  WHITE: string
  PEBBLE: string
  FEATHER_GREY: string
  SLATE: string
  SPACE_GREY: string
  CORAL: string
  SHELL: string
  KALE: string
  TRANSPARENT: string
  HARLEY_DAVIDSON_ORANGE: string
  HEATHER_GRAY: string
  PUMPKIN: string
  LIGHT_YELLOW: string
  MINT_CREAM: string
  LEMON: string
}

interface Palette {
  PRIMARY: string
  SECONDARY: string
  SUCCESS: string
  SUCCESS_LIGHT: string
  ERROR: string
  ERROR_LIGHT: string
  ERROR_TEXT: string
  // Text that changes color based on PRIMARY bg
  TEXT_PRIMARY: string
  // Text that changes color based on SECONDARY bg
  TEXT_SECONDARY: string
  TEXT: string
  TEXT_2: string
  WHITE: string
  GRAY_1: string
  GRAY_2: string
  GRAY_3: string
  GRAY_4: string
  TRANSPARENT: string
  SHADOW: string
  WARNING_PRIMARY: string
  WARNING_SECONDARY: string
  WARNING_ICON: string
  INFO: string
}

interface BreakPoints {
  LG: string
  MD: string
  SM: string
  XS: string
  ELG: string
}

interface Shape {
  RADIUS_ALT: string
  CONTAINER: string
  SHADOW: string
  SHADOW_TOP: string
  BORDER: string
  RADIUS: string
}

interface Font {
  LINE_HEIGHT: string
  FAMILY_BOLD: string
  FAMILY: string
  FAMILY_HEADING: string
  WEIGHT_HEADING: string
  FAMILY_TEXT: string
  WEIGHT_TEXT: string
  BASE_SIZE: string
}

interface FluidRangeConfiguration {
  fromSize: string
  toSize: string
  prop: string
}

interface Mixin {
  fluidRange: (...cssProp: FluidRangeConfiguration[]) => Styles
  dynamicProperty: (
    cssProperty: string,
    dynamicValue: string | number | undefined,
    defaultsTo: string | number
  ) => string
  dynamicColor: (
    theme: Theme,
    colorProperty: string,
    dynamicValue: keyof Palette | undefined,
    defaultsTo: keyof Palette
  ) => string
}

declare module '@emotion/react' {
  export interface Theme {
    breakpoints: BreakPoints
    breakpointValues: typeof breakpointValues
    assetsRoot: string
    palette: Palette
    colors: Colors
    shape: Shape
    mixin: Mixin
    font: Font
  }
}

export const colors: Theme['colors'] = {
  DUPE: '#00838D',
  NAVY: '#06556E',
  LAKE: '#DCECEF',
  CLOUD: '#F3F7F8',
  WHITE: '#FFFFFF',
  PEBBLE: '#F2F2F2',
  FEATHER_GREY: '#CCCCCC',
  SLATE: '#757575',
  SPACE_GREY: '#454545',
  CORAL: '#F16651',
  SHELL: '#FEF8F7',
  KALE: '#159948',
  TRANSPARENT: 'transparent',
  HARLEY_DAVIDSON_ORANGE: '#DD3105',
  HEATHER_GRAY: '#666666',
  PUMPKIN: '#E5780F',
  LIGHT_YELLOW: '#FFFAEF',
  MINT_CREAM: '#F3FAF6',
  LEMON: '#FFB81C',
}

export const palette: Theme['palette'] = {
  PRIMARY: colors.DUPE,
  SECONDARY: colors.LAKE,
  SUCCESS: colors.KALE,
  SUCCESS_LIGHT: colors.MINT_CREAM,
  ERROR: colors.CORAL,
  ERROR_LIGHT: colors.SHELL,
  ERROR_TEXT: colors.HARLEY_DAVIDSON_ORANGE,
  TEXT_PRIMARY: colors.WHITE,
  TEXT_SECONDARY: colors.SPACE_GREY,
  TEXT: colors.SPACE_GREY,
  TEXT_2: colors.SLATE,
  WHITE: colors.WHITE,
  GRAY_1: colors.PEBBLE,
  GRAY_2: colors.FEATHER_GREY,
  GRAY_3: colors.SLATE,
  GRAY_4: colors.HEATHER_GRAY,
  TRANSPARENT: 'transparent',
  SHADOW: rgba(0, 0, 0, 0.08),
  WARNING_PRIMARY: colors.PUMPKIN,
  WARNING_SECONDARY: colors.LIGHT_YELLOW,
  WARNING_ICON: colors.LEMON,
  INFO: colors.NAVY,
}

export const breakpointValues: { [size: string]: number } = {
  ELG: 1440,
  LG: 1200,
  MD: 1024,
  SM: 768,
  XS: 375,
}

export const breakpoints: Theme['breakpoints'] = {
  ELG: `${breakpointValues.ELG}px`,
  LG: `${breakpointValues.LG}px`,
  MD: `${breakpointValues.MD}px`,
  SM: `${breakpointValues.SM}px`,
  XS: `${breakpointValues.XS}px`,
}

export const shape: Theme['shape'] = {
  SHADOW: '0 0 16px 0 rgba(182, 182, 182, 0.5)',
  SHADOW_TOP: '0 -16px 16px -16px rgba(182, 182, 182, 0.5)',
  BORDER: '1px solid transparent',
  CONTAINER: '1280px',
  RADIUS: '8px',
  RADIUS_ALT: '4px',
}

export const font = {
  FAMILY: 'Graphik-Regular, Helvetica, Arial, sans-serif',
  FAMILY_BOLD: 'Graphik-Medium, Helvetica, Arial, sans-serif',
  LINE_HEIGHT: '1.25',
  FAMILY_HEADING: 'Graphik-Medium, Helvetica, Arial, sans-serif',
  WEIGHT_HEADING: '500',
  FAMILY_TEXT: 'Graphik-Regular, Helvetica, Arial, sans-serif',
  WEIGHT_TEXT: '400',
  BASE_SIZE: '14px',
}

export const mixin: Theme['mixin'] = {
  fluidRange: (...cssProp: FluidRangeConfiguration[]) => {
    return baseFluidRange([...cssProp], breakpoints.XS, breakpoints.ELG)
  },
  dynamicProperty: (cssProperty, dynamicValue, defaultsTo) => {
    return `${cssProperty}: ${dynamicValue || defaultsTo}`
  },
  dynamicColor: (theme, colorProperty, dynamicValue, defaultsTo) => {
    return `${colorProperty}: ${theme.palette[dynamicValue || defaultsTo]}`
  },
}

const defaultTheme = {
  assetsRoot: '',
  breakpoints,
  breakpointValues,
  palette,
  colors,
  shape,
  mixin,
  font,
}

export default defaultTheme

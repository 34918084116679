import { FunctionComponent, InputHTMLAttributes, RefObject } from 'react'

import styled from '@emotion/styled'
import { formStyles } from './styles'

export { formStyles }

export enum InputVariant {
  INSET = 'INSET',
  INPUT = 'INPUT',
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Changes input style to error color */
  hasError?: boolean
  /** Changes component style removing its border and padding  */
  variant?: keyof typeof InputVariant
  /** Same as the value attr */
  value?: string
  /** Exposes the ref prop */
  ref?: RefObject<HTMLInputElement>
}

const StyledInput = styled.input<InputProps>`
  border: none; // this goes first
  padding: ${({ variant }) => (variant === InputVariant.INSET ? '0' : '8px 0')};
  border-bottom: ${({ variant, theme }) =>
    variant === InputVariant.INSET ? 0 : theme.shape.BORDER};
  border-color: ${({ hasError, theme }) => (hasError ? theme.palette.ERROR : theme.palette.GRAY_2)};
  color: ${({ theme }) => theme.palette.TEXT};
  background: transparent;
  transition: 0.2s ease;
  border-radius: 0;
  font-size: 14px;
  outline: none;
  &:focus {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.palette.ERROR : theme.palette.PRIMARY};
  }
`

// We need to export components that way so the the props documentation is generated automatically
const Input: FunctionComponent<InputProps> = StyledInput
export default Input

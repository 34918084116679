import {
  FunctionComponent,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  useMemo,
  useState,
} from 'react'

import { Modal, ModalProps } from '@/tbui'

interface StateType {
  setModalContent: Dispatch<ReactNode>
  setModalProps: Dispatch<ModalProps>
  modalContent: ReactNode
  modalProps: ModalProps | null
}

const ModalContext = createContext<StateType>({} as StateType)

export interface ModalProviderHook {
  openModal: (modalContent: ReactNode, modalProps?: ModalProps) => void
  modalContent: ReactNode
  closeModal: () => void
  modalProps: ModalProps | null
}

const useModal = (): ModalProviderHook => {
  const { setModalContent, setModalProps, modalContent, modalProps } = useContext(ModalContext)

  const openModal = (content: ReactNode, options: ModalProps = {}): void => {
    setModalContent(content)
    setModalProps(options)
  }

  const closeModal = async (): Promise<void> => {
    setModalContent(null) // undefined breaks React
    setModalProps({})
  }

  return { modalProps, modalContent, openModal, closeModal }
}

const ModalProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode>(null)
  const [modalProps, setModalProps] = useState<ModalProps | null>(null)
  const ctxValue = useMemo(
    () => ({ setModalContent, setModalProps, modalContent, modalProps }),
    [setModalContent, setModalProps, modalContent, modalProps]
  )

  return <ModalContext.Provider value={ctxValue}>{children}</ModalContext.Provider>
}

const ModalComponent = (): JSX.Element | null => {
  const { modalContent, closeModal, modalProps } = useModal()

  return modalContent ? (
    <Modal
      onClose={closeModal}
      // default the onClose method in case it is not provided
      // overwrite all the other Modal props with modalProps except `open`
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
      open={modalContent != null}
    >
      {modalContent}
    </Modal>
  ) : null
}

export default ModalProvider
export type UseModalType = ReturnType<typeof useModal>
export { useModal, ModalComponent }

import { useReducer, FunctionComponent, useContext, useMemo, createContext, ReactNode } from 'react'
import { TransactionSetupRequestAddress } from '@/libs/helpers/apiClient'

interface ConfigurationUserSession {
  isInitialized: boolean
  isSignedIn: boolean
  billingAddress?: TransactionSetupRequestAddress
}

export type ConfigurationUserSessionPayload = ConfigurationUserSession

export enum ConfigurationActionType {
  SET_USER_SESSION,
}

interface ConfigurationSetUserSessionAction {
  type: ConfigurationActionType.SET_USER_SESSION
  payload: ConfigurationUserSessionPayload
}

export type ConfigurationAction = ConfigurationSetUserSessionAction

export interface State {
  userSession: ConfigurationUserSessionPayload
}

const initialState: State = {
  userSession: {
    isInitialized: false,
    isSignedIn: false,
    billingAddress: undefined,
  },
}

const configurationReducer = (state: State, action: ConfigurationAction): State => {
  switch (action.type) {
    case ConfigurationActionType.SET_USER_SESSION:
      return {
        ...state,
        userSession: action.payload,
      }
    default:
      throw new Error('Unexpected action')
  }
}

const ConfigurationContext = createContext({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: (value: ConfigurationAction) => {
    // no-op
  },
})

const ConfigurationProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(configurationReducer, initialState)
  const ctxValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <ConfigurationContext.Provider value={ctxValue}>{children}</ConfigurationContext.Provider>
}

// ReadOnly hook for convenience
const useConfiguration = (): State => useContext(ConfigurationContext).state

export { ConfigurationContext, ConfigurationProvider, useConfiguration }

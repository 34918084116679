import { FunctionComponent, useContext, useEffect } from 'react'
import { getAuthMe } from '@/libs/helpers/apiClient'
import {
  ConfigurationActionType,
  ConfigurationContext,
} from '@/providers/configuration/ConfigurationContext'

const Initializer: FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const { dispatch: dispatchConfiguration } = useContext(ConfigurationContext)

  // Retrieve user's auth status and dispatch the user session status
  const fetchUserSession = async (): Promise<void> => {
    try {
      const { user } = await getAuthMe()
      dispatchConfiguration({
        type: ConfigurationActionType.SET_USER_SESSION,
        payload: {
          isSignedIn: user !== null,
          isInitialized: true,
        },
      })
    } catch {
      dispatchConfiguration({
        type: ConfigurationActionType.SET_USER_SESSION,
        payload: {
          isSignedIn: false,
          isInitialized: true,
        },
      })
    }
  }

  useEffect(() => {
    fetchUserSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default Initializer

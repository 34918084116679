/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, FunctionComponent } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { PageTransition } from 'next-page-transitions'
import publicConfig from '@/config/public'
import { isPageBlockingError } from '@/libs/helpers/error_handler'
import Providers from '@/providers'
import ErrorPage from './_error'

const InitializeObservability: FunctionComponent<{
  children: JSX.Element
}> = ({ children }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      datadogRum.init({
        applicationId: publicConfig.datadogApplicationId,
        clientToken: publicConfig.datadogClientToken,
        service: publicConfig.datadogService,
        env: publicConfig.environment,
        site: 'datadoghq.com',
        trackResources: true,
        trackUserInteractions: false, // does not leak PII to DD RUM when false
        sessionSampleRate: 100,
        sessionReplaySampleRate: 25,
        useCrossSiteSessionCookie: true,
        version: process.env.NEXT_PUBLIC_GITSHA,
        allowedTracingUrls: [/https:\/\/.*\.tbdine\.com/],
        defaultPrivacyLevel: 'allow',
      })
      datadogRum.startSessionReplayRecording()
    }
  }, [])

  return children
}

const MyApp: FunctionComponent<AppProps> = ({ Component: PageComponent, pageProps, router }) => {
  return (
    <InitializeObservability>
      <>
        <Head>
          <title>TouchBistro - Online Ordering</title>
          <meta
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag#viewport_basics
            content="width=device-width,minimum-scale=1,maximum-scale=1,initial-scale=1"
            name="viewport"
            key="viewport"
          />
        </Head>
        {/* Google Tag Manager */}
        <Script id="gtm-script-dl" strategy="afterInteractive">
          {`window.dataLayer = window.dataLayer || [];`}
        </Script>
        <Script id="gtm-script" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${publicConfig.googleTagManagerId}');`}
        </Script>
        {/* End Google Tag Manager */}
        <Providers pageProps={pageProps}>
          <PageTransition timeout={250} classNames="page-transition">
            {pageProps.error != null && isPageBlockingError(pageProps.error.statusCode) ? (
              <ErrorPage serverError={pageProps.error} />
            ) : (
              <PageComponent {...pageProps} key={router.route} />
            )}
          </PageTransition>
        </Providers>
      </>
    </InitializeObservability>
  )
}

export default MyApp

import {
  FunctionComponent,
  createContext,
  useReducer,
  useContext,
  Dispatch,
  ReactNode,
} from 'react'
import { AxiosError } from 'axios'
import { useRouter, NextRouter } from 'next/router'

import { ModalPageErrorCode } from '@/libs/helpers/errorMapper'
import {
  useConfiguration,
  ConfigurationUserSessionPayload,
} from '@/providers/configuration/ConfigurationContext'
import { useError } from '@/providers/error'

// Types
export type Dispatcher<StateType, ActionTypes> = Dispatch<{
  type: ActionTypes
  payload?: StateType | null
}>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Action = (...args: any[]) => void | Promise<void>

export type ActionsCreator<StateType, ActionTypes> = ({
  setFullPageError,
  setModalError,
  prevState,
  dispatch,
  router,
  userSession,
}: {
  setFullPageError: (errorResponse: AxiosError | unknown) => void
  setModalError: (error: AxiosError | ModalPageErrorCode | unknown) => void
  dispatch: Dispatcher<StateType, ActionTypes>
  prevState: StateType
  router: NextRouter
  userSession: ConfigurationUserSessionPayload & { consumerXRefID?: string }
}) => { [name: string]: Action }

export type Reducer<StateType, ActionTypes> = (
  router: NextRouter
) => (state: StateType, action: { type: ActionTypes; payload?: StateType | null }) => StateType

/**
 * @deprecated
 * generic react context provider factory
 * used only for googleMaps and venue search context providers
 * IMPORTANT: PLEASE DO NOT REUSE THIS ANYMORE
 */
function providerFactory<StateType, GeneratedActions, ActionTypes>({
  useActionsCreator,
  initialState,
  reducer,
}: {
  initialState: StateType
  useActionsCreator: ActionsCreator<StateType, ActionTypes>
  reducer: Reducer<StateType, ActionTypes>
}): {
  Provider: FunctionComponent<{ children: ReactNode }>
  useProvider: () => [StateType, GeneratedActions]
} {
  const DispatchCtx = createContext<Dispatcher<StateType, ActionTypes>>(
    null as unknown as Dispatcher<StateType, ActionTypes>
  )
  const StateCtx = createContext<StateType>(null as unknown as StateType)

  const useProvider = (): [StateType, GeneratedActions] => {
    const { setFullPageError, setModalError } = useError()
    const dispatch = useContext(DispatchCtx)
    const state = useContext(StateCtx)
    const router = useRouter()
    const { userSession } = useConfiguration()
    const actions = useActionsCreator({
      prevState: state,
      setFullPageError,
      setModalError,
      dispatch,
      router,
      userSession,
    }) as unknown as GeneratedActions

    return [state, actions]
  }

  const Provider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const router = useRouter()
    const reducerWithRoute = reducer(router)
    const [state, dispatch] = useReducer(reducerWithRoute, initialState)

    return (
      <DispatchCtx.Provider value={dispatch}>
        <StateCtx.Provider value={state}>{children}</StateCtx.Provider>
      </DispatchCtx.Provider>
    )
  }

  return { Provider, useProvider }
}

// Exports
export default providerFactory

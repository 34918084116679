import { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGeolocation } from '@/libs/helpers/hooks/useGeolocation'
import { OrderMethod } from '@/libs/helpers/utils'
import { useGoogleMaps } from '@/providers/googleMaps'
import { useSearch } from '@/providers/state/search'
import { Button, CircularLoader, Grid, SvgIcon } from '@/tbui'
import AddressAutocompleteComponent, {
  SearchInputByAddressVariant,
} from './AddressAutocompleteComponent'
import DropDownSelector from './DropDownSelector'

interface SearchInputByAddressProps {
  variant: SearchInputByAddressVariant
  orderMethod: OrderMethod
}

interface SearchInputProps {
  variant: SearchInputByAddressVariant
}

const StyledWrapper = styled.div<SearchInputProps>`
  position: relative; // makes the popover component to be positioned relatively to this element
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background: ${(props) => props.theme.palette.WHITE};
  max-width: ${(props) => props.theme.breakpoints.SM};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  grid-template-columns: auto 1fr auto;
  align-content: center;
  align-items: center;
  display: grid;
  margin: ${(props) =>
    props.variant === SearchInputByAddressVariant.ADDRESS_HEADER ? '0 20px' : '0'};
  padding: ${(props) =>
    props.variant === SearchInputByAddressVariant.ADDRESS_HEADER ? '4px' : '8px'};
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    gap: ${(props) =>
      props.variant === SearchInputByAddressVariant.ADDRESS_HEADER ? '16px' : '0'};
  }
  @media (max-width: 550px) {
    margin-bottom: 20px;
  }
  @media (max-width: 430px) {
    grid-template-columns: 1fr;
    gap: ${(props) =>
      props.variant === SearchInputByAddressVariant.ADDRESS_HEADER ? '10px' : '0'};
  }
`

const StyledGridHome = styled(Grid)`
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    bottom: calc(-100% - 28px);
    position: absolute;
    gap: 20px;
    margin: 0;
    right: 0;
    left: 0;
  }
`

const StyledGridSearch = styled(Grid)`
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    display: none;
  }
`

const StyledGridInput = styled(Grid)<SearchInputProps>`
  position: static; // makes the popover to be positioned to the next parent
  grid-template-columns: auto 1fr auto;
  padding: 0 0.5rem;
  gap: 0.75rem;
  ${(props): string =>
    props.variant === SearchInputByAddressVariant.ADDRESS_HEADER
      ? `@media (max-width: ${props.theme.breakpoints.SM}) {
        grid-template-columns: 1fr;
      }`
      : ''};
  ${(props): string =>
    props.variant === SearchInputByAddressVariant.ADDRESS_HEADER
      ? `@media (max-width: 430px) {
        margin-bottom: 5px;
      }`
      : ''};
`

const StyledIcon = styled(SvgIcon)<SearchInputProps>`
  ${(props): string =>
    props.variant === SearchInputByAddressVariant.ADDRESS_HEADER
      ? `@media (max-width: ${props.theme.breakpoints.SM}) {
        display: none;
      }`
      : ''}
`

const SearchInputByAddress: FunctionComponent<SearchInputByAddressProps> = ({
  variant,
  orderMethod,
}) => {
  // HOOKS/STATES
  const [{ orderingAddress }, { selectOrderingAddress }] = useGoogleMaps()
  const [, { setOrderMethod }] = useSearch()
  const geolocation = useGeolocation()
  const { t } = useTranslation()

  // CONSTS
  const { permission, isLoading: loadingGeolocation, position } = geolocation
  const geolocationIsDenied = permission === 'denied'
  const orderMethodOptions = [OrderMethod.delivery, OrderMethod.pickup].map((option) => ({
    label: t(`order_method.${option.toLowerCase()}`),
    value: option.toLowerCase(),
  }))

  // HANDLERS
  const handleSearch = (selectedMethod: string): void => {
    if (orderingAddress) {
      setOrderMethod(selectedMethod as OrderMethod)
    }
  }

  const handleClickGeolocation = (): void => {
    selectOrderingAddress({
      lng: position?.coords.longitude,
      lat: position?.coords.latitude,
    })
  }

  // RENDERERS
  const renderDropDown = (): ReactNode => {
    if (variant === SearchInputByAddressVariant.ADDRESS_HOME) {
      return <span />
    }

    return (
      <DropDownSelector
        options={orderMethodOptions}
        value={orderMethod.toLowerCase()}
        onChange={handleSearch}
        groupId="order_method"
        placeholder={t('order_method.placeholder')}
      />
    )
  }

  const renderLocationIcon = (): ReactNode => {
    if (geolocationIsDenied) {
      return null
    }
    if (loadingGeolocation) {
      return <CircularLoader fontSize="20px" color="PRIMARY" />
    }
    if (position != null) {
      return (
        <StyledIcon
          onClick={handleClickGeolocation}
          data-test="geolocation-button"
          variant={variant}
          color="PRIMARY"
          fontSize="20px"
          name="location"
        />
      )
    }
    return null
  }

  const renderInput = (): ReactNode => (
    <StyledGridInput variant={variant} align="center">
      <StyledIcon variant={variant} color="PRIMARY" fontSize="20px" name="home" />
      <AddressAutocompleteComponent variant={variant} orderMethod={orderMethod} />
      {renderLocationIcon()}
    </StyledGridInput>
  )

  const renderButtons = (): ReactNode => {
    if (variant === SearchInputByAddressVariant.ADDRESS_HOME) {
      return (
        <StyledGridHome xs={2} gap="10px">
          <Button
            onClick={(): void => handleSearch(OrderMethod.delivery)}
            data-test="submit-delivery"
            fullWidth
            big
          >
            {t('tbdine.search_input.delivery')}
          </Button>
          <Button
            onClick={(): void => handleSearch(OrderMethod.pickup)}
            data-test="submit-pickup"
            fullWidth
            big
          >
            {t('tbdine.search_input.pickup')}
          </Button>
        </StyledGridHome>
      )
    }

    return (
      <StyledGridSearch>
        <Button
          onClick={(): void => {
            handleSearch(orderMethod)
          }}
          variant="OUTLINE"
          big
        >
          {t('tbdine.search_input.search')}
        </Button>
      </StyledGridSearch>
    )
  }

  return (
    <StyledWrapper data-test="search-address" variant={variant}>
      {renderDropDown()}
      {renderInput()}
      {renderButtons()}
    </StyledWrapper>
  )
}

export default SearchInputByAddress

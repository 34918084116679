import type { ParsedUrlQuery } from 'querystring'
import axios, { AxiosError } from 'axios'
import { ConsumerProfile } from '@/libs/helpers/apiClient'
import defaultLanguage from '@/libs/i18n/en-CA.json'
import { ErrorActionTypes } from '@/providers/error/reducer'

export enum SuccessStatusCodes {
  OK = 200,
  CREATED = 201,
  FOUND = 302,
}

export enum ClientErrorStatusCode {
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

export enum ServerErrorStatusCode {
  SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
}

type ErrorStatusCodes = typeof ClientErrorStatusCode & typeof ServerErrorStatusCode

// TODO > map all errors using i18n
export type ErrorHandlerParams = {
  errors: unknown[]
  params?: ParsedUrlQuery
  user?: ConsumerProfile
}

export interface ServerErrorResponse {
  error?: {
    message: string
    code: string
  }
}

export type ParsedError = {
  statusCode: ErrorStatusCodes[keyof ErrorStatusCodes]
  message: string
  code?: string
  type: ErrorActionTypes
}

function isResponseError(response: unknown): response is {
  code?: string
  message?: string
  error?: { code?: string; message?: string }
} {
  if (typeof response === 'object' && response) {
    return true
  }
  return false
}

export function isPageBlockingError(statusCode: ErrorStatusCodes[keyof ErrorStatusCodes]): boolean {
  const pageBlockingStatusCodes = [
    ClientErrorStatusCode.UNAUTHORIZED,
    ClientErrorStatusCode.FORBIDDEN,
    ServerErrorStatusCode.SERVER_ERROR,
    ServerErrorStatusCode.BAD_GATEWAY,
  ]

  return pageBlockingStatusCodes.some((error) => error === statusCode)
}

export function parseRequestError(err: unknown | AxiosError): ParsedError {
  // init an standard unknown error
  const standardError: ParsedError = {
    code: 'err_unknown_code',
    statusCode: ServerErrorStatusCode.SERVER_ERROR,
    message: defaultLanguage.error.message_default,
    type: ErrorActionTypes.FULL_PAGE,
  }

  if (typeof err === 'object' && err !== null) {
    // null is of type object, please do not remove the test above
    const errorObj = err as Record<string, unknown>

    if (axios.isAxiosError(err)) {
      // status
      if (typeof err.response?.status === 'number') {
        standardError.statusCode = err.response.status
      }

      if (isResponseError(err.response?.data)) {
        // code
        if (typeof err.response?.data.code === 'string') {
          standardError.code = err.response.data.code
        }

        if (typeof err.response?.data.error?.code === 'string') {
          standardError.code = err.response.data.error.code
        }

        // message
        if (typeof err.response?.data.error?.message === 'string') {
          standardError.message = err.response.data.error.message
        }

        if (typeof err.response?.data.message === 'string') {
          standardError.message = err.response.data.message
        }
      }

      if (
        errorObj.type === ErrorActionTypes.FULL_PAGE ||
        errorObj.type === ErrorActionTypes.MODAL_ERROR
      ) {
        standardError.type = errorObj.type
      }
    }

    // It's a standard error or an http-client error
    else {
      // status
      if (typeof errorObj.statusCode === 'number') {
        standardError.statusCode = errorObj.statusCode
      }

      if (typeof errorObj.status === 'number') {
        standardError.statusCode = errorObj.status
      }

      // code
      if (typeof errorObj.code === 'string') {
        standardError.code = errorObj.code
      }

      // message
      if (typeof errorObj.message === 'string') {
        standardError.message = errorObj.message
      }

      if (
        errorObj.type === ErrorActionTypes.FULL_PAGE ||
        errorObj.type === ErrorActionTypes.MODAL_ERROR
      ) {
        standardError.type = errorObj.type
      }
    }
  }

  return standardError
}

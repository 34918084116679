import {
  CSSProperties,
  ForwardedRef,
  FunctionComponent,
  HTMLAttributes,
  MutableRefObject,
} from 'react'
import { css } from '@emotion/css'
import { Theme } from '@emotion/react'

import styled from '@emotion/styled'

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  /** Defines a max-width value and centralizes the component */
  container?: boolean
  /**
   * Changes the color of the background to one of the palette colors
   * https://touchbistro.github.io/tbui/src-components-palette-index#semantic-colors
   */
  bg?: keyof Theme['palette']
  /**
   * Changes the "justify-content" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content
   */
  justify?: CSSProperties['justifyContent']
  /**
   * Changes the "align-content" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/align-items
   */
  align?: CSSProperties['alignItems']
  /**
   * Changes the "grid-area" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area
   */
  area?: CSSProperties['gridArea']
  /**
   * Changes the "gap" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/gap
   */
  gap?: CSSProperties['gap']
  /**
   * @deprecated Defines a custom "grid-template-columns" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  template?: CSSProperties['gridTemplateColumns']
  /**
   * Changes the number of columns or set a column template on eXtra-Small screens
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  xs?: number | CSSProperties['gridTemplateColumns']
  /**
   * Changes the number of columns or set a column template on SMall screens
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  sm?: number | CSSProperties['gridTemplateColumns']
  /**
   * Changes the number of columns or set a column template on MeDium screens
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  md?: number | CSSProperties['gridTemplateColumns']
  /**
   * Changes the number of columns or set a column template on LarGe screens
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  lg?: number | CSSProperties['gridTemplateColumns']
  /**
   * Changes the "padding" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  p?: CSSProperties['padding']
  /**
   * Changes the "margin" CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  m?: CSSProperties['margin']
  /**
   * Changes the "maxWidth" CSS property, include units ie: px, rem
   * https://developer.mozilla.org/en-US/docs/Web/CSS/max-width
   */
  mw?: CSSProperties['maxWidth']
  /**
   * Reference to an element or component.
   */
  ref?: MutableRefObject<HTMLDivElement> | ForwardedRef<HTMLDivElement>
}

const setResponsiveTemplate = (template?: string | number): string => {
  switch (typeof template) {
    case 'number':
      return `grid-template-columns: repeat(${template}, 1fr);`
    case 'string':
      return `grid-template-columns: ${template};`
    default:
      return ''
  }
}

const StyledGrid = styled.div<GridProps>`
  ${({ theme, container, m }) =>
    theme.mixin.dynamicProperty('margin', m, container ? '0 auto' : '0')};
  ${({ theme, justify }) => theme.mixin.dynamicProperty('justify-content', justify, '')};
  ${({ theme, align }) => theme.mixin.dynamicProperty('align-content', align, 'start')};
  ${({ theme, align }) => theme.mixin.dynamicProperty('align-items', align, 'start')};
  ${({ theme, area }) => theme.mixin.dynamicProperty('grid-area', area, '')};
  ${({ theme, bg }) => theme.mixin.dynamicColor(theme, 'background', bg, 'TRANSPARENT')};
  ${({ theme, gap }) => theme.mixin.dynamicProperty('gap', gap, '0')};
  ${({ theme, p }) => theme.mixin.dynamicProperty('padding', p, '0')};
  max-width: ${({ theme, container }) => (container ? theme.shape.CONTAINER : '100%')};
  // will overrule above max-width
  max-width: ${({ mw }) => mw};
  grid-template-rows: auto;
  position: relative;
  display: grid;
  width: 100%;
  ${({ xs, template }) => setResponsiveTemplate(xs || template || 'auto')}
  @media (min-width: ${(props) => props.theme.breakpoints.SM}) {
    ${({ sm }) => setResponsiveTemplate(sm)}
  }
  @media (min-width: ${(props) => props.theme.breakpoints.MD}) {
    ${({ md }) => setResponsiveTemplate(md)}
  }
  @media (min-width: ${(props) => props.theme.breakpoints.ELG}) {
    ${({ lg }) => setResponsiveTemplate(lg)}
  }
`
export const gridDefaults = css`
  max-width: 100%;
  grid-template-rows: auto;
  position: relative;
  display: grid;
  width: 100%;
`
// We need to export components that way so the the props documentation is generated automatically
const Grid: FunctionComponent<GridProps> = StyledGrid
export default Grid

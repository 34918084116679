/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { css, cx } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import { useSwipeable } from 'react-swipeable'
import Button from '../../Button'

const toggleButtonWrapperStyles = (theme: Theme): string => css`
  display: none;

  @media (max-width: ${theme.breakpoints.SM}) {
    display: block;
    z-index: 1;
  }
`

const stickyToggleButtonStyles = (theme: Theme): string => css`
  @media (max-width: ${theme.breakpoints.SM}) {
    background: ${theme.palette.WHITE};
    box-shadow: ${theme.shape.SHADOW};
    bottom: 0;
    transition: 0.25s ease;
    padding: 1rem;
    position: fixed;
    display: grid;
    right: 0;
    left: 0;
  }
`

const trailingIconWrapper = css`
  display: inline-flex;
  vertical-align: middle;
  margin-left: 5px;
`

const compactToggleButtonStyles = css`
  border-radius: 0;
`

interface ToggleButtonProps {
  /** State to represent show/display Drawer */
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  /** Determines toggle button placement  */
  isSticky?: boolean
  /** Renders button text information  */
  renderButtonText: JSX.Element | string
  /** Renders an icon after the button element  */
  renderTrailingIcon?: JSX.Element
  className?: string
  disabled?: boolean
}

const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
  open,
  setOpen,
  isSticky = false,
  renderButtonText,
  renderTrailingIcon,
  className,
  disabled,
}) => {
  const theme = useTheme()
  const handleSwipeActions = useSwipeable({
    onSwipedUp: () => setOpen(true),
    onSwipedDown: () => setOpen(false), // close drawer
  })

  const stickyButton = renderButtonText != null && (
    <aside
      className={cx(stickyToggleButtonStyles(theme), {
        'is-open': open,
      })}
    >
      <div {...handleSwipeActions}>
        <Button
          fullWidth
          className="sticky-toggle-button"
          data-test={className}
          type="button"
          onClick={() => setOpen(!open)}
          disabled={disabled}
        >
          {renderButtonText}
        </Button>
        {renderTrailingIcon != null ? (
          <span className={trailingIconWrapper}>{renderTrailingIcon}</span>
        ) : null}
      </div>
    </aside>
  )

  const compactButton = renderButtonText != null && (
    <>
      <Button
        className={compactToggleButtonStyles}
        data-test={className}
        type="button"
        variant="LINK"
        onClick={() => setOpen(!open)}
        disabled={disabled}
      >
        {renderButtonText}
      </Button>
      {renderTrailingIcon != null ? (
        <span className={trailingIconWrapper}>{renderTrailingIcon}</span>
      ) : null}
    </>
  )

  return (
    <div className={toggleButtonWrapperStyles(theme)}>
      {isSticky ? stickyButton : compactButton}
    </div>
  )
}

export { ToggleButton }

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FunctionComponent, ChangeEvent, useEffect, useState } from 'react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import SvgIcon, { SvgIconProps } from '../SvgIcon'

const StyledCheckbox = styled.input`
  display: none;
`

enum CheckboxVariants {
  CHECK = 'CHECK',
  ADD = 'ADD',
  SQUARE = 'SQUARE',
}

interface CheckBoxProps {
  /** Unique identifier of the element */
  id: string
  /** Callback function called on input change events */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /** Initial state to render checkbox in */
  isSelected?: boolean
  /** The size of the checked/unchecked icons */
  iconSize?: string
  /** If the checkbox is disabled, adapt the color/behavior */
  isDisabled?: boolean
  /** The color of the checked/unchecked icons */
  iconColor?: keyof Theme['palette']
  /** The input name ATTR, it affects how change events are triggered */
  name?: string
  /** Defines the appearance of the unchecked icon */
  variant?: keyof typeof CheckboxVariants
}

// TODO > update component to not use props with the same name as html attr
const CheckBox: FunctionComponent<CheckBoxProps> = ({
  name = 'input-checkbox',
  isSelected = false,
  isDisabled = false,
  iconColor = isDisabled ? 'GRAY_2' : 'PRIMARY',
  iconSize = '28px',
  onChange,
  variant,
  id,
  ...labelProps
}) => {
  const [checked, setChecked] = useState<boolean>(isSelected)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!isDisabled) {
      setChecked(e.target.checked)
      if (onChange) {
        onChange(e)
      }
    }
  }

  const getIconName = (): SvgIconProps['name'] => {
    if (checked) {
      return variant === 'SQUARE' ? 'check_square_filled' : 'check_filled'
    }
    switch (variant) {
      case CheckboxVariants.SQUARE:
        return 'check_square'
      case CheckboxVariants.CHECK:
        return 'check_circle'
      case CheckboxVariants.ADD:
        return 'add_circle'
      default:
        return 'circle'
    }
  }

  const getIconStroke = (): keyof Theme['palette'] | undefined => {
    if (checked) {
      switch (iconColor) {
        case 'PRIMARY':
          return 'TEXT_PRIMARY'
        case 'SECONDARY':
          return 'TEXT_SECONDARY'
        default:
          return undefined
      }
    }
    return undefined
  }

  useEffect(() => {
    setChecked(isSelected)
  }, [isSelected])

  return (
    <>
      <label {...labelProps} htmlFor={id}>
        <SvgIcon
          name={getIconName()}
          fontSize={iconSize}
          color={iconColor}
          stroke={getIconStroke()}
          disabled={isDisabled}
        />
      </label>
      <StyledCheckbox
        onChange={handleChange}
        checked={checked}
        type="checkbox"
        name={name}
        id={id}
        disabled={isDisabled}
      />
    </>
  )
}

export default CheckBox

/* eslint-disable no-restricted-properties */
import { RefObject, useEffect } from 'react'

export const useClickOutsideEvent = (ref: RefObject<HTMLElement>, callback?: () => void): void => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node) && callback) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}

/* eslint-disable react/jsx-props-no-spreading */
import { CSSProperties, FunctionComponent, HTMLAttributes } from 'react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

import iconList from './icons'

export interface SvgIconProps {
  /** Sets an icon from the icon lib, replaces children */
  name?: keyof typeof iconList
  /** Replaces the icon color by a color from Theme */
  color?: keyof Theme['palette']
  /** Replaces the icon stroke by a color from Theme */
  stroke?: keyof Theme['palette']
  /** Defines the size of the icon */
  fontSize?: string
  /** Passes a method to be called on click events */
  onClick?: (...params: unknown[]) => void
  /** Disables click events and changes color to match the state */
  disabled?: boolean
  /** Changes the "margin" CSS property */
  m?: CSSProperties['margin']
}

const StyledSvgIcon = styled.i<SvgIconProps>`
  ${({ theme, color }) => theme.mixin.dynamicColor(theme, 'color', color, 'TEXT')};
  ${({ theme, stroke }) =>
    theme.mixin.dynamicProperty('stroke', stroke ? theme.palette[stroke] : undefined, 'unset')};
  ${({ m }) => m && `margin: ${m}`};
  font-size: ${(props) => props.fontSize || '1em'};
  height: ${(props) => props.fontSize || '1em'};
  width: ${(props) => props.fontSize || '1em'};
  box-sizing: content-box;
  transition: 0.2s ease;
  display: inline-flex;
  > svg {
    fill: currentColor;
    height: 1em;
    width: 1em;
  }
  &[disabled] {
    color: ${(props) => props.theme.palette.GRAY_2};
    pointer-events: none;
  }
`

const SvgIcon: FunctionComponent<SvgIconProps & HTMLAttributes<HTMLDivElement>> = ({
  name = 'documentation_example',
  children,
  onClick,
  ...iconProps
}) => {
  return (
    <StyledSvgIcon
      {...iconProps}
      role={onClick ? 'button' : undefined}
      onClick={onClick}
      data-test={name}
    >
      {children || iconList[name]}
    </StyledSvgIcon>
  )
}

export default SvgIcon

import { FunctionComponent, ReactNode } from 'react'
import { ThemeProvider } from '@emotion/react'
import ValidateOrderMethod from '@/components/ValidateOrderMethod'
import { contrastingTextColor } from '@/libs/helpers/utils'
import type { ServerSideProps } from '@/libs/helpers/withServerSidePropsDecorator'
import DefaultThemeProvider from '@/tbui/DefaultThemeProvider'
import { ConfigurationProvider } from './configuration/ConfigurationContext'
import Initializer from './configuration/Initializer'
import ErrorProvider from './error'
import GoogleMapsProvider from './googleMaps'
import ModalProvider from './modal'
import StateProvider from './state'
import UserLanguageProvider from './userLanguage'

const RootProvider: FunctionComponent<{
  pageProps?: ServerSideProps
  children?: ReactNode
}> = ({ pageProps, children }) => {
  const primaryColor = pageProps?.globals?.venue?.branding.primaryColor
  const secondaryColor = pageProps?.globals?.venue?.branding.secondaryColor
  return (
    <DefaultThemeProvider isGlobal>
      <ThemeProvider
        theme={(defaultTheme) => ({
          ...defaultTheme,
          palette: {
            ...defaultTheme.palette,
            ...(primaryColor && { PRIMARY: primaryColor }),
            ...(secondaryColor && { SECONDARY: secondaryColor }),
            ...(primaryColor && { TEXT_PRIMARY: contrastingTextColor(defaultTheme, primaryColor) }),
            ...(secondaryColor && {
              TEXT_SECONDARY: contrastingTextColor(defaultTheme, secondaryColor),
            }),
          },
        })}
      >
        <ConfigurationProvider>
          <Initializer>
            <ValidateOrderMethod
              orderMethod={pageProps?.globals?.initialCart.orderMethod}
              venueXRefID={pageProps?.globals?.venue?.venueXRefID}
            >
              <UserLanguageProvider>
                <ModalProvider>
                  <ErrorProvider>
                    <GoogleMapsProvider>
                      <StateProvider error={pageProps?.error ?? null}>{children}</StateProvider>
                    </GoogleMapsProvider>
                  </ErrorProvider>
                </ModalProvider>
              </UserLanguageProvider>
            </ValidateOrderMethod>
          </Initializer>
        </ConfigurationProvider>
      </ThemeProvider>
    </DefaultThemeProvider>
  )
}

export default RootProvider

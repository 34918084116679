import { useState, useEffect } from 'react'

// hook to get a user's geolocation permissions
export const useGeolocation = (): {
  position?: GeolocationPosition
  permission?: PermissionState
  isLoading: boolean
} => {
  // HOOKS/STATES
  const [permission, setPermission] = useState<PermissionState>('prompt')
  const [position, setPosition] = useState<GeolocationPosition>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const supportsGeolocation = navigator?.geolocation != null
  const supportsPermission = navigator?.permissions != null

  // HANDLERS
  const getGeolocation = (): void => {
    setIsLoading(true)
    navigator.geolocation.getCurrentPosition(
      // on success
      (geoPos: GeolocationPosition) => {
        setPosition(geoPos)
        setIsLoading(false)
      },
      // on error
      () => {
        setPermission('denied')
      }
    )
    setIsLoading(false)
  }

  const getPermission = async (): Promise<void> => {
    setIsLoading(true)
    // get permission
    const result = await navigator.permissions.query({
      name: 'geolocation',
    })

    // navigator is not available on server side
    if (result != null) {
      setPermission(result.state)
      // and listen for changes
      result.onchange = (): void => {
        setPermission(result.state)
      }
    }

    setIsLoading(false)
  }

  // EFFECTS
  useEffect(() => {
    // tries to get permission on init
    if (supportsPermission) {
      // Chrome,Firefox,Opera...
      getPermission()
    } else if (supportsGeolocation) {
      // IE,Safari,old browsers...
      getGeolocation()
    }
  }, [supportsGeolocation, supportsPermission])

  useEffect(() => {
    if (supportsGeolocation) {
      // Get position when permission is granted/prompt
      if (permission === 'granted' || permission === 'prompt') {
        getGeolocation()
      }
    }
  }, [permission, supportsGeolocation])

  // RETURN
  return {
    permission,
    isLoading,
    position,
  }
}

export default useGeolocation

import {
  PlaceAutocompleteType,
  PlaceAutocompleteRequest,
  ReverseGeocodeRequest,
  ReverseGeocodeResponse,
  PlaceAutocompleteResponse,
} from '@googlemaps/google-maps-services-js'
import axios from 'axios'

import { orderingAddressAdapter } from '@/libs/helpers/adapters'
import { ModalPageErrorCode } from '@/libs/helpers/errorMapper'
import providerFactory, { Reducer, ActionsCreator } from '@/libs/helpers/hooks/providerFactory'

export interface OrderingAddress {
  formattedAddress: string
  address1: string
  address2?: string
  city: string
  country: string
  postalCode: string
  province: string
  lat: number
  lng: number
  googlePlaceID: string
  addressXRefID?: string
  saveDeliveryAddress?: boolean
}

export interface AutocompleteOption {
  primaryText: string
  secondaryText?: string
  label: string
  id: string
}

interface InitialState {
  placesAutocompleteOptions?: AutocompleteOption[]
  orderingAddress?: OrderingAddress | null
}

const initialState: InitialState = {
  placesAutocompleteOptions: [],
  orderingAddress: null,
}

enum ActionTypes {
  GET_AUTOCOMPLETE_OPTIONS = 'GET_AUTOCOMPLETE_OPTIONS',
  CLEAR_ORDERING_ADDRESS = 'CLEAR_ORDERING_ADDRESS',
  SET_ORDERING_ADDRESS = 'SET_ORDERING_ADDRESS',
}

const reducer: Reducer<InitialState, ActionTypes> =
  () =>
  (state, action): InitialState => {
    /* istanbul ignore next */
    const { type, payload } = action
    switch (type) {
      case ActionTypes.SET_ORDERING_ADDRESS:
        return {
          ...state,
          orderingAddress: payload?.orderingAddress,
        }
      case ActionTypes.GET_AUTOCOMPLETE_OPTIONS:
        return {
          ...state,
          placesAutocompleteOptions: payload?.placesAutocompleteOptions,
        }
      case ActionTypes.CLEAR_ORDERING_ADDRESS:
        return { ...state, orderingAddress: null }
      /* istanbul ignore next */
      default:
        return state
    }
  }

const useActionsCreator: ActionsCreator<InitialState, ActionTypes> = ({
  setModalError,
  prevState,
  dispatch,
}) => {
  const clearUserLocation = (): void => {
    dispatch({ type: ActionTypes.CLEAR_ORDERING_ADDRESS, payload: null })
  }

  // finds a google address using lat/lng or placeID and dispatches as a context value
  const selectOrderingAddress = async ({
    // placeID is google's main identifier for an address
    placeID,
    lat,
    lng,
  }: {
    placeID?: string
    lat?: number
    lng?: number
  }): Promise<void> => {
    try {
      if (placeID || (lat && lng)) {
        const createOptions = (): {
          params: Omit<ReverseGeocodeRequest['params'], 'key'>
        } => {
          if (placeID) {
            return {
              params: {
                place_id: placeID,
              },
            }
          }

          return {
            params: {
              latlng: `${lat},${lng}`,
            },
          }
        }

        const response = await axios.get<
          {
            params: Omit<ReverseGeocodeRequest['params'], 'key'>
          },
          ReverseGeocodeResponse
        >('/api/google/maps/api/geocode/json', createOptions())

        const orderingAddress: OrderingAddress | null = orderingAddressAdapter(response.data)

        dispatch({
          type: ActionTypes.SET_ORDERING_ADDRESS,
          payload: { orderingAddress },
        })
      }
    } catch (error) {
      setModalError(ModalPageErrorCode.InvalidAddress)
    }
  }

  // searches an address user input against google places API
  const getPlacesAutocompleteOptions = async (inputValue: string): Promise<void> => {
    const { orderingAddress } = prevState

    if (inputValue != null && inputValue.length > 2) {
      // allow for bias results in Google Places API,
      // if user location is not set, use ordering address coordinates
      // if nothing is set, will bias to US & Canada

      const getLocation = (): string => {
        let result = ''
        if (orderingAddress != null) {
          result = `${orderingAddress.lat},${orderingAddress.lng}`
        }
        return result
      }

      const options: {
        params: Omit<PlaceAutocompleteRequest['params'], 'key'>
      } = {
        params: {
          radius: getLocation() !== '' ? 20 : 0,
          location: getLocation(),
          input: inputValue,
          types: PlaceAutocompleteType.geocode,
          // if user has not allowed us to recover its location,
          // bias to places in Canada or US and unincorporated organized territories
          // when scaling, we can ultimately do a per-domain name restriction (e.g. .ca bias for canada)
          components: getLocation()
            ? []
            : ['country:ca', 'country:us', 'country:pr', 'country:vi', 'country:gu'],
        },
      }

      try {
        const response = await axios.get<
          {
            params: Omit<PlaceAutocompleteRequest['params'], 'key'>
          },
          PlaceAutocompleteResponse
        >('/api/google/maps/api/place/autocomplete/json', options)

        const placesAutocompleteOptions = response.data.predictions.map((address) => ({
          primaryText: address.structured_formatting.main_text,
          secondaryText: address.structured_formatting.secondary_text,
          label: address.description,
          id: address.place_id,
        }))

        dispatch({
          type: ActionTypes.GET_AUTOCOMPLETE_OPTIONS,
          payload: { placesAutocompleteOptions },
        })
      } catch (error) {
        setModalError(error)
      }
    }
  }

  return {
    getPlacesAutocompleteOptions,
    selectOrderingAddress,
    clearUserLocation,
  }
}

// Create
// Always let TS infer this function type
type GeneratedActions = ReturnType<typeof useActionsCreator>
const { Provider, useProvider } = providerFactory<InitialState, GeneratedActions, ActionTypes>({
  useActionsCreator,
  initialState,
  reducer,
})

// Export
export const useGoogleMaps = useProvider
export default Provider

const icons = {
  documentation_example: (
    <svg viewBox="0 0 581.176 581.176">
      <g>
        <path
          d="M581.176,290.588C581.176,130.087,451.09,0,290.588,0S0,130.087,0,290.588s130.087,290.588,290.588,290.588
          c67.901,0,130.208-23.465,179.68-62.476L254.265,302.696h326.306C580.716,298.652,581.176,294.681,581.176,290.588z
          M447.99,217.941c-26.758,0-48.431-21.697-48.431-48.431s21.673-48.431,48.431-48.431c26.758,0,48.431,21.697,48.431,48.431
          S474.749,217.941,447.99,217.941z"
        />
      </g>
    </svg>
  ),
  close: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12.70703,12l8.64648-8.64648c0.19531-0.19531,0.19531-0.51172,0-0.70703s-0.51172-0.19531-0.70703,0L12,11.29297
          L3.35352,2.64648c-0.19531-0.19531-0.51172-0.19531-0.70703,0s-0.19531,0.51172,0,0.70703L11.29297,12l-8.64648,8.64648
          c-0.19531,0.19531-0.19531,0.51172,0,0.70703C2.74414,21.45117,2.87207,21.5,3,21.5s0.25586-0.04883,0.35352-0.14648L12,12.70703
          l8.64648,8.64648C20.74414,21.45117,20.87207,21.5,21,21.5s0.25586-0.04883,0.35352-0.14648
          c0.19531-0.19531,0.19531-0.51172,0-0.70703L12.70703,12z"
        />
      </g>
    </svg>
  ),
  circle: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,1C5.9248,1,1,5.9248,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
        C23,5.9248,18.07617,1,12,1z M12,22C6.48633,22,2,17.51367,2,11.99951C2,6.48584,6.48633,2,12,2s10,4.48584,10,9.99951
        C22,17.51367,17.51367,22,12,22z"
        />
      </g>
    </svg>
  ),
  filled_circle: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,1C5.9248,1,1,5.9248,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
        C23,5.9248,18.07617,1,12,1z M12,22C6.48633,22,2,17.51367,2,11.99951C2,6.48584,6.48633,2,12,2s10,4.48584,10,9.99951
        C22,17.51367,17.51367,22,12,22z"
        />
        <circle cx="12" cy="12" r="6" fill="currentColor" />
      </g>
    </svg>
  ),
  add: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          transform="scale(1.5,1.5),translate(-4,-4)"
          d="M15.875,11.5h-3.3584V8.14209c0-0.27637-0.22363-0.5-0.5-0.5s-0.5,0.22363-0.5,0.5V11.5H8.1582
          c-0.27637,0-0.5,0.22363-0.5,0.5s0.22363,0.5,0.5,0.5h3.3584v3.35791c0,0.27637,0.22363,0.5,0.5,0.5s0.5-0.22363,0.5-0.5V12.5
          h3.3584c0.27637,0,0.5-0.22363,0.5-0.5S16.15137,11.5,15.875,11.5z"
        />
      </g>
    </svg>
  ),
  add_circle: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,1C5.9248,1,1,5.9248,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
          C23,5.9248,18.07617,1,12,1z M12,22C6.48633,22,2,17.51367,2,11.99951C2,6.48584,6.48633,2,12,2s10,4.48584,10,9.99951
          C22,17.51367,17.51367,22,12,22z"
        />
        <path
          d="M15.875,11.5h-3.3584V8.14209c0-0.27637-0.22363-0.5-0.5-0.5s-0.5,0.22363-0.5,0.5V11.5H8.1582
          c-0.27637,0-0.5,0.22363-0.5,0.5s0.22363,0.5,0.5,0.5h3.3584v3.35791c0,0.27637,0.22363,0.5,0.5,0.5s0.5-0.22363,0.5-0.5V12.5
          h3.3584c0.27637,0,0.5-0.22363,0.5-0.5S16.15137,11.5,15.875,11.5z"
        />
      </g>
    </svg>
  ),
  remove: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          transform="scale(0.5,1.25),translate(12,-2)"
          d="M22,12.49H2a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H22a.5.5,0,0,1,.5.5A.5.5,0,0,1,22,12.49Z"
        />
      </g>
    </svg>
  ),
  remove_circle: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,1C5.9248,1,1,5.9248,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
          C23,5.9248,18.07617,1,12,1z M12,22C6.48633,22,2,17.51367,2,11.99951C2,6.48584,6.48633,2,12,2s10,4.48584,10,9.99951
          C22,17.51367,17.51367,22,12,22z"
        />
        <path
          transform="scale(0.5,1),translate(12)"
          d="M22,12.49H2a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H22a.5.5,0,0,1,.5.5A.5.5,0,0,1,22,12.49Z"
        />
      </g>
    </svg>
  ),
  cross_circle: (
    <svg viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5C4.9248 0.5 0 5.4248 0 11.4995C0 17.5757 4.9248 22.5 11 22.5C17.0762 22.5 22 17.5757 22 11.4995C22 5.4248 17.0762 0.5 11 0.5ZM11 21.5C5.48633 21.5 1 17.0137 1 11.4995C1 5.98584 5.48633 1.5 11 1.5C16.5137 1.5 21 5.98584 21 11.4995C21 17.0137 16.5137 21.5 11 21.5ZM13.7607 8.16602C13.956 7.97071 14.2725 7.97071 14.4678 8.16602C14.6631 8.36133 14.6631 8.67773 14.4678 8.87305L11.8614 11.48L14.4678 14.0869C14.6631 14.2822 14.6631 14.5986 14.4678 14.7939C14.3701 14.8916 14.2422 14.9404 14.1143 14.9404C13.9864 14.9404 13.8584 14.8916 13.7608 14.7939L11.1543 12.187L8.54689 14.7939C8.44923 14.8916 8.3213 14.9404 8.19337 14.9404C8.06544 14.9404 7.93751 14.8916 7.83985 14.7939C7.64454 14.5986 7.64454 14.2822 7.83985 14.0869L10.4473 11.48L7.83985 8.87305C7.64454 8.67774 7.64454 8.36133 7.83985 8.16602C8.03516 7.97071 8.35157 7.97071 8.54688 8.16602L11.1543 10.773L13.7607 8.16602Z"
        fill="currentColor"
      />
    </svg>
  ),
  check_circle: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,2c5.51367,0,10,4.48584,10,9.99951C22,17.51367,17.51367,22,12,22C6.48535,22,2,17.51367,2,11.99951
          C2,6.48584,6.48535,2,12,2 M12,1C5.9248,1,1,5.92529,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
          C23,5.92529,18.07617,1,12,1z"
        />
        <path
          d="M10.54883,15.13965c-0.12793,0-0.25586-0.04883-0.35352-0.14648L7.77734,12.5752
          c-0.19531-0.19531-0.19531-0.51172,0-0.70703s0.51172-0.19531,0.70703,0l2.06445,2.06445l4.9668-4.96631
          c0.19531-0.19531,0.51172-0.19531,0.70703,0s0.19531,0.51172,0,0.70703l-5.32031,5.31982
          C10.80469,15.09082,10.67676,15.13965,10.54883,15.13965z"
        />
      </g>
    </svg>
  ),
  check_square: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99753 1H19.002C21.2099 1 22.9998 2.78986 22.9998 4.99777V19.0022C22.9998 21.2101 21.2099 23 19.002 23H4.99753C2.78962 23 0.999756 21.2101 0.999756 19.0022V4.99777C0.999756 2.78986 2.78962 1 4.99753 1ZM21.9998 4.99777C21.9998 3.34479 20.655 2 19.002 2H4.99753C3.34455 2 1.99976 3.34479 1.99976 4.99777V19.0022C1.99976 20.6552 3.34455 22 4.99753 22H19.002C20.655 22 21.9998 20.6552 21.9998 19.0022V4.99777Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  check_filled: (
    <svg viewBox="0 0 24 24">
      <g>
        <circle cx="12" cy="12" r="12" />
        <path
          d="M10.54883,15.13965c-0.12793,0-0.25586-0.04883-0.35352-0.14648L7.77734,12.5752
          c-0.19531-0.19531-0.19531-0.51172,0-0.70703s0.51172-0.19531,0.70703,0l2.06445,2.06445l4.9668-4.96631
          c0.19531-0.19531,0.51172-0.19531,0.70703,0s0.19531,0.51172,0,0.70703l-5.32031,5.31982
          C10.80469,15.09082,10.67676,15.13965,10.54883,15.13965z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  check_square_filled: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.002 1C19.278 1 19.5474 1.02796 19.8077 1.08124C21.6294 1.45404 22.9997 3.06586 22.9998 4.9978V19.0023C22.9998 20.9341 21.6294 22.546 19.8077 22.9188C19.5474 22.972 19.278 23 19.002 23H19.002H4.9975C2.78962 23 0.999756 21.2101 0.999756 19.0023V4.9978C0.999756 2.78986 2.78961 1 4.9975 1H19.002H19.002ZM10.2057 16.5505L18.3307 8.27783C18.5594 8.04498 18.556 7.67078 18.3232 7.44226C18.0897 7.21314 17.7164 7.21662 17.4876 7.44977L9.78418 15.2931L6.51251 11.962C6.28345 11.7289 5.90979 11.7255 5.67694 11.9545C5.44409 12.183 5.44061 12.5573 5.66943 12.7901L9.36261 16.5505C9.47369 16.6636 9.62549 16.7274 9.78418 16.7274C9.94287 16.7274 10.0946 16.6636 10.2057 16.5505Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  globe: (
    <svg viewBox="0 0 24 24">
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
    </svg>
  ),
  location: (
    <svg viewBox="0 0 24 24">
      <path d="M12,23.5a.57.57,0,0,1-.28-.08C11.35,23.16,2.5,17.18,2.5,10a9.5,9.5,0,0,1,19,0c0,7.18-8.85,13.16-9.22,13.42A.57.57,0,0,1,12,23.5Zm0-22A8.51,8.51,0,0,0,3.5,10c0,6,7,11.32,8.5,12.39C13.49,21.32,20.5,16,20.5,10A8.51,8.51,0,0,0,12,1.5Z" />
      <path d="M12,13.5A3.5,3.5,0,1,1,15.5,10,3.5,3.5,0,0,1,12,13.5Zm0-6A2.5,2.5,0,1,0,14.5,10,2.5,2.5,0,0,0,12,7.5Z" />
    </svg>
  ),
  home: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M22.33398,10.55273l-9.93164-8.9248c-0.19043-0.1709-0.48047-0.16992-0.66895,0.00098L1.66504,10.71191
          c-0.20508,0.18457-0.22168,0.50098-0.03613,0.70605C1.72754,11.52734,1.86328,11.58301,2,11.58301
          c0.11914,0,0.23926-0.04297,0.33496-0.12891l1.18555-1.06934V22c0,0.27637,0.22363,0.5,0.5,0.5h5.11426
          c0.27637,0,0.5-0.22363,0.5-0.5v-6.5625H14.5V22c0,0.27637,0.22363,0.5,0.5,0.5l0.0625-0.0127L15.12598,22.5h5.0127
          c0.27637,0,0.5-0.22363,0.5-0.5V10.37402l1.02734,0.92285c0.20508,0.18555,0.52148,0.16895,0.70605-0.03809
          C22.55664,11.05371,22.54004,10.7373,22.33398,10.55273z M19.63867,9.57324V21.5H15.5v-6.5625c0-0.27637-0.22363-0.5-0.5-0.5
          H9.13477c-0.27637,0-0.5,0.22363-0.5,0.5V21.5H4.52051V9.48242l7.54883-6.80957l7.58594,6.81738L19.63867,9.57324z"
        />
      </g>
    </svg>
  ),
  time: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M11.99951,2C17.51367,2,22,6.48584,22,11.99951C22,17.51367,17.51367,22,11.99951,22C6.48584,22,2,17.51367,2,11.99951
          C2,6.48584,6.48584,2,11.99951,2 M11.99951,1C5.9248,1,1,5.92529,1,11.99951C1,18.07568,5.9248,23,11.99951,23
          C18.07617,23,23,18.07568,23,11.99951C23,5.92529,18.07617,1,11.99951,1z"
        />
        <path
          d="M14.93555,15.71875c-0.12012,0-0.23975-0.04297-0.33594-0.12939l-2.93555-2.65967
          C11.55957,12.83496,11.5,12.7002,11.5,12.55908V6.85254c0-0.27637,0.22363-0.5,0.5-0.5s0.5,0.22363,0.5,0.5v5.48486
          l2.77148,2.51074c0.20459,0.18555,0.22021,0.50146,0.03467,0.70654C15.20752,15.66357,15.07178,15.71875,14.93555,15.71875z"
        />
      </g>
    </svg>
  ),
  facebook: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,2c5.51367,0,10,4.48584,10,9.99951C22,17.51367,17.51367,22,12,22C6.48535,22,2,17.51367,2,11.99951
          C2,6.48584,6.48535,2,12,2 M12,1C5.9248,1,1,5.92529,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
          C23,5.92529,18.07617,1,12,1z"
        />
        <g transform="translate(1.000000, 1.000000)">
          <path
            d="M11.7647059,8.6199095 L11.7647059,7.54242081 C11.7647059,7.05599548 11.8764706,6.80995475 12.6617647,6.80995475
            L13.6470588,6.80995475 L13.6470588,5 L12.0029412,5 C9.98823529,5 9.32352941,5.88800905 9.32352941,7.41233032
            L9.32352941,8.6199095 L8,8.6199095 L8,10.4298643 L9.32352941,10.4298643 L9.32352941,15.8597285 L11.7647059,15.8597285
            L11.7647059,10.4298643 L13.4235294,10.4298643 L13.6470588,8.6199095 L11.7647059,8.6199095 Z"
          />
        </g>
      </g>
    </svg>
  ),
  instagram: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,2c5.51367,0,10,4.48584,10,9.99951C22,17.51367,17.51367,22,12,22C6.48535,22,2,17.51367,2,11.99951
      C2,6.48584,6.48535,2,12,2 M12,1C5.9248,1,1,5.92529,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
      C23,5.92529,18.07617,1,12,1z"
        />
        <g transform="translate(5.000000, 5.000000),scale(1.15, 1.15)">
          <path
            d="M5.99811905,0.00378571429 C4.36911905,0.00378571429 4.16485714,0.0106904762 3.52509524,0.0398809524
            C2.88666667,0.069 2.45064286,0.170404762 2.06911905,0.318690476 C1.67469048,0.471952381 1.34019048,0.677047619
            1.00671429,1.0105 C0.673261905,1.34397619 0.468166667,1.67847619 0.314904762,2.07290476 C0.166619048,2.45442857
            0.0652142857,2.89045238 0.0360952381,3.52888095 C0.0069047619,4.16864286 4.4408921e-14,4.37290476
            4.4408921e-14,6.00190476 C4.4408921e-14,7.63088095 0.0069047619,7.83514286 0.0360952381,8.47490476
            C0.0652142857,9.11333333 0.166619048,9.54935714 0.314904762,9.93088095 C0.468166667,10.3253095
            0.673261905,10.6598095 1.00671429,10.9932857 C1.34019048,11.3267381 1.67469048,11.5318333 2.06911905,11.685119
            C2.45064286,11.833381 2.88666667,11.9347857 3.52509524,11.9639048 C4.16485714,11.9930952 4.36911905,12 5.99811905,12
            C7.62709524,12 7.83135714,11.9930952 8.47111905,11.9639048 C9.10954762,11.9347857 9.54557143,11.833381
            9.92709524,11.685119 C10.3215238,11.5318333 10.6560238,11.3267381 10.9895,10.9932857 C11.3229524,10.6598095
            11.5280476,10.3253095 11.6813333,9.93088095 C11.8295952,9.54935714 11.931,9.11333333 11.960119,8.47490476
            C11.9893095,7.83514286 11.9962143,7.63088095 11.9962143,6.00190476 C11.9962143,4.37290476 11.9893095,4.16864286
            11.960119,3.52888095 C11.931,2.89045238 11.8295952,2.45442857 11.6813333,2.07290476 C11.5280476,1.67847619
            11.3229524,1.34397619 10.9895,1.0105 C10.6560238,0.677047619 10.3215238,0.471952381 9.92709524,0.318690476
            C9.54557143,0.170404762 9.10954762,0.069 8.47111905,0.0398809524 C7.83135714,0.0106904762 7.62709524,0.00378571429
            5.99811905,0.00378571429 Z M5.99811905,1.08452381 C7.59966667,1.08452381 7.78938095,1.09064286 8.42185714,1.1195
            C9.00666667,1.14616667 9.3242619,1.24388095 9.53561905,1.32602381 C9.81559524,1.43483333 10.0154048,1.56480952
            10.2252857,1.77471429 C10.4351905,1.98459524 10.5651667,2.18440476 10.6739762,2.46438095 C10.756119,2.6757381
            10.8538333,2.99333333 10.8805,3.57814286 C10.9093571,4.21061905 10.9154762,4.40033333 10.9154762,6.00190476
            C10.9154762,7.60345238 10.9093571,7.79316667 10.8805,8.42564286 C10.8538333,9.01045238 10.756119,9.32804762
            10.6739762,9.53940476 C10.5651667,9.81938095 10.4351905,10.0191905 10.2252857,10.2290714 C10.0154048,10.4389762
            9.81559524,10.5689524 9.53561905,10.6777619 C9.3242619,10.7599048 9.00666667,10.857619 8.42185714,10.8842857
            C7.78947619,10.9131429 7.59978571,10.9192619 5.99811905,10.9192619 C4.39642857,10.9192619 4.2067619,10.9131429
            3.57435714,10.8842857 C2.98954762,10.857619 2.67195238,10.7599048 2.46059524,10.6777619 C2.18061905,10.5689524
            1.98080952,10.4389762 1.77092857,10.2290714 C1.56104762,10.0191905 1.43104762,9.81938095 1.3222381,9.53940476
            C1.24009524,9.32804762 1.14238095,9.01045238 1.11571429,8.42564286 C1.08685714,7.79316667 1.0807381,7.60345238
            1.0807381,6.00190476 C1.0807381,4.40033333 1.08685714,4.21061905 1.11571429,3.57814286 C1.14238095,2.99333333
            1.24009524,2.6757381 1.3222381,2.46438095 C1.43104762,2.18440476 1.56102381,1.98459524 1.77092857,1.77471429
            C1.98080952,1.56480952 2.18061905,1.43483333 2.46059524,1.32602381 C2.67195238,1.24388095 2.98954762,1.14616667
            3.57435714,1.1195 C4.20683333,1.09064286 4.39654762,1.08452381 5.99811905,1.08452381 L5.99811905,1.08452381 Z"
          />
          <path
            d="M5.7777898,8.24171667 C4.66243659,8.24171667 3.75828333,7.33756341 3.75828333,6.22223425 C3.75828333,5.10688103
            4.66243659,4.20272778 5.7777898,4.20272778 C6.89311897,4.20272778 7.79727222,5.10688103 7.79727222,6.22223425
            C7.79727222,7.33756341 6.89311897,8.24171667 5.7777898,8.24171667 Z M5.7777898,3.11111111 C4.05954751,3.11111111
            2.66666667,4.50399195 2.66666667,6.22223425 C2.66666667,7.94045249 4.05954751,9.33333333 5.7777898,9.33333333
            C7.49600805,9.33333333 8.88888889,7.94045249 8.88888889,6.22223425 C8.88888889,4.50399195 7.49600805,3.11111111
            5.7777898,3.11111111 L5.7777898,3.11111111 Z"
          />
          <path
            d="M9.77777778,2.88889992 C9.77777778,3.25709319 9.47929336,3.55555556 9.11110008,3.55555556 C8.74292887,3.55555556
            8.44444444,3.25709319 8.44444444,2.88889992 C8.44444444,2.52070664 8.74292887,2.22222222 9.11110008,2.22222222
            C9.47929336,2.22222222 9.77777778,2.52070664 9.77777778,2.88889992"
          />
        </g>
      </g>
    </svg>
  ),
  phone: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M5.70312,22.50293c-0.89453,0-1.60449-0.22949-2.06152-0.68555c-0.12012-0.12012-0.24902-0.24219-0.38184-0.36914
          c-0.80566-0.7666-1.80859-1.7207-1.75781-2.9668c0.03516-0.85547,0.55176-1.67383,1.5791-2.50195
          c2.82031-2.27246,3.96484-1.49414,5.38867-0.04785l0.23145,0.2334c0.07617,0.07617,0.85547,0.63672,4.49512-2.99707
          c2.74023-2.73438,3.03613-3.84668,3.03613-4.19727c0-0.13965-0.04199-0.2002-0.08301-0.24121l-0.20312-0.20117
          c-1.44727-1.42773-2.22461-2.57617,0.06641-5.41309c0.83203-1.04785,1.65332-1.57617,2.50684-1.61328
          c1.21191-0.06543,2.15137,0.93066,2.90137,1.72168c0.13184,0.13965,0.26074,0.27539,0.3877,0.40234
          c0.4541,0.45215,0.69336,1.17773,0.69141,2.0957c-0.00391,2.27344-1.56348,6.4668-5.94629,10.80273
          C12.5498,20.52246,8.33301,22.50195,5.70312,22.50293z M6.04395,15.5498c-0.55664,0-1.25879,0.34082-2.33594,1.20898
          c-0.77832,0.62695-1.18457,1.2207-1.20703,1.76367c-0.03223,0.79297,0.75391,1.54102,1.44824,2.20215
          c0.13867,0.13184,0.27344,0.25977,0.39941,0.38574c1.05078,1.0459,6.0625,0.13281,11.5-5.29492
          C19.74805,11.95801,21.49609,7.96582,21.5,5.7207c0.00098-0.6377-0.13965-1.12988-0.39746-1.38672
          c-0.13379-0.13281-0.26855-0.27637-0.40723-0.42285C20.06738,3.24902,19.35645,2.5,18.61035,2.5L18.5625,2.50098
          c-0.53906,0.02344-1.13477,0.44043-1.76953,1.24023c-1.89551,2.34668-1.31055,2.9248-0.14355,4.0752l0.20703,0.20605
          c0.24609,0.24609,0.37598,0.57324,0.37598,0.94824c0,1.06445-1.08887,2.66895-3.3291,4.9043
          c-3.08105,3.07715-4.90234,4.00195-5.90918,2.99707l-0.23633-0.23828C7.1416,16.00781,6.68945,15.5498,6.04395,15.5498z"
        />
      </g>
    </svg>
  ),
  twitter: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,2c5.51367,0,10,4.48584,10,9.99951C22,17.51367,17.51367,22,12,22C6.48535,22,2,17.51367,2,11.99951
        C2,6.48584,6.48535,2,12,2 M12,1C5.9248,1,1,5.92529,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
        C23,5.92529,18.07617,1,12,1z"
        />
        <g transform="translate(1.000000, 1.000000)">
          <path d="M16,7.96418733 C15.6312778,8.12735749 15.2371265,8.23755033 14.8217843,8.28840856 C15.2456029,8.0341174 15.5719432,7.63148972 15.7245179,7.1525747 C15.3282475,7.38779402 14.8895953,7.55944056 14.4212757,7.65056156 C14.0461962,7.25005298 13.5121848,7 12.9230769,7 C11.7893622,7 10.8717949,7.91968637 10.8717949,9.05340114 C10.8717949,9.21445221 10.8887476,9.3712651 10.9247722,9.5217207 C9.21890231,9.43695698 7.70586989,8.61898707 6.69506251,7.37507947 C6.51917779,7.67810977 6.41746133,8.0319983 6.41746133,8.40707777 C6.41746133,9.11909303 6.78194533,9.74846366 7.33290951,10.1171858 C6.99385463,10.1087095 6.67599068,10.0154694 6.40050858,9.86077559 C6.40050858,9.86925196 6.40050858,9.87772833 6.40050858,9.8862047 C6.40050858,10.8821784 7.10828565,11.7107438 8.04704387,11.8993431 C7.87539733,11.9459631 7.69315533,11.9713922 7.50667514,11.9713922 C7.37529138,11.9713922 7.2460267,11.9586777 7.12100021,11.9332486 C7.38164865,12.7490994 8.14028396,13.3424454 9.0387794,13.3593982 C8.33735961,13.9103624 7.45157872,14.2388218 6.48951049,14.2388218 C6.32422123,14.2388218 6.16105107,14.2282263 6,14.2091545 C6.90485272,14.7982623 7.98347107,15.1373172 9.14049587,15.1373172 C12.9188387,15.1373172 14.9828353,12.0074168 14.9828353,9.29285866 C14.9828353,9.20385675 14.9807163,9.11485484 14.9764781,9.02797203 C15.3769866,8.73765628 15.7245179,8.37741047 16,7.96418733 Z" />
        </g>
      </g>
    </svg>
  ),
  youtube: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,2c5.51367,0,10,4.48584,10,9.99951C22,17.51367,17.51367,22,12,22C6.48535,22,2,17.51367,2,11.99951
      C2,6.48584,6.48535,2,12,2 M12,1C5.9248,1,1,5.92529,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
      C23,5.92529,18.07617,1,12,1z"
        />
        <g transform="translate(3.500000, 7.000000),scale(1.1, 1.1)">
          <g transform="translate(0.800000, 0.200000)">
            <g transform="translate(0.500000, 0.000000)">
              <path
                d="M12.1749239,1.31331652 C12.0319239,0.796346087 11.610625,0.389253913 11.0755761,0.251092174 C10.105875,-4.20108393e-13 6.2173913,-4.20108393e-13 6.2173913,-4.20108393e-13 C6.2173913,-4.20108393e-13 2.32890761,-4.20108393e-13 1.35920652,0.251092174 C0.824228261,0.389253913 0.402858696,0.796346087 0.259858696,1.31331652 C-3.01980663e-14,2.25026609 -3.01980663e-14,4.20521739 -3.01980663e-14,4.20521739 C-3.01980663e-14,4.20521739 -3.01980663e-14,6.16010087 0.259858696,7.09718609 C0.402858696,7.6140887 0.824228261,8.02118087 1.35920652,8.15941043 C2.32890761,8.41043478 6.2173913,8.41043478 6.2173913,8.41043478 C6.2173913,8.41043478 10.105875,8.41043478 11.0755761,8.15941043 C11.610625,8.02118087 12.0319239,7.6140887 12.1749239,7.09718609 C12.4347826,6.16010087 12.4347826,4.20521739 12.4347826,4.20521739 C12.4347826,4.20521739 12.4347826,2.25026609 12.1749239,1.31331652"
                mask="url(#mask-2)"
              />
            </g>
            <polyline
              points="6.05163043 6.26654064 9.16032609 4.42351088 6.05163043 2.58034026 6.05163043 6.26654064"
              fill="#FFFFFE"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  alert: (
    <svg viewBox="0 0 24 24">
      <path
        d="M3.48828,21.50023c-0.43457-0.00488-0.8584-0.12012-1.23145-0.33203c-0.58301-0.33301-0.99902-0.87207-1.17188-1.5166
        c-0.17188-0.64258-0.08203-1.31152,0.25293-1.88672L9.85352,3.69848C9.85449,3.6975,9.85449,3.69652,9.85547,3.69555
        c0.20898-0.33984,0.50098-0.62891,0.84473-0.83594c1.18262-0.70703,2.72656-0.33301,3.44434,0.83594l8.51367,14.0625
        C22.87793,18.135,22.99512,18.5559,23,18.98363c0.01465,1.37207-1.09863,2.50098-2.48242,2.5166H3.48828z M10.70703,4.21898
        L2.19727,18.27562C2.00195,18.61156,1.94824,19.01,2.05078,19.39183c0.10352,0.38574,0.35254,0.70801,0.70215,0.90723
        c0.22559,0.12891,0.48145,0.19824,0.74121,0.20117h17.01758c0.82715-0.00977,1.49707-0.68457,1.48828-1.50586
        c-0.00293-0.25391-0.07227-0.50488-0.20117-0.72559L13.29102,4.21605c-0.43066-0.70215-1.3623-0.92578-2.07617-0.49902
        C11.00879,3.84105,10.83301,4.01488,10.70703,4.21898z"
      />
      <path
        d="M12,14.50023c-0.27637,0-0.5-0.22363-0.5-0.5v-6c0-0.27637,0.22363-0.5,0.5-0.5s0.5,0.22363,0.5,0.5v6
        C12.5,14.2766,12.27637,14.50023,12,14.50023z"
      />
      <circle cx="12" cy="17" r="1" />
      <path
        d="M3.48828,21.50023c-0.43457-0.00488-0.8584-0.12012-1.23145-0.33203c-0.58301-0.33301-0.99902-0.87207-1.17188-1.5166
        c-0.17188-0.64258-0.08203-1.31152,0.25293-1.88672L9.85352,3.69848C9.85449,3.6975,9.85449,3.69652,9.85547,3.69555
        c0.20898-0.33984,0.50098-0.62891,0.84473-0.83594c1.18262-0.70703,2.72656-0.33301,3.44434,0.83594l8.51367,14.0625
        C22.87793,18.135,22.99512,18.5559,23,18.98363c0.01465,1.37207-1.09863,2.50098-2.48242,2.5166H3.48828z M10.70703,4.21898
        L2.19727,18.27562C2.00195,18.61156,1.94824,19.01,2.05078,19.39183c0.10352,0.38574,0.35254,0.70801,0.70215,0.90723
        c0.22559,0.12891,0.48145,0.19824,0.74121,0.20117h17.01758c0.82715-0.00977,1.49707-0.68457,1.48828-1.50586
        c-0.00293-0.25391-0.07227-0.50488-0.20117-0.72559L13.29102,4.21605c-0.43066-0.70215-1.3623-0.92578-2.07617-0.49902
        C11.00879,3.84105,10.83301,4.01488,10.70703,4.21898z"
      />
      <path
        d="M12,14.50023c-0.27637,0-0.5-0.22363-0.5-0.5v-6c0-0.27637,0.22363-0.5,0.5-0.5s0.5,0.22363,0.5,0.5v6
        C12.5,14.2766,12.27637,14.50023,12,14.50023z"
      />
      <circle cx="12" cy="17" r="1" />
    </svg>
  ),
  calendar: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0643 2.16113H13.3755C14.4523 2.16113 15.3286 3.03743 15.3286 4.11459V13.3802C15.3286 14.4574 14.4523 15.3337 13.3755 15.3337H2.61964C1.54281 15.3337 0.666511 14.4574 0.666504 13.3802V4.11459C0.666504 3.03743 1.54281 2.16113 2.61963 2.16113H3.91976V1.33366C3.91976 0.965492 4.21826 0.666992 4.58643 0.666992C4.9546 0.666992 5.2531 0.965492 5.2531 1.33366V2.16113H10.731V1.33366C10.731 0.965492 11.0295 0.666992 11.3976 0.666992C11.7658 0.666992 12.0643 0.965492 12.0643 1.33366V2.16113ZM13.3755 14.0003C13.7173 14.0003 13.9953 13.722 13.9953 13.3802H13.9953V4.11459C13.9953 3.77279 13.7173 3.49447 13.3755 3.49447H12.0643V4.32194C12.0643 4.69011 11.7658 4.98861 11.3976 4.98861C11.0295 4.98861 10.731 4.69011 10.731 4.32194V3.49447H5.2531V4.32194C5.2531 4.69011 4.9546 4.98861 4.58644 4.98861C4.21827 4.98861 3.91977 4.69011 3.91977 4.32194V3.49447H2.61964C2.27784 3.49447 1.99984 3.77279 1.99984 4.11459V13.3802C1.99984 13.722 2.27784 14.0003 2.61964 14.0003H13.3755Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  chevron: (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <g>
        <path
          d="M8.00008 12C7.82951 12 7.65893 11.9349 7.52872 11.8047L0.862058 5.13802C0.601645 4.8776 0.601645 4.45572 0.862058 4.19531C1.12247 3.9349 1.54435 3.9349 1.80476 4.19531L8.00008 10.3906L14.1954 4.19531C14.4558 3.9349 14.8777 3.9349 15.1381 4.19531C15.3985 4.45572 15.3985 4.8776 15.1381 5.13802L8.47143 11.8047C8.34122 11.9349 8.17065 12 8.00008 12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  chevron_left: (
    <svg viewBox="0 0 24 24">
      <g>
        <path d="M11 21.634c-.128 0-.256-.049-.354-.147l-10-10.06c-.195-.196-.195-.515 0-.711l10-10.06c.196-.196.512-.196.708 0 .195.197.195.515 0 .712l-9.647 9.704 9.647 9.704c.195.196.195.514 0 .711-.098.098-.226.147-.354.147z" />
      </g>
    </svg>
  ),
  // resource: https://iconify.design/icon-sets/?query=angle+right
  chevron_right: (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 511.949 511.949"
    >
      <path
        d="M386.235,248.308L140.902,2.975c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827l237.76,237.76
			l-237.76,237.867c-4.267,4.053-4.373,10.88-0.213,15.04c4.053,4.267,10.88,4.373,15.04,0.213c0.107-0.107,0.213-0.213,0.213-0.213
			l245.333-245.333C390.395,259.188,390.395,252.468,386.235,248.308z"
      />
    </svg>
  ),
  arrow: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M22.35547,11.64795l-9.41016-9.5c-0.19482-0.1958-0.51074-0.19727-0.70752-0.00342
          c-0.1958,0.19434-0.19775,0.51123-0.00342,0.70752l8.5752,8.65674H2c-0.27637,0-0.5,0.22363-0.5,0.5s0.22363,0.5,0.5,0.5h18.79248
          l-8.55811,8.63916c-0.19434,0.19629-0.19238,0.51318,0.00342,0.70752C12.33545,21.95166,12.46289,22,12.58984,22
          c0.12891,0,0.25732-0.04932,0.35547-0.14795l9.38281-9.47266C22.43164,12.28809,22.5,12.1582,22.5,12.00879l-0.00098-0.00391
          C22.50049,11.87598,22.45312,11.74658,22.35547,11.64795z"
        />
      </g>
    </svg>
  ),
  info: (
    <svg viewBox="0 0 24 24">
      <g>
        <path
          d="M12,2c5.51416,0,10,4.48584,10,10s-4.48584,10-10,10S2,17.51416,2,12S6.48584,2,12,2 M12,1C5.9248,1,1,5.9248,1,12
		      s4.9248,11,11,11s11-4.9248,11-11S18.0752,1,12,1z"
        />
        <path
          d="M11.94775,17.02002c-0.27637,0-0.5-0.22363-0.5-0.5v-5.49609c0-0.27637,0.22363-0.5,0.5-0.5s0.5,0.22363,0.5,0.5v5.49609
		      C12.44775,16.79639,12.22412,17.02002,11.94775,17.02002z"
        />
        <circle cx="11.94775" cy="7.67236" r="0.75" />
      </g>
    </svg>
  ),
  user: (
    <svg viewBox="0 0 24 24">
      <g transform="scale(1.05)">
        <path
          transform="scale(0.85,0.85),translate(2,4)"
          d="M20.875,16.97754c-0.93164-3.61133-3.53711-5.19482-4.70605-5.73975c0.97559-1.03564,1.57715-2.42139,1.57715-3.94678
          c0-3.19336-2.61816-5.79102-5.83594-5.79102S6.0752,4.09766,6.0752,7.29102c0,1.5542,0.62305,2.96387,1.62988,4.00488
          c-1.22266,0.59814-3.68164,2.19824-4.58105,5.68164c-0.27734,1.07764-0.0918,2.0415,0.55371,2.86475
          c1.60645,2.05127,5.74316,2.62793,8.1875,2.65527l0.00293,0.00049l0.07031,0.00049L12.05176,22.5l0.16406-0.00098l0.02441-0.00537
          c2.87793-0.02637,6.58105-0.71826,8.08008-2.63232C20.96875,19.03418,21.15527,18.06396,20.875,16.97754z M11.91016,2.5
          c2.66699,0,4.83594,2.14941,4.83594,4.79102s-2.16895,4.79102-4.83594,4.79102c-2.66602,0-4.83496-2.14941-4.83496-4.79102
          S9.24414,2.5,11.91016,2.5z M19.5332,19.24463c-1.21191,1.54688-4.64062,2.24268-7.41504,2.25244
          c-2.81934,0.01221-6.4082-0.68115-7.65332-2.27148c-0.44922-0.57373-0.57129-1.22705-0.37207-1.99805
          c0.92969-3.60547,3.72949-4.92676,4.45312-5.21289c0.95215,0.66992,2.11133,1.06738,3.36426,1.06738
          c1.28027,0,2.46191-0.41602,3.4248-1.1123c0.4502,0.16064,3.57812,1.40625,4.57129,5.25781
          C20.10742,18.00732,19.98535,18.66699,19.5332,19.24463z"
        />
        <path
          d="M12,1C5.9248,1,1,5.9248,1,11.99951C1,18.07568,5.9248,23,12,23c6.07617,0,11-4.92432,11-11.00049
          C23,5.9248,18.07617,1,12,1z M12,22C6.48633,22,2,17.51367,2,11.99951C2,6.48584,6.48633,2,12,2s10,4.48584,10,9.99951
          C22,17.51367,17.51367,22,12,22z"
        />
      </g>
    </svg>
  ),
  star_filled: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C10.1578 0 10.3021 0.0891677 10.3727 0.230328L13.1906 5.86617L19.2217 6.67032C19.3789 6.69127 19.5105 6.79957 19.5613 6.94973C19.6122 7.09988 19.5734 7.26587 19.4613 7.37796L15.0356 11.8036L16.2419 17.835C16.2731 17.991 16.2127 18.1512 16.0862 18.2478C15.9598 18.3444 15.7893 18.3605 15.647 18.2893L10 15.4658L4.35301 18.2893C4.21067 18.3605 4.04022 18.3444 3.91375 18.2478C3.78729 18.1512 3.72688 17.991 3.75809 17.835L4.96436 11.8036L0.538707 7.37796C0.426614 7.26587 0.387838 7.09988 0.43867 6.94973C0.489502 6.79957 0.621134 6.69127 0.778266 6.67032L6.8094 5.86617L9.62732 0.230328C9.6979 0.0891677 9.84218 0 10 0Z"
        fill="inherit"
      />
    </svg>
  ),
  // adapted from https://iconify.design/icon-sets/simple-line-icons/paper-plane.html
  paper_plane: (
    <svg viewBox="0 0 1024 1024">
      <path d="M1004 0q-9 0-22 5L27 410q-17 8-23.5 18.5T2 451t20 22l270 168q16 10 34 26t34 34t25 34l162 265q7 11 15 16.5t16 5.5q20 0 31-27l409-954q15-36-9-41h-5zM326 584L107 448l734-312l-473 481q-23-21-42-33zm247 331L442 702q-3-6-8-13t-10-13.5t-11-13.5l474-482z" />
    </svg>
  ),
  // stole from https://iconify.design/icon-sets/typcn/location-arrow-outline.html
  location_alt: (
    <svg viewBox="0 0 24 24">
      <path d="M11.087 20.914c-.353 0-1.219-.146-1.668-1.496L8.21 15.791l-3.628-1.209c-1.244-.415-1.469-1.172-1.493-1.587s.114-1.193 1.302-1.747l11.375-5.309c1.031-.479 1.922-.309 2.348.362c.224.351.396.97-.053 1.933l-5.309 11.375c-.529 1.135-1.272 1.305-1.665 1.305zm-5.39-8.068l4.094 1.363l1.365 4.093l4.775-10.233l-10.234 4.777z" />
    </svg>
  ),
  gift: (
    <svg viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 4.00008H13.0994C13.245 3.6958 13.3334 3.35929 13.3334 3.00008C13.3334 1.71362 12.2865 0.666748 11.0001 0.666748C9.46757 0.666748 8.5391 1.74161 8.00008 2.75923C7.46106 1.74161 6.53259 0.666748 5.00008 0.666748C3.71362 0.666748 2.66675 1.71362 2.66675 3.00008C2.66675 3.35929 2.75513 3.6958 2.90079 4.00008H1.33341C0.964928 4.00008 0.666748 4.29858 0.666748 4.66675V8.00008C0.666748 8.36825 0.964928 8.66675 1.33341 8.66675H2.00008V14.6667C2.00008 15.0349 2.29826 15.3334 2.66675 15.3334H13.3334C13.7019 15.3334 14.0001 15.0349 14.0001 14.6667V8.66675H14.6667C15.0352 8.66675 15.3334 8.36825 15.3334 8.00008V4.66675C15.3334 4.29858 15.0352 4.00008 14.6667 4.00008ZM11.0001 2.00008C11.5515 2.00008 12.0001 2.44865 12.0001 3.00008C12.0001 3.55151 11.5515 4.00008 11.0001 4.00008H8.89721C9.21102 3.17293 9.85815 2.00008 11.0001 2.00008ZM5.00008 2.00008C4.44865 2.00008 4.00008 2.44865 4.00008 3.00008C4.00008 3.55151 4.44865 4.00008 5.00008 4.00008H7.10295C6.78914 3.17293 6.14201 2.00008 5.00008 2.00008ZM2.00008 5.33342H5.00008H7.33342V7.33342H2.00008V5.33342ZM7.33341 8.66675H3.33341V14.0001H7.33341V8.66675ZM12.6667 14.0001H8.66675V8.66675H12.6667V14.0001ZM8.66675 7.33342H14.0001V5.33342H11.0001H8.66675V7.33342Z"
      />
    </svg>
  ),
  tooltip: (
    <svg viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666016 7.99984C0.666016 3.9497 3.94922 0.666504 7.99935 0.666504C12.0495 0.666504 15.3327 3.9497 15.3327 7.99984C15.3327 12.05 12.0495 15.3332 7.99935 15.3332C3.94922 15.3332 0.666016 12.05 0.666016 7.99984ZM14.666 7.99984C14.666 4.32373 11.6755 1.33317 7.99935 1.33317C4.32324 1.33317 1.33268 4.32373 1.33268 7.99984C1.33268 11.6759 4.32324 14.6665 7.99935 14.6665C11.6755 14.6665 14.666 11.6759 14.666 7.99984ZM7.91016 9.92138C7.72591 9.92138 7.57682 9.7723 7.57682 9.58805V8.63069C7.57682 8.47118 7.69108 8.33349 7.84766 8.30322C7.90755 8.2915 9.3151 8.00472 9.4095 6.77327C9.40853 6.0389 9.07975 5.56494 8.43099 5.39013C7.57617 5.15868 6.76302 5.54182 6.49642 6.29898C6.43522 6.47281 6.24381 6.56266 6.07128 6.50276C5.89779 6.44157 5.80632 6.25114 5.86752 6.07764C6.24642 5.00016 7.4222 4.4279 8.60482 4.74626C9.55306 5.00212 10.0752 5.73096 10.0752 6.79866C9.98048 8.04801 8.95281 8.67074 8.2435 8.89014V9.58806C8.24349 9.7723 8.0944 9.92138 7.91016 9.92138ZM7.96192 12.2775C8.23806 12.2775 8.46192 12.0536 8.46192 11.7775C8.46192 11.5014 8.23806 11.2775 7.96192 11.2775C7.68577 11.2775 7.46192 11.5014 7.46192 11.7775C7.46192 12.0536 7.68577 12.2775 7.96192 12.2775Z"
      />
    </svg>
  ),
  heart: (
    <svg viewBox="0 0 16 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52005 14.3731C7.64638 14.502 7.81957 14.5742 8.00005 14.5731C8.17443 14.5795 8.34437 14.5173 8.47338 14.3998L14.4 8.25977C16.0814 6.48341 16.0814 3.70279 14.4 1.92644C13.5991 1.07925 12.4859 0.597357 11.32 0.593103C10.1519 0.595532 9.03593 1.07758 8.23338 1.92644L8.00005 2.16644L7.76671 1.89977C6.96353 1.05258 5.84745 0.572754 4.68005 0.572754C3.51265 0.572754 2.39656 1.05258 1.59338 1.89977C-0.0879302 3.67613 -0.0879302 6.45675 1.59338 8.2331L7.52005 14.3731ZM2.55338 2.8331C3.1021 2.24911 3.86542 1.91441 4.66671 1.90644C5.47779 1.90471 6.25303 2.24041 6.80671 2.8331L7.52004 3.56644C7.6457 3.6968 7.81898 3.77045 8.00004 3.77045C8.18111 3.77045 8.35439 3.6968 8.48005 3.56644L9.19338 2.8331C9.74706 2.24041 10.5223 1.90471 11.3334 1.90644C12.1348 1.91363 12.8984 2.24847 13.4467 2.8331C14.6345 4.08769 14.6345 6.05185 13.4467 7.30643L8.00004 12.9464L2.55338 7.3131C1.3681 6.05496 1.3681 4.09124 2.55338 2.8331Z"
      />
    </svg>
  ),
  map_marker: (
    <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1140_50829)">
        <path
          d="M0 9.73189C0.162679 6.23071 1.67464 3.46365 4.67943 1.53424C6.78469 0.197767 9.11962 -0.253998 11.5789 0.141296C14.756 0.649532 17.1579 2.33424 18.756 5.08247C19.6555 6.626 20.067 8.31071 20 10.0895C19.9617 11.106 19.6555 12.0754 19.2919 13.026C18.5263 15.0307 17.5024 16.9037 16.4115 18.7389C14.622 21.7413 12.622 24.6025 10.5167 27.4072C10.4115 27.5484 10.2967 27.6989 10.1818 27.8401C10.0191 28.0472 9.99043 28.0472 9.83732 27.8495C9.44498 27.3319 9.05263 26.8237 8.66986 26.2966C7.12919 24.1978 5.64593 22.0519 4.26794 19.8401C2.96651 17.7413 1.7512 15.6142 0.832536 13.3178C0.488038 12.4519 0.200957 11.5766 0.0669856 10.6542C0.0382775 10.3531 0.0287081 10.0519 0 9.73189ZM5.1866 9.80718C5.16746 12.4425 7.311 14.5884 9.97129 14.6072C12.6316 14.626 14.823 12.5178 14.8421 9.92012C14.8612 7.23777 12.7368 5.12012 10.0096 5.1013C7.36842 5.08247 5.20574 7.20012 5.1866 9.80718Z"
          fill="#DB331A"
        />
        <path
          d="M5.18665 9.80708C5.20579 7.20002 7.36847 5.08238 10.0096 5.1012C12.7369 5.12002 14.8613 7.24708 14.8422 9.92002C14.823 12.5177 12.6221 14.6259 9.97134 14.6071C7.31105 14.5883 5.16751 12.4424 5.18665 9.80708Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1140_50829">
          <rect width="20" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  star: (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C10.1578 0 10.3021 0.0891677 10.3727 0.230328L13.1906 5.86617L19.2218 6.67032C19.3789 6.69127 19.5105 6.79957 19.5613 6.94973C19.6122 7.09988 19.5734 7.26587 19.4613 7.37796L15.0357 11.8036L16.2419 17.835C16.2731 17.991 16.2127 18.1512 16.0863 18.2478C15.9598 18.3444 15.7893 18.3605 15.647 18.2893L10 15.4658L4.35302 18.2893C4.21068 18.3605 4.04024 18.3444 3.91377 18.2478C3.7873 18.1512 3.7269 17.991 3.75811 17.835L4.96438 11.8036L0.538722 7.37796C0.426629 7.26587 0.387853 7.09988 0.438685 6.94973C0.489517 6.79957 0.621149 6.69127 0.778282 6.67032L6.80942 5.86617L9.62734 0.230328C9.69792 0.0891677 9.84219 0 10 0ZM10 1.34836L7.45603 6.43634C7.39427 6.55986 7.27531 6.64476 7.13842 6.66301L1.72418 7.38491L5.71131 11.372C5.80982 11.4705 5.85258 11.6118 5.82526 11.7484L4.74234 17.163L9.81368 14.6273C9.93098 14.5687 10.0691 14.5687 10.1864 14.6273L15.2577 17.163L14.1748 11.7484C14.1475 11.6118 14.1902 11.4705 14.2887 11.372L18.2759 7.38491L12.8616 6.66301C12.7247 6.64476 12.6058 6.55986 12.544 6.43634L10 1.34836Z"
        fill="currentColor"
      />
    </svg>
  ),
  hamburger: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g x="1" y="4" width="22" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 6.5H2C1.44727 6.5 1 6.05225 1 5.5C1 4.94775 1.44727 4.5 2 4.5H22C22.5527 4.5 23 4.94775 23 5.5C23 6.05225 22.5527 6.5 22 6.5ZM22 13.0342H2C1.44727 13.0342 1 12.5864 1 12.0342C1 11.4819 1.44727 11.0342 2 11.0342H22C22.5527 11.0342 23 11.4819 23 12.0342C23 12.5864 22.5527 13.0342 22 13.0342ZM2 19.5H22C22.5527 19.5 23 19.0522 23 18.5C23 17.9478 22.5527 17.5 22 17.5H2C1.44727 17.5 1 17.9478 1 18.5C1 19.0522 1.44727 19.5 2 19.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  logout: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23633 21.5H8.70996C8.98633 21.5 9.20996 21.7236 9.20996 22C9.20996 22.2764 8.98633 22.5 8.70996 22.5H4.23633C2.72754 22.5 1.5 21.2783 1.5 19.7773V4.22266C1.5 2.72168 2.72754 1.5 4.23633 1.5H8.70996C8.98633 1.5 9.20996 1.72363 9.20996 2C9.20996 2.27637 8.98633 2.5 8.70996 2.5H4.23633C3.2793 2.5 2.5 3.27246 2.5 4.22266V19.7773C2.5 20.7275 3.2793 21.5 4.23633 21.5ZM16.4297 5.89454L22.3516 11.7627C22.4463 11.8565 22.5 11.9844 22.5 12.1182C22.5 12.2519 22.4463 12.3799 22.3516 12.4736L16.4297 18.3418C16.333 18.4385 16.2051 18.4863 16.0781 18.4863C15.9492 18.4863 15.8203 18.4365 15.7227 18.3379C15.5283 18.1426 15.5303 17.8252 15.7266 17.6309L20.7793 12.624H8.99219C8.71582 12.624 8.49219 12.4004 8.49219 12.124C8.49219 11.8477 8.71582 11.624 8.99219 11.624H20.791L15.7266 6.60548C15.5303 6.41115 15.5283 6.09376 15.7227 5.89845C15.917 5.70216 16.2353 5.7002 16.4297 5.89454Z"
      />
    </svg>
  ),
  search: (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M22.35059,21.64355l-4.15723-4.0918l-0.09863-0.06396c1.69531-1.75146,2.74219-4.1167,2.74219-6.72607 C20.83691,5.37012,16.39648,1,10.91797,1C5.44043,1,1,5.37012,1,10.76172c0,5.39111,4.44043,9.76123,9.91797,9.76123 c2.4834,0,4.74707-0.90381,6.48633-2.3877l0.08789,0.12939l4.15723,4.0918C21.74707,22.45215,21.87305,22.5,22,22.5 c0.12891,0,0.25879-0.0498,0.35645-0.14941C22.5498,22.15381,22.54785,21.8374,22.35059,21.64355z M10.91797,19.52295 C6.00098,19.52295,2,15.59277,2,10.76172C2,5.93018,6.00098,2,10.91797,2c4.91797,0,8.91895,3.93018,8.91895,8.76172 C19.83691,15.59277,15.83594,19.52295,10.91797,19.52295z" />
    </svg>
  ),
  restaurant: (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M22.5,9.2002l-0.01465-0.23145l-0.00781-0.02539C22.5,8.85156,22.50586,8.75391,22.4707,8.6582l-2.09863-5.82715 C20.30078,2.63184,20.1123,2.5,19.90137,2.5H3.77441c-0.21973,0-0.41406,0.14355-0.47852,0.35449L1.52148,8.68262 C1.49316,8.77637,1.50195,8.86914,1.52637,8.95703l-0.01562,0.0498L1.5,9.2002c0,0.7666,0.48828,1.44336,1.23047,1.86719 l-0.02344,0.11426V21c0,0.27637,0.22363,0.5,0.5,0.5h4.20508c0.27637,0,0.5-0.22363,0.5-0.5v-4.21973h3.11914V21 c0,0.27637,0.22363,0.5,0.5,0.5h8.97754c0.27637,0,0.5-0.22363,0.5-0.5v-9.80273C21.89648,10.79297,22.5,10.05371,22.5,9.2002z M20.00879,20.5h-7.97754v-4.21973c0-0.27637-0.22363-0.5-0.5-0.5H7.41211c-0.27637,0-0.5,0.22363-0.5,0.5V20.5H3.70703v-9.06836 c0.25391,0.05469,0.5166,0.09277,0.79297,0.09277c1.04395,0,1.96289-0.41602,2.5-1.04395 c0.53711,0.62793,1.45605,1.04395,2.5,1.04395s1.96289-0.41602,2.5-1.04395c0.53711,0.62793,1.45605,1.04395,2.5,1.04395 s1.96289-0.41602,2.5-1.04395c0.53711,0.62793,1.45605,1.04395,2.5,1.04395c0.17383,0,0.34277-0.01758,0.50879-0.04004V20.5z M19.5,10.52441c-1.08398,0-2-0.60645-1.99512-1.41406c0.01172-0.10645-0.01953-0.20312-0.06738-0.29004l-0.03711-0.06836 l-0.01367-0.0166c-0.10352-0.1416-0.27344-0.23047-0.45605-0.2041c-0.27441,0.03223-0.4707,0.28125-0.4375,0.55469L16.5,9.2002 c0,0.71777-0.91602,1.32422-2,1.32422s-2-0.60645-1.99512-1.41406c0.01172-0.10645-0.01953-0.20312-0.06836-0.29004 l-0.03613-0.06836l-0.01465-0.0166c-0.10352-0.1416-0.27246-0.23047-0.45508-0.2041 c-0.27441,0.03223-0.4707,0.28125-0.4375,0.55469L11.5,9.2002c0,0.71777-0.91602,1.32422-2,1.32422s-2-0.60645-1.99512-1.41406 C7.5166,9.00293,7.48535,8.90527,7.43555,8.81738l-0.0332-0.0625L7.38281,8.73242C7.27832,8.59277,7.11035,8.50488,6.93066,8.53125 c-0.27441,0.03223-0.4707,0.28125-0.4375,0.55469L6.5,9.2002c0,0.71777-0.91602,1.32422-2,1.32422s-2-0.60645-1.99512-1.41406 l-0.01562-0.1709L4.14453,3.5H19.5498l1.95605,5.43066l-0.0127,0.15527L21.5,9.2002C21.5,9.91797,20.58398,10.52441,19.5,10.52441z " />
    </svg>
  ),
  error: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.9248 5.9248 1 12 1C18.0752 1 23 5.9248 23 12C23 18.0752 18.0752 23 12 23C5.9248 23 1 18.0752 1 12ZM22 12C22 6.48633 17.5142 2 12 2C6.48584 2 2 6.48633 2 12C2 17.5137 6.48584 22 12 22C17.5142 22 22 17.5137 22 12ZM12.0522 13.4756C11.7759 13.4756 11.5522 13.252 11.5522 12.9756V7.48047C11.5522 7.2041 11.7759 6.98047 12.0522 6.98047C12.3286 6.98047 12.5522 7.2041 12.5522 7.48047V12.9756C12.5522 13.252 12.3286 13.4756 12.0522 13.4756ZM12.0522 15.252C11.458 15.252 10.977 15.7334 10.977 16.3272C10.977 16.9219 11.458 17.4033 12.0522 17.4033C12.646 17.4033 13.1274 16.9219 13.1274 16.3272C13.1274 15.7334 12.646 15.252 12.0522 15.252Z"
      />
    </svg>
  ),
  course: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C9 1.72363 9.22363 1.5 9.5 1.5C9.77637 1.5 10 1.72363 10 2V8.38574C10 9.96241 8.45313 11.2451 6.55176 11.2451H6.5V22C6.5 22.2764 6.27637 22.5 6 22.5C5.72363 22.5 5.5 22.2764 5.5 22V11.2451H5.44727C3.54688 11.2451 2 9.9624 2 8.38574V2C2 1.72363 2.22363 1.5 2.5 1.5C2.77637 1.5 3 1.72363 3 2V8.38574C3 9.39356 4.1211 10.2451 5.44727 10.2451H5.5V2C5.5 1.72363 5.72363 1.5 6 1.5C6.27637 1.5 6.5 1.72363 6.5 2V10.2451H6.55176C7.87891 10.2451 9 9.39355 9 8.38574V2ZM22.6035 6.52734C22.6035 3.75537 20.8096 1.5 18.6035 1.5C16.3975 1.5 14.6035 3.75537 14.6035 6.52734C14.6035 9.08398 16.1358 11.1777 18.1035 11.4912V22C18.1035 22.2764 18.3272 22.5 18.6035 22.5C18.8799 22.5 19.1035 22.2764 19.1035 22V11.4912C21.0713 11.1777 22.6035 9.08398 22.6035 6.52734ZM18.6035 10.5547C16.9492 10.5547 15.6035 8.74805 15.6035 6.52735C15.6035 4.30665 16.9492 2.50001 18.6035 2.50001C20.2578 2.50001 21.6035 4.30665 21.6035 6.52735C21.6035 8.74805 20.2578 10.5547 18.6035 10.5547Z"
      />
    </svg>
  ),
  edit: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3916 2.59782C19.8984 1.11979 18.2373 1.13395 16.7138 2.64177L2.30659 16.8971C2.22847 16.9743 2.17768 17.0754 2.16304 17.1838L1.50484 21.9342C1.48238 22.0939 1.53902 22.2541 1.65523 22.3649C1.74897 22.4543 1.873 22.5031 1.99995 22.5031L2.09272 22.4943L6.68061 21.631C6.77827 21.613 6.86909 21.5656 6.9394 21.4953L20.0361 8.53678L21.3466 7.24088C22.1122 6.48307 22.5 5.69059 22.5 4.88493C22.5 4.09489 22.1269 3.32536 21.3916 2.59782ZM6.34468 20.6769L2.59077 21.383L3.12983 17.4889L14.5107 6.22771L14.5166 6.2365L17.6093 9.4782L17.6406 9.50016L6.34468 20.6769ZM18.3427 8.80534L20.6435 6.52946C21.2119 5.96745 21.5 5.41373 21.5 4.88493C21.5 4.37223 21.2265 3.84147 20.6884 3.30875C19.5888 2.2194 18.5498 2.23258 17.417 3.3527L15.2168 5.52995L15.2392 5.54606L18.332 8.78776L18.3427 8.80534Z"
      />
    </svg>
  ),
  external_link: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 16.1313C21 15.5791 21.4478 15.1313 22 15.1313C22.5522 15.1313 23 15.5791 23 16.1313V19.7778C23 21.5547 21.5547 23 19.7778 23H4.22217C2.44531 23 1 21.5547 1 19.7778V4.22217C1 2.44531 2.44531 1 4.22217 1H8C8.55225 1 9 1.44775 9 2C9 2.55225 8.55225 3 8 3H4.22217C3.54834 3 3 3.54834 3 4.22217V19.7778C3 20.4517 3.54834 21 4.22217 21H19.7778C20.4517 21 21 20.4517 21 19.7778V16.1313ZM14.6123 10.8071C14.4175 11.002 14.1616 11.0996 13.9058 11.0996C13.6499 11.0996 13.3935 11.002 13.1982 10.8061C12.8081 10.4155 12.8081 9.78223 13.1992 9.39209L19.5669 3.03101L14.3364 3.00635C13.7842 3.00391 13.3389 2.55371 13.3413 2.00147C13.3438 1.45117 13.791 1.00635 14.3413 1.00635H14.3462L21.9688 1.04248C22.2445 1.0437 22.4933 1.15717 22.6735 1.33893C22.6737 1.33912 22.6739 1.33925 22.6741 1.33939C22.6744 1.33953 22.6746 1.33967 22.6748 1.33985C22.6839 1.34895 22.6898 1.35969 22.6956 1.37045C22.7007 1.37976 22.7058 1.38908 22.713 1.39735C22.7812 1.47663 22.845 1.56019 22.8859 1.65821C22.9407 1.78791 22.9664 1.92536 22.964 2.06391L23 9.65235C23.0024 10.2046 22.5571 10.6548 22.0049 10.6572H22C21.4497 10.6572 21.0024 10.2129 21 9.66211L20.9754 4.45056L14.6123 10.8071Z"
      />
    </svg>
  ),
}

export default icons

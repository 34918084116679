/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent, ChangeEvent, useState, Fragment } from 'react'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import Divider from '../Divider'
import Grid, { GridProps } from '../Grid'
import { Option } from '../Select'
import SvgIcon from '../SvgIcon'
import Text from '../Text'

const StyledRadioInput = styled.input`
  display: none;
`

const StyledGrid = styled(Grid)`
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    grid-template-columns: 1fr;
  }
`

const StyledLabel = styled.label<{ isDisabled?: boolean }>`
  grid-template-columns: auto 1fr;
  align-items: center;
  display: grid;
  gap: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.MD}) {
    margin-bottom: 20px;
  }
  ${(props) =>
    props.isDisabled && {
      cursor: 'auto',
    }}
`

const dividerStyles = css`
  margin-bottom: 10px;
`

interface RadioProps {
  options: Option[]
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  name?: string
  showDivider?: boolean
  selected?: string
}

const Radio: FunctionComponent<RadioProps & GridProps> = ({
  onChange,
  options,
  name,
  showDivider = false,
  selected,
  ...gridProps
}) => {
  const [value, setValue] = useState<string | undefined>(selected)

  const renderIcon = (isChecked: boolean, isDisabled?: boolean): JSX.Element => {
    if (isChecked) {
      return <SvgIcon name="filled_circle" fontSize="28px" color="PRIMARY" />
    }

    return <SvgIcon name="circle" fontSize="28px" color={isDisabled ? 'GRAY_2' : 'PRIMARY'} />
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <StyledGrid {...gridProps}>
      {options.map((option: Option, idx: number) => (
        <Fragment key={option.key}>
          <StyledLabel htmlFor={option.key} isDisabled={option.disabled}>
            <StyledRadioInput
              checked={option.value === value}
              onChange={handleChange}
              value={option.value}
              id={option.key}
              type="radio"
              name={name}
              data-test={name}
              disabled={option.disabled}
            />
            {renderIcon(option.value === value, option?.disabled)}
            <Text>{option.label}</Text>
          </StyledLabel>
          {showDivider && options.length - 1 !== idx ? (
            <Divider classNames={dividerStyles} />
          ) : null}
        </Fragment>
      ))}
    </StyledGrid>
  )
}

export default Radio

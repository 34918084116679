import { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'
import { Button, ButtonProps, ButtonVariants, Grid, SvgIcon, Text } from '@/tbui'

export interface PopupModalTemplateProps {
  name: string
  onClose?: () => void
  title: string
  callToAction?: string
  cancel?: string
  onSubmit?: () => void
  message?: string
  icon?: ReactNode
  callToActionVariant?: ButtonProps['variant']
}

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const StyledButton = styled(Button)`
  min-width: 45%;
  max-width: 100%;
`

const PopupModalTemplate: FunctionComponent<PopupModalTemplateProps> = ({
  name,
  callToAction,
  onSubmit,
  message,
  onClose,
  title,
  icon,
  cancel,
  callToActionVariant = ButtonVariants.SECONDARY,
}) => (
  <Grid align="center" gap="28px" data-test={name}>
    <Grid justify="center">{icon ?? <SvgIcon fontSize="50px" name="alert" color="ERROR" />}</Grid>
    <Grid justify="center" gap="18px">
      <Text type="H3" align="center">
        {title}
      </Text>
      {message && (
        <Text data-test="popup-modal-message" align="center" type="H4" bold={false}>
          {message}
        </Text>
      )}
    </Grid>
    {callToAction && (onClose || onSubmit) && (
      <BtnContainer>
        {cancel != null && onClose != null && (
          <StyledButton data-test="cancel-btn" onClick={onClose} variant="LINK">
            {cancel}
          </StyledButton>
        )}
        <StyledButton
          data-test={`${callToAction.toLocaleLowerCase()}-cta`}
          onClick={onSubmit ?? onClose}
          variant={callToActionVariant}
        >
          {callToAction}
        </StyledButton>
      </BtnContainer>
    )}
  </Grid>
)

export default PopupModalTemplate

import { getSearchVenue } from '@/libs/helpers/apiClient'
import providerFactory, { ActionsCreator, Reducer } from '@/libs/helpers/hooks/providerFactory'
import { OrderMethod } from '@/libs/helpers/utils'

const SEARCH_LIMIT = 60

interface Pagination {
  previous: string
  limit: number
  total: number
  page: number
  next: string
  max: number
}

export interface SearchVenue {
  venueXRefID: string
  venueName: string
  venueAddress1: string
  venueState: string
  venueCountry: string
  venueCity: string
  orderingURL: string
  distanceKM: number
}

export interface SearchResults {
  pagination: Pagination
  data: SearchVenue[]
}

const config = {
  nearSearchMaxRadiusKM: 2.5,
  searchAllMaxRadiusKM: 100,
  farSearchMaxRadiusKM: 10,
}

interface InitialState {
  searchResultsNear?: SearchResults | null
  searchResultsFar?: SearchResults | null
  orderingVenue?: string | null
  orderMethod?: OrderMethod
}

const initialState: InitialState = {
  searchResultsNear: null,
  searchResultsFar: null,
  orderingVenue: null,
}

enum ActionTypes {
  GET_SEARCH_RESULTS_NEAR = 'GET_SEARCH_RESULTS_NEAR',
  GET_SEARCH_RESULTS_FAR = 'GET_SEARCH_RESULTS_FAR',
  CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS',
  SET_ORDERING_VENUE = 'SET_ORDERING_VENUE',
  SET_ORDER_METHOD = 'SET_ORDER_METHOD',
}

/* state provider that allows searching venues, clearing search results, setting an order method and ordering venue */

const reducer: Reducer<InitialState, ActionTypes> =
  () =>
  (state, action): InitialState => {
    switch (action.type) {
      case ActionTypes.SET_ORDERING_VENUE:
        return {
          ...state,
          orderingVenue: action.payload?.orderingVenue,
          searchResultsNear: null,
          searchResultsFar: null,
        }
      case ActionTypes.SET_ORDER_METHOD:
        return {
          ...state,
          searchResultsNear: null,
          searchResultsFar: null,
        }
      case ActionTypes.GET_SEARCH_RESULTS_FAR:
        return {
          ...state,
          searchResultsFar: action.payload?.searchResultsFar,
        }
      case ActionTypes.GET_SEARCH_RESULTS_NEAR:
        return {
          ...state,
          searchResultsNear: action.payload?.searchResultsNear,
        }
      case ActionTypes.CLEAR_SEARCH_RESULTS:
        return {
          ...state,
          searchResultsNear: null,
          searchResultsFar: null,
        }
      /* istanbul ignore next */
      default:
        return state
    }
  }

// ACTIONS
const useActionsCreator: ActionsCreator<InitialState, ActionTypes> = ({
  setFullPageError,
  prevState,
  dispatch,
  router,
}) => {
  const setOrderMethod = (orderMethod: OrderMethod): void => {
    if (!prevState.orderingVenue) {
      router.push('/[orderMethod]', `/${orderMethod.toLowerCase()}`)
    }

    dispatch({
      type: ActionTypes.SET_ORDER_METHOD,
      payload: { orderMethod },
    })
  }

  const setOrderingVenue = (orderMethod: OrderMethod, orderingVenue: string): void => {
    router.push('/[orderMethod]/all', `/${orderMethod.toLowerCase()}/all`)

    dispatch({
      type: ActionTypes.SET_ORDERING_VENUE,
      payload: { orderingVenue },
    })
  }

  const getSearchResultNear = async ({
    maxDistanceKM,
    orderMethod,
    query,
    lat,
    lng,
  }: {
    maxDistanceKM?: number
    orderMethod: OrderMethod
    query?: string
    lng: number
    lat: number
  }): Promise<void> => {
    try {
      const searchResults = await getSearchVenue({
        params: {
          // Configurable max distance for results within 1 km range
          maxDistanceKM: maxDistanceKM ?? config.nearSearchMaxRadiusKM,
          orderMethod,
          consumerLongitude: lng,
          consumerLatitude: lat,
          limit: SEARCH_LIMIT,
          query,
        },
      })

      dispatch({
        type: ActionTypes.GET_SEARCH_RESULTS_NEAR,
        payload: { searchResultsNear: searchResults },
      })
    } catch (error) {
      setFullPageError(error)
    }
  }

  const getSearchResultFar = async ({
    orderMethod,
    lat,
    lng,
  }: {
    orderMethod: OrderMethod
    lat: number
    lng: number
  }): Promise<void> => {
    try {
      const searchResults = await getSearchVenue({
        params: {
          // Configurable max distance for results beyond 2.5 km range but within a 10 km radius
          minDistanceKM: config.nearSearchMaxRadiusKM,
          maxDistanceKM: config.farSearchMaxRadiusKM,
          orderMethod,
          consumerLongitude: lng,
          consumerLatitude: lat,
          limit: SEARCH_LIMIT,
        },
      })

      dispatch({
        type: ActionTypes.GET_SEARCH_RESULTS_FAR,
        payload: { searchResultsFar: searchResults },
      })
    } catch (error) {
      setFullPageError(error)
    }
  }

  const clearSearchResults = (): void => {
    dispatch({
      type: ActionTypes.CLEAR_SEARCH_RESULTS,
    })
  }

  return {
    getSearchResultNear,
    getSearchResultFar,
    clearSearchResults,
    setOrderingVenue,
    setOrderMethod,
  }
}

// Create
type GeneratedActions = ReturnType<typeof useActionsCreator>
const { Provider, useProvider } = providerFactory<InitialState, GeneratedActions, ActionTypes>({
  useActionsCreator,
  initialState,
  reducer,
})

// Export
export const distanceConfig = config
export type UseSearchType = ReturnType<typeof useProvider>
export const useSearch = useProvider
export default Provider

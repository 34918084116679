/* eslint-disable react/jsx-props-no-spreading */
import type { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import CircularLoader from '../CircularLoader'
import Grid, { GridProps } from '../Grid'

const StyledGrid = styled(Grid)`
  place-content: center;
  height: 100vh;
`

const LoadScreen: FunctionComponent<GridProps> = ({ ...gridProps }) => {
  return (
    <StyledGrid {...gridProps}>
      <CircularLoader fontSize="60px" />
    </StyledGrid>
  )
}

export default LoadScreen

import { ParsedUrlQuery } from 'querystring'
import { Dispatch } from 'react'
import { ConsumerProfile } from '@/libs/helpers/apiClient'
import { ParsedAxiosError } from '@/libs/helpers/utils'

enum ErrorActionTypes {
  FULL_PAGE = 'FULL_PAGE',
  MODAL_ERROR = 'MODAL_ERROR',
  CLEAR = 'CLEAR',
}

export type ErrorHandlerParams = {
  errors: ParsedAxiosError[]
  params: ParsedUrlQuery
  user?: ConsumerProfile
}

export interface StateType extends ParsedAxiosError {
  type: ErrorActionTypes
}

const initialState: StateType = null as unknown as StateType
interface Action extends Partial<ParsedAxiosError> {
  type: ErrorActionTypes
}

export type DispatchType = Dispatch<Action>

const reducer = (state: StateType, action: Action): StateType => {
  switch (action.type) {
    case ErrorActionTypes.FULL_PAGE:
    case ErrorActionTypes.MODAL_ERROR: {
      return {
        type: ErrorActionTypes.FULL_PAGE,
        message: action.message ?? '',
        status: action.status ?? 500,
        errorCode: action.errorCode,
      }
    }

    case ErrorActionTypes.CLEAR:
      return initialState
    /* istanbul ignore next */
    default:
      return state
  }
}

export { initialState, reducer, ErrorActionTypes }

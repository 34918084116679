import { FunctionComponent, useState, ReactNode } from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import publicConfig from '@/config/public'
import type { ConsumerProfile } from '@/libs/helpers/apiClient'
import { useCookies } from '@/libs/helpers/hooks/useCookies'
import { useWindowSize } from '@/libs/helpers/hooks/useWindowSize'
import { HeaderVariant, OrderMethod } from '@/libs/helpers/utils'
import { useConfiguration } from '@/providers/configuration/ConfigurationContext'
import { Button, Grid, Select, SelectVariant, Option, SvgIcon, Divider } from '@/tbui'
import { SlideUp, ToggleButton } from '@/tbui/Drawer'
import iconList from '@/tbui/SvgIcon/icons'

const StyledGrid = styled(Grid)`
  position: relative;
  padding: 20px 30px;
  a {
    background: transparent;
    position: absolute;
    min-width: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    top: 0;
    i {
      display: none;
    }
    label {
      text-align: center;
    }
  }
`

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledOptionLabel = styled.div`
  width: 100%;
`

const StyledLabel = styled.p`
  margin: 16px 0;
  font-family: ${(props) => props.theme.font.FAMILY};
`

const StyledOptionsContainer = styled.div`
  height: 264px;
`

const StyledToggleButtonContent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  gap: 10px;
`

const ButtonContentWrapper: FunctionComponent<{
  onClick?: () => void
  dataTestId: string
  children: ReactNode
}> = ({ children, onClick, dataTestId }) => {
  return (
    <StyledGrid
      template="repeat(2, auto)"
      align="center"
      justify="end"
      gap="10px"
      onClick={onClick}
    >
      <Button variant="LINK" data-dd-privacy="hidden" data-test={dataTestId}>
        {children}
      </Button>
      <SvgIcon name="user" fontSize="24px" color="PRIMARY" />
    </StyledGrid>
  )
}

interface MobileMenuOption {
  label: string
  icon: keyof typeof iconList
  value: string
}

interface SignInUpOutProps {
  userProfile?: Pick<ConsumerProfile, 'firstName'>
  variant: HeaderVariant
  orderMethod: OrderMethod
  venueXRefID: string | undefined
}

const SignInUpOut: FunctionComponent<SignInUpOutProps> = ({
  userProfile,
  variant,
  orderMethod,
  venueXRefID,
}) => {
  const theme = useTheme()
  const { userSession } = useConfiguration()
  const { t } = useTranslation()
  const router = useRouter()
  const { setRedirectURLCookie } = useCookies()

  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false)
  const windowSize = useWindowSize()

  if (venueXRefID === undefined || !userSession.isInitialized) {
    return null
  }

  // If the guest tries to login in the checkout page, should be redirected to the checkout page after signed in
  const callbackInfo =
    !userSession.isSignedIn && variant === HeaderVariant.CHECKOUT
      ? {
          url: '/[orderMethod]/[venueXRefID]/checkout',
          as: `/${orderMethod.toLowerCase()}/${venueXRefID}/checkout`,
        }
      : {
          url: '/[orderMethod]/[venueXRefID]/menu',
          as: `/${orderMethod.toLowerCase()}/${venueXRefID}/menu`,
        }

  const isSmallScreen = windowSize.width < theme.breakpointValues.SM
  const showMobileButton = isSmallScreen

  if (userSession.isSignedIn && userProfile != null) {
    enum SelectOptions {
      PROFILE = 'profile',
      SIGN_OUT = 'sign_out',
    }

    const selectOptions: Option[] = Object.values(SelectOptions).map((option) => ({
      label: t('tbdine.user_menu', { context: option }),
      value: option,
      key: option,
    }))

    const mobileMenuOptions: MobileMenuOption[] = Object.values(SelectOptions).map((option) => ({
      label: t('tbdine.user_menu', { context: option }),
      icon: option === SelectOptions.PROFILE ? 'user' : 'logout',
      value: option,
    }))

    const handleChange = async (value: string): Promise<void> => {
      switch (value) {
        case SelectOptions.PROFILE:
          await router.push(
            `${publicConfig.cafProfile}?redirect_url=${encodeURIComponent(
              `${publicConfig.baseURL}${router.asPath}`
            )}`
          )
          break
        case SelectOptions.SIGN_OUT:
          // This is a temporary solution until we move all AZ auth to backend
          // In order to keep profile page Azure in sync with OOF, we should signout CAF when OOF signout is initiated
          // So redirect to CAF logout first with redirect query param for OOF signout
          // CAF will redirect back to OOF signout page wich will signout of OOF session for Azure
          await router.push(
            `${publicConfig.cafLogout}?redirect_url=${encodeURIComponent(
              `${publicConfig.baseURL}/signout?venueXRefID=${venueXRefID}`
            )}`
          )
          break
        default:
          await router.push(`${publicConfig.baseURL}/signout?venueXRefID=${venueXRefID}`)
          break
      }
    }

    return (
      <>
        {!isSmallScreen && (
          <ButtonContentWrapper dataTestId="user-navigation-bar">
            {userProfile.firstName}
            <Select
              variant={SelectVariant.CHECKOUT}
              options={selectOptions}
              onChange={handleChange}
              data-test="user-navigation-option"
            />
          </ButtonContentWrapper>
        )}
        {showMobileButton && (
          <>
            <ToggleButton
              open={toggleDrawer}
              setOpen={setToggleDrawer}
              renderButtonText={
                <StyledToggleButtonContent data-test="profile-menu-toggle">
                  {userProfile.firstName}
                  <SvgIcon name="user" fontSize="24px" color="PRIMARY" />
                </StyledToggleButtonContent>
              }
              data-test="user-navigation-toggle-btn"
            />
            <SlideUp
              open={toggleDrawer}
              setOpen={setToggleDrawer}
              data-test="user-navigation-slide-up"
              name="userNavigationDrawer"
              subtitle={null}
              title={null}
            >
              <StyledOptionsContainer>
                {mobileMenuOptions.map((option, index) => (
                  <StyledOption
                    key={option.value}
                    onClick={() => handleChange(option.value)}
                    data-test={`option-${option.value}`}
                  >
                    <SvgIcon name={option.icon} fontSize="24px" m="0 12px 0 0" color="GRAY_4" />
                    <StyledOptionLabel>
                      <StyledLabel>{option.label}</StyledLabel>
                      {index < mobileMenuOptions.length - 1 && <Divider />}
                    </StyledOptionLabel>
                  </StyledOption>
                ))}
              </StyledOptionsContainer>
            </SlideUp>
          </>
        )}
      </>
    )
  }

  const signInWithAzure = (): void => {
    // we save a redirect URL with a cookie in order to redirect
    // the user to the last page they were on before signing in
    setRedirectURLCookie(callbackInfo)
    router.push('/api/consumer-frontend/auth/azure-b2c/login?p=B2C_1A_signup_signin')
  }

  return (
    <ButtonContentWrapper onClick={signInWithAzure} dataTestId="signin-button">
      {t('tbdine.sign_in')}
    </ButtonContentWrapper>
  )
}

export default SignInUpOut

/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import Grid from '../Grid'
import SvgIcon from '../SvgIcon'
import Text from '../Text'

const StyledText = styled(Text)`
  text-align: center;
  font-size: 26px;
`

interface QuantitySelectorProps {
  onChange?: (newQuantity: number) => void
  initialQuantity?: number
  quantityMin?: number
  quantityMax?: number
  disabled?: boolean
  name?: string
  disableAdd?: boolean
}

// TODO > update component to not use props with the same name as html attr
const QuantitySelector: FunctionComponent<QuantitySelectorProps> = ({
  initialQuantity = 1,
  quantityMin = 1,
  quantityMax = 99,
  onChange,
  disabled,
  disableAdd,
  ...containerProps
}) => {
  const [quantity, setQuantity] = useState<number>(initialQuantity)

  useEffect(() => {
    setQuantity(initialQuantity)
  }, [initialQuantity])

  const handleRemoveItem = (): void => {
    const newQuantity = quantity - 1
    const validQuantity = newQuantity < quantityMin ? quantityMin : newQuantity
    setQuantity(validQuantity)
    if (onChange) {
      onChange(validQuantity)
    }
  }

  const handleAddItem = (): void => {
    const newQuantity = quantity + 1
    const validQuantity = newQuantity > quantityMax ? quantityMax : newQuantity
    setQuantity(validQuantity)
    if (onChange) {
      onChange(validQuantity)
    }
  }

  return (
    <Grid template="repeat(3, auto)" {...containerProps} align="center" gap="12px">
      <SvgIcon
        disabled={disabled || quantity === quantityMin}
        onClick={handleRemoveItem}
        name="remove_circle"
        fontSize="36px"
        color="PRIMARY"
      />
      <StyledText>{initialQuantity}</StyledText>
      <SvgIcon
        disabled={disableAdd || disabled || quantity === quantityMax}
        onClick={handleAddItem}
        name="add_circle"
        fontSize="36px"
        color="PRIMARY"
      />
    </Grid>
  )
}

export default QuantitySelector

import { FunctionComponent } from 'react'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import OrderStatusBar from '@/components/Header/OrderStatusBar'
import SearchInputByAddress from '@/components/SearchInputByAddress'
import { SearchInputByAddressVariant } from '@/components/SearchInputByAddress/AddressAutocompleteComponent'
import type { Delivery, VenueOrderingMethod } from '@/libs/helpers/adapters'
import { type ConsumerProfile } from '@/libs/helpers/apiClient'
import { HeaderVariant, OrderMethod, getOrderMethodSettings } from '@/libs/helpers/utils'
import type { OrderingAddress } from '@/providers/googleMaps'
import { Grid } from '@/tbui'
import Logo from './Logo'
import OrderMethodDropdown from './OrderMethodDropdown'
import SignInUpOut from './SignInUpOut'

export interface HeaderProps {
  variant: HeaderVariant
  venueOrderingMethods?: VenueOrderingMethod[]
  openOrderMethodSelector?: () => void
  cart: {
    orderingAddress?: OrderingAddress
    scheduledFor?: string
    delivery?: Delivery
    isCartLoading: boolean
    orderMethod: OrderMethod
  }
  userProfile?: ConsumerProfile
  venueXRefID: string | undefined
}

const StyledHeader = styled.header<{ isSearchVariant: boolean }>`
  padding: ${({ isSearchVariant }) => (isSearchVariant ? '0 0 8px 0' : '0')};
  background: ${(props) => props.theme.palette.WHITE};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  position: relative;
  display: grid;
  z-index: 99;
  min-height: 100px;
  align-items: center;
`

const StyledContainer = styled(Grid)<{
  isMenuVariant: boolean
  isSearchVariant: boolean
}>`
  grid-template-areas: ${({ isMenuVariant }) =>
    isMenuVariant ? '"logo order-method-dropdown signin"' : '"logo search-input signin"'};
  grid-template-columns: ${({ isMenuVariant }) =>
    isMenuVariant ? 'auto auto 1fr' : 'auto 1fr auto'};
  justify-content: space-between;
  align-items: center;
  @media (max-width: 550px) {
    grid-template-columns: ${({ isSearchVariant }) => (isSearchVariant ? '1fr' : '1fr auto auto')};
    grid-template-areas: ${({ isSearchVariant }) =>
      isSearchVariant ? '"logo" "search-input"' : '"logo order-method-dropdown signin"'};
  }
`

const profileContainerStyles = css`
  display: flex;
  justify-self: right;
`

const Header: FunctionComponent<HeaderProps> = ({
  variant,
  venueOrderingMethods = [],
  cart,
  openOrderMethodSelector,
  userProfile,
  venueXRefID,
}) => {
  const router = useRouter()
  const orderMethod = cart.orderMethod
  const isDelivery = orderMethod === OrderMethod.delivery
  const { isASAPAvailable, isSchedulingAvailable } = getOrderMethodSettings(
    venueOrderingMethods,
    orderMethod,
    cart.delivery
  )
  const isMethodAvailable = isASAPAvailable || isSchedulingAvailable

  // dropdown is clickable when pickup scheduling only,
  // delivery is the current order method,
  // pickup and delivery are available
  // and the given method is available (i.e ASAP or scheduling)
  const isClickable =
    (venueOrderingMethods.length > 1 || isDelivery || isSchedulingAvailable) && isMethodAvailable

  return (
    <StyledHeader isSearchVariant={variant === HeaderVariant.SEARCH}>
      <StyledContainer
        isMenuVariant={variant === HeaderVariant.MENU}
        isSearchVariant={variant === HeaderVariant.SEARCH}
        container
      >
        <Grid area="logo">
          <Logo
            isSearchVariant={variant === HeaderVariant.SEARCH}
            onClick={async (): Promise<void> => {
              if (!cart.isCartLoading && venueXRefID != null && variant !== HeaderVariant.HOME) {
                // logo should go to menu page when user has cart
                // and venueXRefID exists in path
                await router.push(
                  '/[orderMethod]/[venueXRefID]/menu',
                  `/${orderMethod.toLowerCase()}/${venueXRefID}/menu`
                )
                return
              }
              // otherwise go to home discovery
              await router.push('/')
            }}
          />
        </Grid>
        {variant === HeaderVariant.MENU && (
          <Grid
            area="order-method-dropdown"
            template={`repeat(${
              isDelivery && cart.scheduledFor != null && cart.orderingAddress != null ? 3 : 2
            }, auto)`}
            gap="1rem"
          >
            {openOrderMethodSelector != null && (
              <OrderMethodDropdown
                isClickable={isClickable}
                openOrderMethodSelector={openOrderMethodSelector}
                venueOrderingMethods={venueOrderingMethods}
                orderMethod={orderMethod}
                onCustomSelectChange={async (method: string): Promise<void> => {
                  await router.push(
                    '/[orderMethod]/[venueXRefID]/menu',
                    `/${method}/${venueXRefID}/menu`
                  )
                }}
              />
            )}
            {isMethodAvailable && openOrderMethodSelector != null && (
              <OrderStatusBar
                openOrderMethodSelector={openOrderMethodSelector}
                orderingAddress={cart.orderingAddress}
                scheduledFor={cart.scheduledFor}
                orderMethod={orderMethod}
              />
            )}
          </Grid>
        )}
        {variant === HeaderVariant.SEARCH && (
          <Grid area="search-input">
            <SearchInputByAddress
              variant={SearchInputByAddressVariant.ADDRESS_HEADER}
              orderMethod={orderMethod}
            />
          </Grid>
        )}
        <Grid
          // If not in the venue section of OOF SignInUpOut will render null
          area="signin"
        >
          <div className={profileContainerStyles}>
            <SignInUpOut
              userProfile={userProfile}
              variant={variant}
              orderMethod={orderMethod}
              venueXRefID={venueXRefID}
            />
          </div>
        </Grid>
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header

import Cookies, { CookieSetOptions } from 'universal-cookie'

import publicConfig from '@/config/public'
import { SupportedLanguages } from '../formatters'

interface RedirectURL {
  url: string
  as: string
}

export interface CookieHook {
  getUserLocaleCookie: () => SupportedLanguages
  setUserLocaleCookie: (value: SupportedLanguages) => void
  getRedirectURLCookie: () => RedirectURL | null
  setRedirectURLCookie: (value: RedirectURL) => void
  removeRedirectURLCookie: () => void
}

export enum CookieNames {
  USER_LOCALE = 'usr-locale',
  REDIRECT_URL = 'redirect-url',
}

/* hook for easier browser cookie management */
const useCookies = (): CookieHook => {
  const cookies = new Cookies()

  const cookieOptions: CookieSetOptions = {
    secure: publicConfig.environment === 'production',
    httpOnly: false, // Its set by the client
    sameSite: 'strict',
    path: '/',
  }

  const getUserLocaleCookie: CookieHook['getUserLocaleCookie'] = () => {
    return cookies.get(CookieNames.USER_LOCALE)
  }

  const setUserLocaleCookie: CookieHook['setUserLocaleCookie'] = (value) => {
    return cookies.set(CookieNames.USER_LOCALE, value, cookieOptions)
  }

  // we read/write a redirect URL cookie to send the user back
  // from whence they came after signing in (i.e leaving the app)
  const getRedirectURLCookie: CookieHook['getRedirectURLCookie'] = () => {
    const value = cookies.get(CookieNames.REDIRECT_URL)
    if (!value) {
      return null
    }
    // NOTE: the url and as values are passed to router.push
    // IMPORTANT: we use a pipe `|` as a delimiter since it's a disallowed character in URLs
    // CRITICAL: at some point, this used a dash as a delimiter and that broke because they are common characters in URLs
    const [url, as] = value.split('|')
    return { url, as }
  }

  const setRedirectURLCookie: CookieHook['setRedirectURLCookie'] = ({ url, as }: RedirectURL) => {
    // NOTE: the url and as values are passed to router.push
    // IMPORTANT: we use a pipe `|` as a delimiter since it's a disallowed character in URLs
    const value = `${url}|${as}`
    return cookies.set(CookieNames.REDIRECT_URL, value, {
      ...cookieOptions,
      // we need to specify a lax sameSite policy when setting the cookie in order for it to persist in safari
      sameSite: 'lax',
    })
  }

  const removeRedirectURLCookie: CookieHook['removeRedirectURLCookie'] = () =>
    cookies.remove(CookieNames.REDIRECT_URL)

  return {
    getUserLocaleCookie,
    setUserLocaleCookie,
    getRedirectURLCookie,
    setRedirectURLCookie,
    removeRedirectURLCookie,
  }
}

export default useCookies
export { useCookies }

/* eslint-disable react/jsx-props-no-spreading */
import {
  FunctionComponent,
  FocusEvent,
  MouseEvent,
  useState,
  Dispatch,
  useRef,
  ReactNode,
} from 'react'
import styled from '@emotion/styled'
import Grid from '../Grid'
import { formStyles } from '../Input/styles'
import SvgIcon from '../SvgIcon'

export enum SelectVariant {
  CHECKOUT = 'CHECKOUT',
  SEARCH = 'SEARCH',
  INPUT = 'INPUT',
}

const StyledFakeSelect = styled.a<{ variant?: SelectVariant }>`
  border-bottom: ${(props) => props.theme.shape.BORDER};
  border: ${(props) => (props.variant !== SelectVariant.CHECKOUT ? props.theme.shape.BORDER : '0')};
  border-radius: ${(props) =>
    props.variant !== SelectVariant.CHECKOUT ? props.theme.shape.RADIUS : '0'};
  padding: ${(props) => (props.variant === SelectVariant.CHECKOUT ? '0 0 8px 0' : '8px 16px')};
  color: ${(props) => props.theme.palette.TEXT_2};
  border-color: ${(props) => props.theme.palette.GRAY_2};
  font-size: ${(props) => props.theme.font.FAMILY};
  background: ${(props) =>
    props.variant === SelectVariant.CHECKOUT
      ? props.theme.palette.WHITE
      : props.theme.palette.GRAY_1};
  justify-content: space-between;
  text-decoration: none;
  align-content: center;
  position: relative;
  text-align: left;
  min-width: 200px;
  font-size: ${({ theme }) => formStyles(theme).fontSize};
  cursor: pointer;
  display: flex;
  &:focus {
    box-shadow: inset ${(props) => props.theme.shape.SHADOW};
    box-shadow: none;
  }
  ${(props) =>
    props.variant === SelectVariant.INPUT && {
      border: `${formStyles(props.theme).border}`,
      padding: `${formStyles(props.theme).padding}`,
      borderRadius: `${formStyles(props.theme).radius}`,
      backgroundColor: `${props.theme.palette.WHITE}`,
      // target the pop over child div because no props for variant can be grabbed
      '> .pop-over': {
        borderRadius: `${formStyles(props.theme).radius}`,
        label: {
          paddingLeft: '12px',
        },
      },
    }}
`

export enum SelectOrientation {
  BOTTOM = 'BOTTOM',
  TOP = 'TOP',
}

interface PopOverProps {
  orientation?: keyof typeof SelectOrientation
  offset?: number
  open: boolean
}
const StyledPopOver = styled.div<PopOverProps>`
  border: ${(props) => props.theme.shape.BORDER};
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  color: ${(props) => props.theme.palette.TEXT_2};
  border-color: ${(props) => props.theme.palette.GRAY_2};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background: ${(props) => props.theme.palette.WHITE};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  opacity: ${(props) => (props.open ? '1' : '0')};
  transition: ease 0.3s;
  position: absolute;
  overflow: hidden;
  font-size: ${({ theme }) => theme.font.BASE_SIZE};
  padding: 6px 0;
  display: grid;
  z-index: 999;
  right: 0;
  left: 0;
  ${({ orientation, offset }) =>
    /* The offset is on the opposite direction of the orientation */
    `${orientation === SelectOrientation.TOP ? 'bottom' : 'top'}: ${offset ? `${offset}px` : '0'}`};
`

const StyledLabel = styled.label`
  color: ${(props) => props.theme.palette.TEXT};
  padding: 12px 24px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.palette.SECONDARY};
    color: ${(props) => props.theme.palette.TEXT_SECONDARY};
  }
`

const StyledIcon = styled(SvgIcon)<{ up: boolean }>`
  transform: rotate(${(props) => (props.up ? '180deg' : '0')});
  transition: ease 0.3s;
`

export interface Option {
  label: string | JSX.Element
  value: string
  key: string
  disabled?: boolean
}

interface SelectProps {
  /** The value to be shown as the current inside the Select */
  currentLabel?: string | JSX.Element
  /** Sets the direction of the PopOver opening */
  orientation?: keyof typeof SelectOrientation
  /** Callback function returning the selected value */
  onChange: Dispatch<string>
  /**
   An array of Option<{
  label: string | JSX.Element // the text or element to be displayed as option
  value: string; // the value to be returned on selection
  key: string; // Unique identifier used by React
}>
   */
  options: Option[]
  /** Variant to style dropdown depending on context */
  variant?: SelectVariant
  children?: ReactNode
}

// TODO > update component to not use props with the same name as html attr
const Select: FunctionComponent<SelectProps> = ({
  orientation = SelectOrientation.BOTTOM,
  currentLabel = '',
  onChange,
  options,
  variant,
  ...gridProps
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLAnchorElement>(null)

  const getHeight = (): number | undefined => {
    return wrapperRef.current?.getClientRects()[0]?.height
  }

  const handleSelect = (value: string) => (e: MouseEvent<HTMLLabelElement>) => {
    e.preventDefault()
    onChange(value)
    setOpen(false)
  }

  const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    setOpen(!open)
  }

  const handleBlur = (e: FocusEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    setOpen(false)
  }

  return (
    <StyledFakeSelect
      variant={variant ?? SelectVariant.SEARCH}
      onClick={handleClick}
      onBlur={handleBlur}
      ref={wrapperRef}
      href="#"
    >
      <StyledPopOver
        orientation={orientation}
        offset={getHeight()}
        open={open}
        className="pop-over"
      >
        {options.map(({ key, label, value }) => (
          <StyledLabel key={key} onClick={handleSelect(value)}>
            {label}
          </StyledLabel>
        ))}
      </StyledPopOver>
      <Grid align="center" template="auto 1fr" gap="8px" {...gridProps}>
        {currentLabel}
      </Grid>
      <StyledIcon name="chevron" up={open} />
    </StyledFakeSelect>
  )
}

export default Select

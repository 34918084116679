import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { buildAssetsPath } from '@/libs/helpers/assets'

const StyledPicture = styled.picture`
  padding: 20px;
`
const StyledLogo = styled.img<Pick<LogoProps, 'isSearchVariant'>>`
  cursor: pointer;
  height: 32px;
  width: auto;
  @media (max-width: ${({ theme, isSearchVariant }) =>
      isSearchVariant ? theme.breakpoints.MD : theme.breakpoints.SM}) {
    justify-self: ${({ isSearchVariant }) => (isSearchVariant ? 'start' : 'center')};
  }
`

interface LogoProps {
  isSearchVariant: boolean
  onClick?: () => void
}

const Logo: FunctionComponent<LogoProps> = ({ isSearchVariant, onClick }) => {
  return (
    <StyledPicture>
      <source
        media={isSearchVariant ? '(min-width: 700px)' : '(min-width: 600px)'}
        srcSet={buildAssetsPath('/images/tb-dine-logo.png')}
      />
      <StyledLogo
        src={buildAssetsPath('/images/tb-dine-logo-small.png')}
        isSearchVariant={isSearchVariant}
        onClick={onClick}
        alt="logo"
      />
    </StyledPicture>
  )
}

export default Logo

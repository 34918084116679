/**
 * VARIABLES WITH 'NEXT_PUBLIC_' prefix are only allowed to be accessed in frontend code in next.js
 */

const environment = process.env.NEXT_PUBLIC_NODE_ENV

const baseURL = process.env.NEXT_PUBLIC_BASE_URL ?? ''
const datadogApplicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? ''
const datadogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? ''
const datadogService = process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? ''

const googleMapsAPIKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''
const googleMapsAPIURL = process.env.NEXT_PUBLIC_GOOGLE_API_URL ?? ''

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

const publicAssetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX

const wepaySDK = process.env.NEXT_PUBLIC_WEPAY_SDK_APP_ID ?? ''

const azureB2cTenant = process.env.NEXT_PUBLIC_AZURE_B2C_TENANT

const cwaURL = process.env.NEXT_PUBLIC_CWA_URL

const cafProfile = process.env.NEXT_PUBLIC_PROFILE_PAGE
const cafLogout = process.env.NEXT_PUBLIC_LOGOUT_PAGE
const hostedPaymentTestURL = process.env.NEXT_PUBLIC_HOSTED_PAYMENTS_TEST_URL ?? ''
const hostedPaymentProdURL = process.env.NEXT_PUBLIC_HOSTED_PAYMENTS_PROD_URL ?? ''

const payrixPayFieldsTestUS_URL = 'https://test-api.payrix.com/payFieldsScript'
const payrixPayFieldsUS_URL = process.env.NEXT_PUBLIC_PAYRIX_PAYFIELDS_API_USA_URL ?? ''
const payrixPayFieldsTestCA_URL = 'https://test-api.payrixcanada.com/payFieldsScript'
const payrixPayFieldsCA_URL = process.env.NEXT_PUBLIC_PAYRIX_PAYFIELDS_API_CAN_URL ?? ''

const browserConfig = {
  azureB2cTenant,
  environment,
  baseURL,
  datadogApplicationId,
  datadogClientToken,
  datadogService,
  googleMapsAPIKey,
  googleMapsAPIURL,
  googleTagManagerId,
  publicAssetPrefix,
  wepaySDK,
  cwaURL,
  cafLogout,
  cafProfile,
  hostedPaymentTestURL,
  hostedPaymentProdURL,
  payrixPayFieldsUS_URL,
  payrixPayFieldsTestUS_URL,
  payrixPayFieldsCA_URL,
  payrixPayFieldsTestCA_URL,
}

export default browserConfig

import { useEffect, useRef } from 'react'

/**
 * Simple hook that will give us the ability to wait a certain amount of time
 * after being called for the last time before firing the callback.
 * This is particularly useful when an API call is attached to an onChange/onKeyPress
 * event (which might end up firing a lot). This method only supports trailing debounce
 * (meaning the last callback is going to be the one called), we could add a leading option
 * in the future to return the first callback that was stored by the argsRef.
 * @param callback The callback to be run once the delay has expired without new input
 * @param delay the amount of time (in ms) to wait for new input before firing the callback
 */
export function useDebounce<A extends unknown[]>(
  callback: (...args: A) => void,
  delay: number
): (...args: A) => void {
  // track args & timeout handle between calls
  const argsRef = useRef<A>()
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  function cleanup(): void {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, [])

  return function debouncedCallback(...args: A) {
    // capture latest args
    argsRef.current = args

    // clear debounce timer
    cleanup()

    // start waiting again
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callback(...argsRef.current)
      }
    }, delay)
  }
}

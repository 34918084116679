import { FunctionComponent, MouseEvent, MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import type { OptionsType } from '@/libs/helpers/utils'
import { Grid, SvgIcon, Text, useClickOutsideEvent } from '@/tbui'

import CustomOption from './CustomOption'

const StyledWrapper = styled.a<{
  length: number
  minWidth?: number
}>`
  pointer-events: ${(props) => (props.length > 1 ? 'auto' : 'none')};
  cursor: ${(props) => (props.length > 1 ? 'pointer' : 'auto')};
  padding: ${(props) => (props.length > 1 ? '0 8px' : '0')};
  min-width: ${(props) => (props.minWidth != null ? props.minWidth : 'auto')};
  flex-direction: column;
  text-decoration: none;
  display: flex;
`

const StyledGrid = styled(Grid)`
  grid-template-areas: 'label icon' 'value icon';
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  > h6 {
    grid-area: label;
  }
  > p {
    grid-area: value;
  }
  > i {
    place-items: center;
    grid-area: icon;
  }
`

const StyledSvgIcon = styled(SvgIcon)<{ isOpen: boolean }>`
  transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
  transform-origin: 50% 50%;
  transition: 0.25s ease;
  pointer-events: none;
  right: 8px;
`

type ParentRect = DOMRectList[0]

export interface StyledPopUpProps {
  parent: ParentRect
  open: boolean
  popupMaxHeight?: number
  dropup?: boolean
}

const StyledPopUp = styled.div<StyledPopUpProps>`
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background: ${(props) => props.theme.palette.WHITE};
  box-shadow: ${(props) => props.theme.shape.SHADOW};
  opacity: ${(props) => (props.open ? '1' : '0')};
  width: ${(props) => props.parent?.width}px;
  top: ${(props) => (props.dropup ? 'auto' : props.parent?.height)}px;
  flex-direction: column;
  transition: 0.25s ease;
  position: absolute;
  overflow: auto;
  padding: 6px 0;
  display: flex;
  z-index: 99;
  right: 0;
  max-height: ${(props) => (props.popupMaxHeight != null ? `${props.popupMaxHeight}px` : 'auto')};
  ${(props) => props.dropup && { bottom: '100%' }};
  margin: 8px 0 ${(props) => (props.dropup ? 10 : 0)}px;
`

interface ICustomSelect {
  onChange: (selectedOption: string) => void
  wrapperRef: MutableRefObject<HTMLElement | null>
  options: OptionsType[]
  groupId: string
  value: string
  minWidth?: number
  popupMaxHeight?: number
  placeholder?: string
  dropup?: boolean
  styleOnly?: boolean
}

const CustomSelect: FunctionComponent<ICustomSelect> = ({
  groupId,
  options,
  onChange,
  value,
  wrapperRef,
  minWidth,
  popupMaxHeight,
  placeholder,
  dropup,
  styleOnly = false,
}) => {
  const [displayValue, setDisplayValue] = useState<string>(value)
  const [open, setOpen] = useState(false)
  const popoverRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (value != null) {
      setDisplayValue(value)
    } else if (options.length > 0) {
      setDisplayValue(options[0].value)
    }
  }, [value, options])

  const getClientRects = (): DOMRect => {
    return wrapperRef?.current?.getClientRects()[0] as DOMRect
  }

  useClickOutsideEvent(popoverRef, () => setOpen(false))

  const handleSelect = (selectedOption: string): void => {
    setDisplayValue(selectedOption)
    onChange(selectedOption)
    setOpen(false)
  }

  const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    if (options.length === 1) {
      // do nothing when there is 1 option
      return
    }

    setOpen(!open)
  }

  return (
    <StyledWrapper
      length={options.length}
      onClick={!styleOnly ? handleClick : undefined}
      minWidth={minWidth}
      data-test={`custom-select-${groupId}`}
    >
      <StyledGrid>
        {placeholder && (
          <Text type="H6" color="GRAY_2">
            {placeholder}
          </Text>
        )}
        <Text fontSize="16px" data-test="custom-select-display-value">
          {options.find((option) => option.value === displayValue)?.label ?? ''}
        </Text>
        {/* only show chevron when there are many options */}
        {options.length > 1 && (
          <StyledSvgIcon name="chevron" fontSize="16px" color="PRIMARY" isOpen={open} />
        )}
      </StyledGrid>
      <StyledPopUp
        ref={popoverRef}
        parent={getClientRects()}
        open={open}
        popupMaxHeight={popupMaxHeight}
        dropup={dropup}
      >
        {options
          .filter((option) => option.value !== displayValue)
          .map((option) => (
            <CustomOption
              onChange={handleSelect}
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
      </StyledPopUp>
    </StyledWrapper>
  )
}

export default CustomSelect

/* eslint-disable no-restricted-properties */
import { useState, useEffect } from 'react'

/* hook that subscribes to browser window resize events
   and passes the window size values via react context
*/
// RESOURCE: https://usehooks.com/useWindowSize/
const useWindowSize: () => {
  width: number
  height: number
} = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number
    height: number
  }>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // Handler to call on window resize
    const handleResize: () => void = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export { useWindowSize }

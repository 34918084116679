import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { NextRouter } from 'next/router'
import PopupModalTemplate from '@/components/PopupModalTemplate'
import {
  mapErrorToErrorModalContent,
  PaymentErrorCode,
  ModalPageErrorCode,
} from '@/libs/helpers/errorMapper'
import { parseRequestError } from '@/libs/helpers/error_handler'
import { UseModalType } from '@/providers/modal'
import { ErrorActionTypes, DispatchType } from './reducer'

const setFullPageErrorAction =
  (dispatch: DispatchType) =>
  (error: AxiosError | unknown): void => {
    const { statusCode, message, code } = parseRequestError(error)

    dispatch({
      type: ErrorActionTypes.FULL_PAGE,
      message,
      errorCode: code,
      status: statusCode,
    })
  }

/* opens a modal with a translated modal `title`, `message`
   and an optional call to action function */
const setModalErrorAction =
  (dispatch: DispatchType, t: TFunction, modal: UseModalType, router: NextRouter) =>
  (error: AxiosError | ModalPageErrorCode | unknown, onSubmit?: () => void): void => {
    const { openModal, closeModal } = modal
    const { translationContext, callToActionHandler, modalOptions } = mapErrorToErrorModalContent(
      error,
      router
    )
    const isPaymentError = Object.values(PaymentErrorCode).some(
      (errorCode) => errorCode === translationContext
    )
    const titleContent = isPaymentError
      ? t('error.modal.title_payment_failed')
      : t('error.modal.title', { context: translationContext })
    const messageContent = t('error.modal.message', {
      context: translationContext,
    })
    const callToActionContent = t('error.modal.cta', {
      context: translationContext,
    })
    const handleErrorCallToAction = (): void => {
      // There are stale cart errors and such which is hooked to router reload
      // When we pass onSubmit with different router action it was not getting overridden
      // Eg: Error on payment status page needs to be redirected to checkout and not just reload
      if (callToActionHandler && !onSubmit) {
        callToActionHandler()
      }
      closeModal()
      if (onSubmit) {
        onSubmit()
      }
    }

    openModal(
      <PopupModalTemplate
        name={translationContext}
        title={titleContent}
        message={messageContent}
        callToAction={callToActionContent}
        onSubmit={handleErrorCallToAction}
      />,
      { variant: 'SMALL', onClose: handleErrorCallToAction, ...modalOptions }
    )

    // We do not need to keep track of other errors, so we can clear the error provider state
    dispatch({ type: ErrorActionTypes.CLEAR })
  }

const clearErrorAction = (dispatch: DispatchType) => async (): Promise<void> => {
  dispatch({ type: ErrorActionTypes.CLEAR })
}

export { setFullPageErrorAction, setModalErrorAction, clearErrorAction }

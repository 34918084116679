import { FunctionComponent, ReactNode, HTMLAttributes } from 'react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { textStyles } from './styles'

export { textStyles }

export enum Types {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  P = 'P',
}

export enum Transformations {
  capitalize = 'capitalize',
  uppercase = 'uppercase',
  lowercase = 'lowercase',
}

export enum Alignments {
  center = 'center',
  right = 'right',
  left = 'left',
}

export interface TextProps {
  children?: ReactNode
  transform?: keyof typeof Transformations
  align?: keyof typeof Alignments
  color?: keyof Theme['palette']
  type?: keyof typeof Types
  fontSize?: string
  onClick?: () => void
  bold?: boolean
  m?: string
  p?: string
  lineHeight?: string
}

export interface ThemedTextProps extends TextProps {
  theme: Theme
}

const sharedTextStyles = ({
  transform,
  theme,
  align,
  color,
  m,
  p,
  lineHeight,
}: ThemedTextProps): string => `
${theme.mixin.dynamicProperty('text-transform', transform, 'inherit')};
${theme.mixin.dynamicProperty('text-align', align, 'left')};
${theme.mixin.dynamicColor(theme, 'color', color, 'TEXT')};
${theme.mixin.dynamicProperty('padding', p, '0')};
${theme.mixin.dynamicProperty('margin', m, '0')};
${theme.mixin.dynamicProperty('line-height', lineHeight, theme.font.LINE_HEIGHT)};
`

const mapTextComponents = {
  [Types.H1]: styled.h1<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === false ? theme.font.FAMILY : theme.font.FAMILY_BOLD};
    letter-spacing: 0.6px;
    font-weight: 500;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '30px')};
  `,
  [Types.H2]: styled.h2<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === false ? theme.font.FAMILY : theme.font.FAMILY_BOLD};
    letter-spacing: 0.6px;
    font-weight: 500;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '24px')};
  `,
  [Types.H3]: styled.h3<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === false ? theme.font.FAMILY : theme.font.FAMILY_BOLD};
    letter-spacing: 0.32px;
    font-weight: 500;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '18px')};
  `,
  [Types.H4]: styled.h4<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === false ? theme.font.FAMILY : theme.font.FAMILY_BOLD};
    letter-spacing: 0.32px;
    font-weight: 500;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '16px')};
  `,
  [Types.P]: styled.p<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === true ? theme.font.FAMILY_BOLD : theme.font.FAMILY};
    font-weight: 400;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '14px')};
  `,
  [Types.H5]: styled.h5<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === true ? theme.font.FAMILY_BOLD : theme.font.FAMILY};
    letter-spacing: 0.2px;
    font-weight: 400;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '12px')};
  `,
  [Types.H6]: styled.h6<TextProps>`
    ${(props) => sharedTextStyles(props)}
    font-family: ${({ theme, bold }) =>
      bold === true ? theme.font.FAMILY_BOLD : theme.font.FAMILY};
    letter-spacing: 0.2px;
    font-weight: 400;
    ${({ theme, fontSize }) => theme.mixin.dynamicProperty('font-size', fontSize, '10px')};
  `,
}

const Text: FunctionComponent<TextProps & HTMLAttributes<HTMLParagraphElement>> = ({
  type = 'P',
  ...textProps
}) => {
  const TextElement = mapTextComponents[type]
  return (
    <TextElement
      role={textProps.onClick ? 'button' : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...textProps}
    />
  )
}

export default Text

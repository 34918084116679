import { FunctionComponent, createContext, useContext, useReducer, ReactNode } from 'react'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { ModalPageErrorCode } from '@/libs/helpers/errorMapper'
import { useModal } from '@/providers/modal'
import { clearErrorAction, setFullPageErrorAction, setModalErrorAction } from './actions'
import { DispatchType, initialState, reducer, StateType, ErrorActionTypes } from './reducer'

const ErrorContext = createContext<[StateType, DispatchType]>(
  null as unknown as [StateType, DispatchType]
)

export interface ErrorProviderHook {
  setFullPageError: (error: AxiosError | unknown) => void
  setModalError: (error: AxiosError | ModalPageErrorCode | unknown, onSubmit?: () => void) => void
  clearError: () => Promise<void>
  error: StateType
}

/* hook for handling and keeping error as a react context value
   exposes a few functions to set full page, modal errors as well as clear the error
*/
const useError = (): ErrorProviderHook => {
  const [error, dispatch] = useContext(ErrorContext)
  const modal = useModal()
  const { t } = useTranslation()
  const router = useRouter()

  const setFullPageError = setFullPageErrorAction(dispatch)
  const setModalError = setModalErrorAction(dispatch, t, modal, router)
  const clearError = clearErrorAction(dispatch)

  return { error, setFullPageError, setModalError, clearError }
}

const ErrorProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  return (
    <ErrorContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
export { useError, ErrorActionTypes }

import { FunctionComponent, ChangeEvent, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import Grid from '../Grid'
import Input, { InputProps, InputVariant } from '../Input'
import { formStyles } from '../Input/styles'
import Label from '../Label'
import SvgIcon from '../SvgIcon'

const StyledInput = styled(Input)`
  font-family: ${(props) => props.theme.font.FAMILY};
  margin: ${(props) => (props.variant === InputVariant.INSET ? '1.25rem 0 0 0' : '0.5rem 0 0 0')};
  &:focus {
    // label animations (the "+" is a sibling selector)
    + label {
      font-size: 0.75rem;
      top: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    padding: ${(props) =>
      props.variant === InputVariant.INSET ? '0 1.5rem 0 0' : '0.5rem 1.5rem 0.5rem 0'};
    font-size: ${(props) => props.variant === InputVariant.INSET && '16px'};
    padding-right: ${(props) => props.variant === InputVariant.INPUT && '35px !important'};
  }

  // box input style overrides - should be temporary
  ${(props) =>
    props.variant === InputVariant.INPUT && {
      border: `${formStyles(props.theme).border}`,
      padding: `${formStyles(props.theme).padding} !important`,
      borderRadius: `${formStyles(props.theme).radius}`,
      fontSize: `${formStyles(props.theme).fontSize}`,
      '&:focus': {
        border: `${formStyles(props.theme).border}`,
      },
    }}
`

const StyledLabel = styled(Label)<{ hasValue?: boolean }>`
  font-size: ${(props) => (props.hasValue ? 0.75 : 1)}rem;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    font-size: ${(props) => (props.hasValue ? 0.75 : 1)}rem;
  }
  top: ${(props) => (props.hasValue ? 0 : 1)}rem;
  transition: 0.2s ease;
  position: absolute;
  left: 0;
`

const StyledIcon = styled(SvgIcon)<InputProps>`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.LG}) {
    display: inline-block;
    position: absolute;
    font-size: 0.75rem;
    right: 0.5rem;
    top: 1.25rem;

    // box input style overrides - should be temporary
    ${(props) =>
      props.variant === InputVariant.INPUT && {
        padding: `${formStyles(props.theme).padding}`,
        top: '14px',
        right: '14px',
      }}
  }
`

export interface TextFieldProps extends InputProps {
  /** The text to be displayed as the label or placeholder */
  label?: string | JSX.Element
  // https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#privacy-options
  datadogRUMPrivacy?: 'mask' | 'hidden' | 'mask-user-input' | 'allow'
}

const TextField: FunctionComponent<TextFieldProps> = ({
  placeholder,
  value: controlledValue = '',
  hasError,
  onChange,
  children,
  variant,
  label,
  datadogRUMPrivacy = 'mask-user-input',
  ...inputProps // HTMLAttributes<HTMLInputElement>
}) => {
  const [value, setValue] = useState<string>(controlledValue)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }

  const handleClearInput = (e: ChangeEvent<HTMLInputElement> | unknown): void => {
    inputRef.current?.focus()
    setValue('')

    if (onChange != null) {
      onChange(e as ChangeEvent<HTMLInputElement>)
    }
  }

  useEffect(() => {
    // updates the internal value and allows it to be controlled from outside
    if (controlledValue != null) {
      setValue(controlledValue)
    }
  }, [controlledValue])

  return (
    <Grid>
      {value && <StyledIcon variant={variant} name="close" onClick={handleClearInput} />}
      <StyledInput
        onChange={handleChange}
        hasError={hasError}
        variant={variant}
        ref={inputRef}
        value={value}
        data-dd-privacy={datadogRUMPrivacy}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
      />
      {!placeholder && (
        <StyledLabel hasValue={!!value} hasError={hasError}>
          {label}
        </StyledLabel>
      )}
      {children}
    </Grid>
  )
}

export default TextField

import { css } from '@emotion/css'
import { Theme } from '@emotion/react'

const padding = '8.5px 12px'
const border = (theme: Theme): string => `1px solid ${theme.palette.GRAY_2}`
const radius = (theme: Theme): string => theme.shape.RADIUS_ALT
const fontSize = (theme: Theme): string => theme.font.BASE_SIZE
const labelFontSize = '14px'
const labelPadding = '5px 0'
const labelMarginTop = '10px'

const label = (theme: Theme): string => css`
  font-size: ${labelFontSize};
  font-family: inherit;
  display: flex;
  padding: ${labelPadding};
  flex-direction: column;
  color: ${theme.palette.TEXT};
  font-weight: 200;
  transition: 0.2s ease;
  input,
  textarea {
    margin-top: ${labelMarginTop};
  }
`

export const base = (theme: Theme): string => css`
  font-size: ${fontSize(theme)};
  font-family: ${theme.font.FAMILY_TEXT};
  color: ${theme.palette.TEXT};
  border: 1px solid ${theme.palette.GRAY_2};
  border-radius: ${radius(theme)};
  padding: ${padding};
  background: transparent;
  transition: 0.2s ease;
  &:focus {
    // border-color: ${theme.palette.PRIMARY};
  }
  &:invalid {
    border-color: ${theme.palette.ERROR};
  }
`
const invalid = (theme: Theme): string => css`
  //directly applies to the input or label
  border-color: ${theme.palette.ERROR};
  color: ${theme.palette.ERROR};
  //applies to input inside of a label or container
  input {
    border-color: ${theme.palette.ERROR};
    color: ${theme.palette.ERROR};
  }
`

const textField = css`
  resize: none;
  height: auto;
`

export const formStyles = (
  theme: Theme
): {
  input: [string]
  textField: [string, string]
  label: [string]
  invalid: string
  padding: string
  border: string
  radius: string
  fontSize: string
  labelFontSize: string
  labelPadding: string
  labelMarginTop: string
  base: string
} => ({
  input: [base(theme)],
  textField: [base(theme), textField],
  label: [label(theme)],
  invalid: invalid(theme),
  padding,
  border: border(theme),
  radius: radius(theme),
  fontSize: fontSize(theme),
  labelFontSize,
  labelPadding,
  labelMarginTop,
  base: base(theme),
})

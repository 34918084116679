import { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'
import SvgIcon, { SvgIconProps } from '../SvgIcon'

export interface CircularLoaderProps extends SvgIconProps {
  /** Defines the circle thickness */
  thickness?: number
  children?: ReactNode
}

const StyledSvg = styled(SvgIcon)<CircularLoaderProps>`
  @keyframes circular-dash {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0px;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }

  ${({ theme, color }) => theme.mixin.dynamicColor(theme, 'color', color, 'PRIMARY')};
  animation: rotate 1.4s linear infinite;
  > svg > circle {
    animation: circular-dash 1.4s ease-in-out infinite;
    stroke-width: ${(props) => props.thickness || 3};
    stroke-dasharray: 80px, 200px;
    transform-origin: center;
    stroke-dashoffset: 0px;
    stroke: currentColor;
    fill: none;
  }
`
const CircularLoader: FunctionComponent<CircularLoaderProps> = ({ ...iconProps }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledSvg {...iconProps}>
      <svg viewBox="22 22 44 44">
        <circle cx="44" cy="44" r="20.5" />
      </svg>
    </StyledSvg>
  )
}

export default CircularLoader

import { css, SerializedStyles, Theme } from '@emotion/react'
/*
 CSS BASED VERSION OF TEXT COMPONENT
 import theme, use theme variables locally
 */

const h = (theme: Theme): SerializedStyles => css`
  font-family: ${theme.font.FAMILY_HEADING};
  font-weight: ${theme.font.WEIGHT_HEADING};
  line-height: ${theme.font.LINE_HEIGHT};
  padding: 0;
  margin: 0;
  color: ${theme.palette.TEXT};
`

const t = (theme: Theme): SerializedStyles => css`
  font-family: ${theme.font.FAMILY_TEXT};
  font-weight: ${theme.font.WEIGHT_TEXT};
  line-height: ${theme.font.LINE_HEIGHT};
  padding: 0;
  margin: 0;
  color: ${theme.palette.TEXT};
`

const base = (theme: Theme): Record<string, SerializedStyles> => ({
  h1: css`
    font-size: 30px;
    letter-spacing: 0.6px;
  `,
  h2: css`
    font-size: 24px;
    letter-spacing: 0.6px;
  `,
  h3: css`
    font-size: 18px;
    letter-spacing: 0.32px;
  `,
  h4: css`
    font-size: 16px;
    letter-spacing: 0.32px;
  `,
  h5: css`
    font-size: 12px;
    letter-spacing: 0.2px;
  `,
  h6: css`
    font-size: 10px;
    letter-spacing: 0.2px;
  `,
  p: css`
    // default is 14px
    font-size: ${theme.font.BASE_SIZE};
  `,
})

const headings = (theme: Theme): Record<string, [SerializedStyles, SerializedStyles]> => {
  const hTheme = h(theme)
  const baseTheme = base(theme)
  return {
    one: [hTheme, baseTheme.h1],
    two: [hTheme, baseTheme.h2],
    three: [hTheme, baseTheme.h3],
    four: [hTheme, baseTheme.h4],
    five: [hTheme, baseTheme.h5],
    six: [hTheme, baseTheme.h6],
    p: [hTheme, baseTheme.p],
  }
}

const texts = (theme: Theme): Record<string, [SerializedStyles, SerializedStyles]> => {
  const tTheme = t(theme)
  const baseTheme = base(theme)
  return {
    one: [tTheme, baseTheme.h1],
    two: [tTheme, baseTheme.h2],
    three: [tTheme, baseTheme.h3],
    four: [tTheme, baseTheme.h4],
    five: [tTheme, baseTheme.h5],
    six: [tTheme, baseTheme.h6],
    p: [tTheme, baseTheme.p],
  }
}

export const textStyles = (
  theme: Theme
): {
  heading: Record<string, [SerializedStyles, SerializedStyles]>
  text: Record<string, [SerializedStyles, SerializedStyles]>
  base: Record<string, SerializedStyles>
} => ({
  heading: headings(theme),
  text: texts(theme),
  base: base(theme),
})

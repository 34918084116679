import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import Grid, { GridProps } from '../Grid'

export interface CroppedImageProps extends GridProps {
  /** Path to the image to be rendered */
  imageSrc?: string
  /** Crops the border using the theme radius value */
  radius?: string
  /** The hight of your image */
  height?: string
  /** the width of your image */
  width?: string
}

const StyledCroppedImage = styled(Grid)<CroppedImageProps>`
  border-radius: ${(props) => (props.radius ? props.theme.shape.RADIUS : '0')};
  background-image: url(${(props) => props.imageSrc});
  background-position: center center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background-size: cover;
  position: relative;
  overflow: hidden;
`

const CroppedImage: FunctionComponent<CroppedImageProps> = StyledCroppedImage

CroppedImage.defaultProps = {
  imageSrc: 'https://dummyimage.com/150x150/eee/333',
  height: '150px',
  width: '150px',
}

export default CroppedImage

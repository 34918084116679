import { FunctionComponent, ReactNode } from 'react'
import { ThemeProvider, Global, css } from '@emotion/react'
import theme, { palette, font } from './defaultTheme'

const DefaultThemeProvider: FunctionComponent<{
  isGlobal?: boolean
  children: ReactNode
}> = ({ isGlobal = false, children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      {isGlobal && (
        <Global
          styles={css`
            #__next,
            body,
            html {
              background: ${palette.WHITE};
              font-family: ${font.FAMILY};
              color: ${palette.TEXT};
              scroll-behavior: smooth;
              max-width: 100vw;
              font-size: 16px;
              margin: 0;
              * {
                box-sizing: border-box;
                :focus {
                  outline: none;
                  -webkit-tap-highlight-color: transparent;
                }
              }
            }

            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              line-height: ${font.LINE_HEIGHT};
              font-family: ${font.FAMILY};
              font-weight: normal;
              margin: 1em 0;
            }

            *[role='button'],
            button,
            label,
            a {
              cursor: pointer;
              outline: none;
            }

            .page-transition-enter {
              position: relative;
              opacity: 0;
            }

            .page-transition-enter-active {
              transition: 0.3s ease;
              position: relative;
              opacity: 1;
            }

            .page-transition-exit {
              position: relative;
              opacity: 1;
            }

            .page-transition-exit-active {
              transition: 0.3s ease;
              position: relative;
              opacity: 0;
            }

            @keyframes shake {
              0% {
                left: 0px;
              }
              25% {
                left: 5px;
              }
              50% {
                left: 0px;
              }
              75% {
                left: -5px;
              }
              100% {
                left: 0px;
              }
            }

            @keyframes rotate {
              100% {
                transform: rotate(360deg);
              }
            }
          `}
        />
      )}
    </ThemeProvider>
  )
}

export default DefaultThemeProvider
